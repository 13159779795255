.ManageDriverVehicleDetailsCardBox{
    /* position: absolute; */
width: 811px;
height: 70px;


background: #FFFFFF;
}
.ManageDriverVehicleCardName{
    margin-top: 1rem;

    position: absolute;
    width: 106px;
    height: 22px;
    left: 9px;
    top: 24px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}
.ManageDriverVehicleFastagID{
    
position: absolute;
width: 145px;
height: 22px;
left: 190px;
top: 41px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */
word-break: break-all; 
color: #25252D;

}
.ManageDriverVehicleAssignedDriver{
    margin-top: 1rem;
    position: absolute;
    width: 140px;
    height: 22px;
    left: 350px;
    top: 24px;
    text-align: center;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}
.ManageDriverVehicleCardStatus{
    margin-top: 1rem;
    position: absolute;
width: 68px;
height: 22px;
left: 545px;
top: 24px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


/* color: #31BF7A; */
}
.ManageDriverVehicleButton{
    /* margin-top: 1rem;
position: absolute;
left:700px;
top:19px; */
box-sizing: border-box;

/* position: absolute; */
width: 70px;
height: 32px;


border: 1px solid #485B7C;
border-radius: 4px;
cursor: pointer;
padding-top: 0.2rem;
color: #485B7C;
text-align: center;
}

.ManageDriverVehicleButton:hover {
    background-color:#96A9CA;
    color: white;
    
  }


