.main-vehicle-assigned-history{
    position: relative;
    /* top: 88px; */
    padding-top: 1rem;
   
}
.vehicleAssignedHistory{
    border-collapse: collapse;
   
}
.tbodyVehicle{
    z-index: -1;
    overflow-y:scroll;
    }

.olderTransactions-div{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    padding-left:22rem;
}
/* .tbodyVehicle-div{
    overflow-y: scroll;
    overflow-x: hidden;
    
    height: 50px;
}     */    
.vehicle-assigned-history-title{

width: 233px;
height: 27px;


font-family: 'Nunito-Bold';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 27px;

color: #25252D;
}

.vehicle-logo{
    margin: 0 0 0 12px;
    color : black
}

.table-header-vehicle-assign{
    font-family: 'Nunito-Bold';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
    text-align: center;
    height: 51px;
    background: rgba(46, 124, 123, 0.1);
}

.table-row-vehicle-assign{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */

border-collapse: collapse;

color: #25252D;

    height: 78px;
    text-align: center;
}

.table-column-vehicle-assign{
    padding: 28px 0 0 0 ;
}

.view-full-history-vehicle-assign{
    position: relative;
    top: 13px;
    font-family: 'Nunito-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
/* identical to box height */

text-align: center;

color: #25252D;
}


.table-for-vehicle-receivable {
    
    position: sticky;
}
 .table>:not(:first-child) {
    border-top-width: 0px;
}


.main-vehicle-assigned-history  tr{
    height: 40px;
}

