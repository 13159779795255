.ManageDriverDetailsCardBarBox {
    position: relative;
    width: 808px;
    height: 51px;
    background: rgba(46, 124, 123, 0.1);
}

.ManageDriverDetailsCardBarType {
    position: absolute;
    left: 24px;
    top: 16px;
    width: 111px;
    height: 19px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-right: 2rem;
    /* identical to box height */
    text-transform: capitalize;
    color: #25252d;
}

.ManageDriverDetailsCardBarAmount {
    position: absolute;
    width: 53px;
    height: 19px;
    left: 251px;
    top: 16px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    text-transform: capitalize;
    color: #25252d;
}

.ManageDriverDetailsCardBarMode {
    position: absolute;
    width: 18px;
    height: 19px;
    left: 370px;
    top: 16px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    text-transform: capitalize;
    color: #25252d;
}

.ManageDriverDetailsCardBarDate {
    position: absolute;
    width: 37px;
    height: 19px;
    left: 468px;
    top: 16px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    text-transform: capitalize;
    color: #25252d;
}

.ManageDriverDetailsCardBarAttach {
    position: absolute;
    width: 32px;
    height: 19px;
    left: 603px;
    top: 16px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    text-transform: capitalize;
    color: #25252d;
}

.ManageDriverDetailsCardBarAction {
    position: absolute;
    width: 50px;
    height: 19px;
    left: 738px;
    top: 16px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    text-transform: capitalize;
    color: #25252d;
}

.ManageDriverDetailsCardBarAmountImage {
    position: absolute;
    left: 304px;
    top: 18px;
}

.ManageDriverDetailsCardBarDateImage {
    position: absolute;
    left: 636px;
    top: 18px;
}

.ManageDriverDetailsCardBarByImage {
    position: absolute;
    left: 388px;
    top: 18px;
}

.ManageDriverDetailsCardBarModeImage {
    position: absolute;
    left: 505px;
    top: 18px;
}