.greenColumn{
    background-color: #ED752E;
}

.headingLogin{
    padding: 2.5rem 1.5rem 2.5rem 2.3rem;
}

.subheading{
    text-align: right;
}

.headText1{
    font-size: 32px;
}

.InputForm{
    text-align: left;
    padding:5rem 12rem 13rem; 
}


.InputForm1{
    text-align: left;
    padding:5rem 12rem 6rem; 
}


.InputForm2{
    text-align: left;
    padding:2.5rem 12rem 6rem; 
}

.InputForm3{
    text-align: left;
    padding:5rem 12rem 5rem; 
}

.headText2{
    color:#25252D;
    display: flex;
}

.DesktopOuterContainer{
    height: 100vh;
}

.underline1{
    width: 4rem;
    height: 0px;
    border: 0.4px solid #a7a7a7;
}

.underline2{
    width: 200px;
    height: 0px;
    margin-left: 1.5rem;
    border: 0.4px solid #a7a7a7;
}

.boxWithBorder{
    border-right: 0.4px solid #E2E2E2;
}

.loginButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 45px;
    position: relative;
    text-align: center;
    /* background: #2e7c7b; */
    border-radius: 4px;
    /* color: #ffffff; */
    border: none;
}

.bottomTextSpan{
    color: #ED752E;
    text-decoration: underline;
    cursor: pointer;
}

.bottomTextLogin{
    color: #AAAAAA;
    text-align: center;
}

.footerText{
    color: #AAAAAA;
    /* margin: auto; */
    align-items: center;
    text-align: left;
    display: flex;
    /* width: 16rem; */
    padding: 0.5rem 5rem 2rem;
}

.footDiv{
    padding:2rem 17rem; 
}
.formPosition{
    width: 23rem;
    margin: auto;
}

.lowerDiv{
    position: absolute;
    bottom: 0;
    width: 100%;
}


.whiteColumn{
    position: relative;
}


@media (max-width: 1400px) and (min-width: 1200px) {
    .InputForm{
        text-align: left;
        padding:5rem 16.5rem; 
    }

    .InputForm1{
        text-align: left;
        padding:4rem 16.5rem; 
    }

    .InputForm2{
        text-align: left;
        padding:1.5rem 16.5rem; 
    }

    .InputForm3{
        text-align: left;
        padding:4rem 16.5rem; 
    }

    .footDiv{
        padding:2rem 15rem; 
    }

    .loginButton{
        width: 335px;
    }
}


@media only screen and (max-width: 1026px) {
    .whiteHeight {
        height: 65%;
    }

    .greenHeight{
        height: 35%;
    }
}

.statusElement{
    display: flex;
    flex-direction: row;
    padding-bottom: 0.5rem;
}

.statusBar{
    border-top: 5px solid #ED752E;;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: 5px solid #ED752E;;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    /* border-bottom: 0.5rem solid #2E7C7B; */
}

.statusBar1{
    border-top: 5px solid #ED752E;
    border-bottom: 5px solid #ED752E;
    /* border-bottom: 0.5rem solid #2E7C7B; */
}


.statusBarRight{
    border-top: 5px solid #ED752E;;
    border-top-right-radius: 5px;
    border-bottom: 5px solid #ED752E;;
    border-bottom-right-radius: 5px;
}

.statusBarLeft{
    border-top: 5px solid #ED752E;;
    border-top-left-radius: 5px;
    border-bottom: 5px solid #ED752E;;
    border-bottom-left-radius: 5px;
    /* border-bottom: 0.5rem solid #2E7C7B; */
}

.banner{
    /* background-color: #F9B906; */
    background-color: rgba(249, 185, 6, 0.1);
    padding: 0.5rem;
    border-radius: 0.5rem;
}