.link-fastagId-to-vehicle-btn {
  position: relative;
  top: 80px;
}

.modal-content {
  height: 100%;
  width: 100%;
  border-radius: 0;
}
button:disabled {
  background-color: gray;
}
