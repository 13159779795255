.cardContainerNew{
    background-color: #4C4059;
    border-radius: 0.5rem;
    padding: 0.8rem;
}

.cardNameDr{
    color: rgba(255, 255, 255, 0.6);
}

.whiteText{
    color: #FFFFFF;
}

.greenCircleBack{
    align-items: center;
    text-align: center;
}


.pinkBackground{
    background: rgb(255, 234, 238);
}

.padding7{
    padding-top: 7px;
    padding-bottom: 7px;
}

.downloadBtn{
    color:#2E7C7B;
    cursor: pointer;
    text-decoration: underline;
}

.centralEle{
    text-align: center;
}

.styleGreen{
    text-decoration: underline;
    color: #2E7C7B;
}