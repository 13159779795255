.DriverRequestCustomerCard{
    position: absolute;
width: 600px;
height: 90px;


background: #F5F5F5;
}
.NameClass{
    position: absolute;
width: 77px;
height: 22px;
left: 20px;
top: 16px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.currentBal{
    position: absolute;
    width: 74px;
    height: 22px;
    left: 190px;
    top: 16px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    color: #25252D;

}
.RequestDate{
    position: absolute;
    width: 91px;
    height: 22px;
    left: 340px;
    top: 16px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;;
}
.RequestBal{
    position: absolute;
    width: 74px;
    height: 22px;
    left: 500px;
    top: 16px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    color: #6378E3;
}
.code{
    position: absolute;
width: 87px;
height: 19px;
left: 20px;
top: 38px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */


color: #6C6C6C;
}
.text{
    position: absolute;
left: 20px;
right: 70.14%;
top: 55px;
bottom: 61.91%;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
/* identical to box height */

display: flex;
align-items: center;
text-decoration-line: underline;

color: #2E7C7B;
}