.customerSDetailsCardName {
  margin-left: 20px;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #25252d;
}

.customerSDetailsCardBal {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #25252d;
}

.customerSDetailsCardId {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #6c6c6c;
}

.customersDetailsCardDate {
  margin-left: 10px;
}

.customerDetailsAmountCard {
  margin-left: 10px;
}

.customersDetailsAttachment {
  margin-left: 10px;
}

.customerDetailsAction {
  padding-left: 0px;
}

.table-row-customer-details {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #25252d;
  height: 100px;
  text-align: left;
  background-color: white;
}

.table-header-customer-details {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: "14px";
  line-height: 22px;
  /* identical to box height */

  background: #ededf1;
  color: #485b7c;
  height: 51px;
  text-align: left;
}

.table-for-customer-receivable {
  /* overflow: hidden;
    overflow-y: scroll; */
  /* height: 367; */
  position: sticky;
}

.view-all-transaction-customer-receeivabl {
  position: relative;
  /* top: 40px; */
  font-family: "Nunito-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background-color: white;
  /* identical to box height */
  /* display: flex; */
  text-align: center;
  cursor: pointer;
  color: #25252d;
  padding: 25px 0px;
}

.edit-btn-div {
  position: relative;
  width: 110px;
  height: 46px;
  /* left: 1272px;
    top: 711px; */
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.booking_title {
  position: relative;
}

.booking_total {
  margin-top: -15px;
  font-size: 14px;
}

.booking_IDs {
  margin-top: -15px;
  font-size: 14px;
  color: #6c6c6c;
}

.mode_position {
  position: "relative";
  left: "0";
  color: #6c6c6c;
  margin-top: -15px;
}

.mode_value_position {
  color: #6c6c6c;
  margin-top: -15px;
  margin-left: -60px;
}

.dropDown {
  position: absolute;
  top: 100%;
  right: 75%;

  width: 100%;
}
.table {
  margin-bottom: 0 !important;
}
