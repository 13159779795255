.form-check-input:checked{
    background-color: #485B7C;
    border-color: #485B7C;
}

.LowBorder{
    border-bottom: 1px solid #485B7C;
    width: 80% !important;
}

.driverSearchContainer{
    position: absolute;
    z-index: 300;
    /* width: 60%; */
    top:0;
    padding: 0.5rem;
    background-color: #ffffff;
    padding-top: 1rem;
}
.driverSearchContainer2{
    position: absolute;
    z-index: 300;
    width: 100%;
    top:0;
    padding: 0.5rem;
    background-color: #ffffff;
    padding-top: 1rem;
}

.containerShadow{
    box-shadow: 0 4px 20px 0 rgba(37, 37, 45, 0.1);
}