.mainn-container {
    /* Rectangle 828189 */
    box-sizing: border-box;
    position: relative;
    /* width: 25rem; */
    min-height: 7rem;
    /* left: -7px;
    top: 146px; */

    border: 1px solid #E2E2E2;
    border-radius: 8px;
}

.mainn-container-new{
    box-sizing: border-box;

    position: relative;
    width: 379px;
    min-height: 130px;
    left: -7px;
    top: 146px;

    
    border-radius: 8px;
}
.last-Trans {
    position: absolute;
    width: 120px;
    height: 22px;
    left: 12px;
    top: 12px;

    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    /* display: flex;
    align-items: center; */

    color: #25252D;
}

.all-transaction {
    position: absolute;
    /* bottom: 49.32%; */
    left: 231px;
    /* right: 64.24%; */
    top: 11px;

    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    /* display: flex;
    align-items: center;
    text-align: right; */

    color: #2E7C7B;
}

.arrows {

    position: absolute;
    left: 346px;
    top: 6px;
}

.icon-left {
    position: absolute;
    left: 8px;
    right: 85.14%;
    top: 47.73%;
    bottom: 43.75%;
    Width: 36px;
    Height: 36px;

    background: #FFF3EA;
    mix-blend-mode: normal;
}

.vector-left {
    position: absolute;
    left: 1.92%;
    right: 1.92%;
    top: 15.38%;
    bottom: 15.39%;

    background: #F9AB73;
}

.text-left {
    position: absolute;
    left: 56px;
    top: 50px;

    /* font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px; */
    /* identical to box height */

    /* display: flex;
    align-items: center; */

    color: #25252D;

}

.text-left-bottom {
    position: absolute;
    left: 56px;
    top: 75px;

    /* font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px; */
    /* identical to box height */

    /* display: flex;
    align-items: center; */

    color: #A7A7A7;
}

.amountt {
    /* ₹2,000.00 */
    position: relative;
    /* left: 30px; */
    /* top: 50px; */
    text-align: right;
    /* font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px; */
    /* display: flex;
    align-items: center;
    text-align: right; */
    color: #25252D;
    margin-bottom: 0px;

}

.days {
    /* 5 days ago */
    /* position: relative; */
    /* top: 43px;
    left: 16px; */
    text-align: right;
    /* font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px; */
    /* identical to box height */

    /* display: flex;
    align-items: center;
    text-align: right; */

    color: #A7A7A7;
}

.Box {
    display: flex;
}

.Transaction-logo {
    position: absolute;
    left: 12px;
    top: 54px;
}

.homeCard-Transaction {
    cursor: pointer;
}

.clickArrow{
    margin-top: 0.2rem;
}
