.addItemDiv2 {
  height: 45px;
  border: 1px solid #6c6c6c33;
  text-align: center;
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 0 0 0;
  cursor: pointer;
}
.paymentTypeSelect {
  position: absolute;
  z-index: 300;
  /* width: 100%; */
  /* top: 0; */
  padding: 0.5rem;
  background-color: #ffffff;
  padding-top: 1rem;
}
.addItemDiv3 {
  width: 885px;
  height: 45px;
  /* border: 1px solid #6c6c6c33; */
  text-align: center;
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 0 0 0;
  cursor: pointer;
}
