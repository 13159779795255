.add-lr-attachment {
  width: 25px;
  height: 36px;
  border-radius: 20px;
  border: 2px #2e7c7b;
  border-style: dotted;
  margin-left: -36px;
  opacity: 0;
  cursor: pointer;
}
.add-attach-for-fastag-linked-vehicle-new {
  position: relative;
  /* left: 83px; */
  /* top: 28px; */
  background-color: #ffffff;
  border: none;
}

.add-attach-for-fastag-linked-vehicle-new:hover {
  background-color: #ffffff;
  border: none;
}

.add-attach-for-fastag-linked-vehicle-new:focus {
  background-color: #ffffff;
  border: none;
}
.PGCollectionBox1 {
  width: 970px;
}
