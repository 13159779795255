.addItemDiv {
  width: 885px;
  height: 45px;
  border: 1px solid #6C6C6C33;
  text-align: center;
  font-family: 'Nunito-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 0 0 0;
  cursor: pointer;
}

.minBox3 {
    /* font-family: 'Roboto', sans-serif;
      color: #E2E2E2;
      font-size: 1.2rem;
        margin: 0 auto;
      padding: 1.5rem 2rem;
      border-radius: 0.2rem;
      background-color: rgb(255, 255, 255);
      border: none;
      width: 90%;
      display: block;
      border-bottom: 0.3rem solid transparent;
      transition: all 0.3s; */
    padding-left: 10px;
    color: #25252d;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    height: 30px;
    width: 90%;
  }
  .minBox3:focus {
    outline: none;
  }
  .minBox3::placeholder {
    color: #e2e2e2;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
