.card-control-toggle-slider{
    
  position: relative;
 top:-70px;
  left:360px;
  
}
.card-control-atm-pin{
    width:185px;
    height:19px;
    font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
flex-direction: row;



color: #A7A7A7;
}
.card-control-logo{
    position:absolute;
    left: 44.1px;
    top: 26.1px;
}

.body-sub-part2-here {
    margin-top: -9px;
    /* padding-left: 96px; */
    width: 92px;
    height: 27px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: #25252D;
}

.body-sub-part1 {
    padding: 30px 0 0 0px;
    margin-left: 96px;
    font-family: 'Nunito-Medium';
    /* font-style: bold; */
    font-weight:550;
    font-size: 18px;

    line-height: 16px;
    text-transform: capitalize;
    color: #6C6C6C;
}

.body-sub-part1-ATM{
    padding: 0px 0 0 0px;
    margin-left: 10px;
    font-family: 'Nunito-Medium';
    
    font-weight:500;
    font-size: 18px;

    line-height: 16px;
    text-transform: capitalize;
    color: black;
}

.change-pin-css{
    position: relative;
    top: -84px;
    left: 400px;
    font-weight:500
}

.body-sub-part1-all {
    padding: 30px 0 0 0px;
    margin-left: 10px;
    font-family: 'Nunito-Medium';
    /* font-style: bold; */
    font-weight:500;
    font-size: 18px;

    line-height: 16px;
    text-transform: capitalize;
    color: black;
}


.card-control-atm-pin {
    width: 185px;
    height: 19px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    flex-direction: row;
    color: #A7A7A7;
}

.body-sub-part2-all{
    
    margin-top: -14px;
    padding-left: 11px;
    /* margin-left: 45px; */
    width: 92px;
    height: 27px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: black;
    
}


.card-control-value-edit {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    top: -13px;
    left: -20px;
    position: relative;
    align-items: center;
    text-transform: uppercase;
    color: #2E7C7B;
}

