.search-Bar{
    box-sizing: border-box;

    position: relative;
    width: auto;
    height: 46px;
   
    border: 1px solid #C4C4C4;
    border-radius: 4px;
}

.search-bar-logo{
    position: absolute;
    top: 11px;
    left: 23px;
}

.search-bar-input{
    position: absolute;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: black;
    width: auto;

    top: 11px;
    left: 52px;
    border: none;
}

.search-bar-input:hover{
        outline: none;
}
.search-bar-input:focus{
    outline: none;
}