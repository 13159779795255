.selectDate-input-vendor{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 90px;
    height: 24px;
    color: #25252D;
    padding: 10px 10px 10px 10px;
}
.Select-Date-Vendor{
    width: 278px;
    height: 104px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(46, 124, 123, 0.05);
    border-radius: 15px;
    padding: 12px 12px 12px 12px;
    margin: 24px 24px 24px 24px;
}
.selectDate-input-Bg{
    width: 254px;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(46, 124, 123, 0.05);
    border-radius: 15px;
    margin: 0 0 0 0;
    padding: 12px 0 0 0;
}
.transfer-box{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(46, 124, 123, 0.05);
    border-radius: 15px;
    padding: 12px 12px 12px 12px;
    margin: 24px 24px 24px 24px;
}
.Select-Date-Heading{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #25252D;
    margin: 0px 0px 8px 0px;
}
.Transfer-To-Logipe-Vendor-Bg{
    width: 98px;
    height: 48px;

    background: #96A9CA;
    border: 0.5px solid rgba(108, 108, 108, 0.2);
    border-radius: 8px;
    text-align: center;
}
.transfer-box-2{
    width: 118px;
    height: 104px;

    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(46, 124, 123, 0.05);
    border-radius: 15px;
    padding: 12px 12px 12px 12px;
    margin: 24px 24px 24px 24px;
}
.Transfer-Btn{
    display: flex;
    flex-direction: row;
    gap: 10px;

    height: 24px;

    background: #485B7C;
    border: 0.5px solid #485B7C;
    border-radius: 4px;
}
.vendor-transaction-header{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #25252D;
    border-right-width: 1px;
    vertical-align:middle;
}
.upload-Icon{
    margin: 0 14px 0px 0px;
}
.add-attachment-btn-4{
    width: 35px;
    height: 35px;
    position:relative;
    top: -30px;
    opacity: 0;

}