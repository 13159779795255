.profile-img1{
    box-sizing: border-box;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    /* background: url(image.png); */
    border: 1px solid #F5F5F5;
}

.buttonStyle{
    background-color:#79ABAB ;
    display: flex;
    text-align: center;
    font-size: 12px;
    padding:0.2rem 0 0.2rem 0.5rem;
    position: relative;
    top:-0.5rem;
    border: none;
}
.adding-btn{
    width:4.5rem;
    height: 1.2rem;
    background-color:#79ABAB ;
    display: flex;
    text-align: center;
    font-size: 12px;
    padding:0.2rem 0.5rem;
    opacity: 0;
    cursor : pointer
}


.buttonStyle:focus{
    background-color:#79ABAB ;
}

.buttonStyle:hover{
    background-color:#79ABAB ;
}

.logoutBtn{
    background-color: #fdebec;
    color:#FE2950;
    width:360px;
    height: 52px;
    font-size: 20px;
    border: none;
}

.logoutBtn:focus{
  background-color: #fdebec;
  color:#FE2950;
}

.logoutBtn:hover{
    background-color: #fdebec;
    color:#FE2950;
  }

.boxAddress{
    box-sizing: border-box;
    position: relative;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
}
  

.versionText{
    text-align: center;
}