.input-amount-for-link {
    width: 362px;
    height: 0px;
    margin: 3px 0 0 92px;
    border: 0.4px solid #a7a7a7;
}

.butt-4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 52px;
    position: relative;
    left: 70px;
    font-family: "Nunito-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    background: #485B7C;
    border-radius: 4px;
    color: #ffffff;
    border: none;
}

.butt-4:hover {
    background: #ED752E;
}

.butt-4:focus {
    background: #485B7C;
    outline: none;
}

.input-body-3 {
    margin-top: 0px;
    width: 350px;
    border: none;
    margin-left: 90px;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #c4c4c4;
}

.input-body-3:focus {
    outline: none;
}

.date-input {
    display: flex;
    flex-direction: row;
}

.date-logo {
    position: absolute;
    left: 450px;
}

.add-attach {
    position: relative;
    left: 83px;
    top: -8px;
    background-color: #ffffff;
    border: none;
}

.add-attach:hover {
    background-color: #ffffff;
}

.add-attach:focus {
    background-color: #ffffff;
    box-sizing: none;
}