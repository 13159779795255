.heading1 {
  color: #25252d;
  font-size: 25px;
  font-weight: 700;
  margin-left: 14px;
}
.container2 {
  /* margin-top: 90px; */
  margin-bottom: 90px;
  border-radius: 8px 8px 8px 8px;
  background: #fff;
  /* box-shadow: 0px 4px 20px 0px rgba(37, 37, 45, 0.1); */
  padding: 30px 30px 30px 30px;
  display: flow-root;
}
.headingBox {
  justify-content: flex-start;
  display: flex;
}
.textClass {
  color: #6c6c6c;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.divider2 {
  width: 1px;
  height: 100px;
  background: #000;
  margin: 0px 20px;
  padding: 0px;
}
.textClass2 {
  color: #09bd7b;
  font-size: 18px;
  font-weight: 500;
}
.viewClass {
  border-radius: 10px;
  border: 0.5px solid rgba(108, 108, 108, 0.2);
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 15px;
  align-self: flex-start;
}
.textClass3 {
  color: #25252d;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.activeTab {
  background: #ED752E;
  color: #fff;
}
.inActiveTab {
  background: #fff;
  color: #ED752E;
}
.tab {
  border-radius: 10px 10px 0px 0px;
  border: 0.5px solid rgba(46, 124, 123, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 14px 40px 14px 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
.bottomPart {
  margin-top: 24px;
}
.text-input {
  box-sizing: border-box;
  position: relative;
  height: 30px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}
.text-input-placeholder {
  box-sizing: border-box;
  position: relative;
  height: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-family: "Nunito-Regular";
  font-weight: 400;
  line-height: 22px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  padding: 0px 10px 0px 10px;
}
.text-input-placeholder::placeholder {
  color: #e2e2e2;
}
.box2 {
  border-radius: 0px 10px 10px 10px;
  border: 1px solid rgba(108, 108, 108, 0.2);
  background: #fff;
}
.box3 {
  padding: 23px 23px 15px 23px;
}
.headingText {
  color: #25252d;
  font-size: 14px;
  font-weight: 600;
}
.text3 {
}
.table-row-customer-details2 {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #25252d;
  text-align: center;
  text-align-last: center;
}
.statusBtn {
  border-radius: 12px;
  background: #09bd7b;
  color: #fff;
  text-align: center;
  font-family: "Nunito-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 4px;
}
.dabitTxn {
  color: #e63946;
  text-align: center;
  font-family: "Nunito-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.craditTxn {
  color: #09bd7b;
  text-align: center;
  font-family: "Nunito-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dropDownDivActions {
  width: 100px;
  /* height: 27.25rem; */
  box-sizing: border-box;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  margin-top: 5px;
  z-index: 1;
}
