.enter-pending-money {
    margin: 4px 0 0 89px;
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #898989;
}

.rupee-label {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    position: relative;
    left: 90px;

    color: #25252D;

}