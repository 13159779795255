.AllTrasactionCardPos{
    margin-top: 30px;
        position:relative;
    }
    .AllTransactionDetailsBar{
        margin-top: 0px;
    }
    
    
    .close-btn-for-transaction{
        cursor: pointer;
    }
    
    .bank-logo-for-transaction{
        margin: 0 0 0 70px;
    }
    
    .transactionModalBody{
        /* height: 100px; */
    }
    
    .MasterFastagTable tr td{
        padding: 1rem 0 1rem 5.5rem!important;
        text-align: left !important;
    }
    
    .MasterFastagTable tr th{
        padding: 1rem 5rem !important;
        text-align: left !important;
    }
    
    
    .MasterFastagTable thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }