.custom-file-upload2 {
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    font-weight: 700;
    margin: -24px 5px 1px -5px;
}
.custom-file-upload2 input[type="file"] {
    display: none;
}
.svgContainerUp{
    background-color: rgba(46, 124, 123, 0.05);
    padding: 10px;
    border-radius: 8px;
    border: 0.5px solid rgba(108, 108, 108, 0.2);
    width: 66%;
}
.payButton{
    background-color: #2E7C7B;
    padding: 10px;
}

.payButtonDis{
    background-color: #a9a9a9;
    padding: 10px;
}
.payButtonDis:hover {
    background-color: #a9a9a9;
    padding: 10px;
    cursor: default;
  }

  .custom-file-upload3 {
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    font-weight: 700;
}
.custom-file-upload3 input[type="file"] {
    display: none;
}