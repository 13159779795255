.leftBorderLine{
    border: none;
    border-left: 1px solid rgba(108, 108, 108, 0.2) ;
}

.driverCardColor{
    background-color: #D5E5E5;
}

.selectedCard{
    background-color: #2E7C7B;
    color: #ffffff;
    border: 1px solid #2E7C7B;
    border-radius: 0.5rem;
}

.notSelectedCard{
    background-color: #E9F0F0;
    color: #000000;
    border: 1px solid rgba(108, 108, 108, 0.2);
    border-radius: 0.5rem;
}

.greyShadeText1{
    color: rgba(108, 108, 108, 0.6);
}

.greyShadeText2{
    color: rgba(255, 255, 255, 0.6);
}




.DriverSectionTable tr td{
    padding: 0.7rem 0 0.7rem 0.5rem!important;
    text-align: left !important;
}

.DriverSectionTable tr th{
    padding: 0.5rem 0.3rem !important;
    text-align: left !important;
}

.DriverSectionTable thead{
    border-color: transparent !important;
}

.DriverSectionTable thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

.whiteBack{
    border: 0.5px solid #E2E2E2 ;
    border-radius: 0.5rem;
    box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important;
}
.boxTxns{
    margin-top: 10px;
    border: 0.5px solid #E2E2E2 ;
    border-radius: 0.5rem;
    box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important;
}
.cardInfo{
    border: 0.5px solid #E2E2E2 ;
    border-radius: 0.5rem;
    /* box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important; */
    box-shadow: 0px 4px 20px 0px #25252D1A !important;
    height: 150px;
    margin: 2.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

}
#driver_tables th:first-child{
    border-radius:10px 0 0 10px;
  }
  
#driver_tables th:last-child{
    border-radius:0 10px 10px 0;
  }
.cardButton{
    width: 120px;
    border: 1px solid #4D5073;
    border-radius: 0.5rem;
    background-color: #4D5073;
    padding: 5px;
    margin: 5px;
}
.Button{
    width: 150px;
    border: 1px solid #4D5073;
    border-radius: 0.5rem;
    background-color: #4D5073;
    padding: 5px;
    margin:5px;
}

.ButtonInactive{
    width: 150px;
    border: 1px solid  #a9a9a9;
    border-radius: 0.5rem;
    background-color: #a9a9a9;
    padding: 5px;
    margin:4px;
}
.ButtonInactive:hover {
    width: 150px;
    border: 1px solid  #a9a9a9;
    border-radius: 0.5rem;
    background-color: #a9a9a9;
    cursor: default;
    padding: 5px;
    margin:4px;
  }

.driverHeader {
    padding: 5px;
    font-weight: 600;
    margin-top: -10px;
    font-size:larger;
    display: flex;
    justify-content: space-between;
}
.left-btn{
   border-radius: 2rem;
   border: none;
   background: rgba(46, 124, 123, 0.1);
   font-weight: 500;
   font-size: 1rem;
   /* font-family: 'Nunito-Regular' */
}
   
.fontNB24 {
    font-family: "Nunito-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

.fontNR16 {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.cardInfo3{
    border: 0.5px solid #E2E2E2 ;
    border-radius: 0.5rem;
    /* box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important; */
    box-shadow: 0px 4px 20px 0px #25252D1A !important;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

}

.fontNM11 {
    font-family: "Nunito-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
}

.fontNB20 {
    font-family: "Nunito-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
}
