.card-12{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    border-radius: 5px;
    border: 1px solid #25252D;
    background: #FEFFFF;
    width:380px;
    height:160px;
    padding: 14px 22px;
}
.keys {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
}

.values{display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: flex-end;
    justify-content: space-between;
    gap: 12px;}