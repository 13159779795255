.FullVehicleListBox{

    
    height: 934px;
    
    background: #FFFFFF;
    border-radius: 4px 0px 0px 0px;
}
.FullVehiclesListTopBox{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FullCustomerListTopBoxV2{
    display: flex;
    flex-direction: row; 
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
}

/* .FullVehicleDetailsList_driverListTable{
    width:500px;
    height: 550px;
    overflow: scroll;
} */


.FullVehiclesListHeader{
    position: relative;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 27px;
text-transform: capitalize;
padding: 12px;
color: #25252D;
}
.FullVehiclesListAddButton{

    position: relative;
    padding: 12px;
   


}
.FullVehiclesListSearchBar{
   padding: 12px;
    position: relative;
}
.VehicleListSwitchHandler{
    margin-top:40px;
}
.endBar-vehicleCard{
    width: 454px;
    height:0.1px;
    color: #E2E2E2;
    margin-top:200px;
}
.FullVehicleDetailsList{
    margin-top:10px;
    margin-left: 0px;
}
.FullVehicleDetailsListBar{
    padding: 12px 0px;
    border-bottom: solid 1px #e2e2e2;
}

.autoRechargeBtn{
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
}


.FontNS15 {
    font-family: "Nunito-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 1px;
  }

  @media (max-width: 1400px) and (min-width:1200px){
    .FullCustomerListTopBoxV2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 0.5rem;

    }

}