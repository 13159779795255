.FullDriverListBox{
position: relative;

height: 934px;

background: #FFFFFF;
border-radius: 4px 0px 0px 0px;
}
.driverTh{
    background: #fff;
    position: sticky;
}
#scrollableDivDriverm{
    /* overflow: auto; */
    
    border-collapse: collapse;
height: 100%;
}
.FullDriverListHeader{
position: relative;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 27px;
text-transform: capitalize;
padding: 12px;
height: 66px;
color: #25252D;
display: flex;
align-items: center;
}
.FUllDriverListSearchBar{
   padding: 12px;;
    position: relative;
}
.FullDriverListNavigation{
    position: relative;
    margin-left:12px;
    margin-top:0px;
}
.FullDriverListBar{
    position: relative;
    margin-left:20px;
    margin-top:25px;
}
.FullDriverListCard{
    position: relative;
    margin-left:12px;
  
}
.FullDriverListBarLoc{
    position: relative;
   
}

.setBorderColor{
    /* border:1px green; */
}

.ManagedriverDetailCard {

    width: 195px;
    height: 22px;
    position: relative;
    top: 19px;
    left: 20px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    color: #000000;
}

.ManagedriverDetailsCarType1Box {
    width: 454px;
    height: 74px;


    background: #ffffff;
    border-radius: 4px;
}


.pendingFastagBalance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #060606;
}


.scrolldriverList {
    overflow: auto;
    overflow-y: scroll;
    height: 700px;
    width: 454px;
    margin: 22px 0 0 0;
}

.ManagedriversDetailsCardName {
   
    /* identical to box height */
    position: relative;
    margin-top: 15px;
    margin-left: 60px;

    color: #6C6C6C;
}

.ManagedriverDetailsCardBalance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 19px;
    margin-left: 50px;
    color: #25252D;
}

.ManagedriverDetailsCarType1Box:hover {

    background: #F3F5FD;
    border-radius: 4px;
}

.profile-img-driver-list {
    /* margin-left: 2rem; */
    box-sizing: border-box;
    position: relative;
    left: -3px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    top: 7px;
    background: #F3F5FD;
    border: 1px solid #E0E4F9;
}

.active-vehicel-no {
    background-color: blue;
}

.text-in-circle {
    position: relative;
    top: 8px;
}

.driver-number-in-driver-list{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #25252D;
}

.driver-name-in-driver-list{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #6C6C6C
}

.driver-list-fastag-balance{
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    overflow: auto
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white
    ; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #485B7C;
    border-radius: 5px 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #485B7C    ; 
  }



  .rectangle-for-driver {
    width: 4px;
    height: 58px;
    /* background: red; */
    min-width: -38px;
    position: relative;
    left: 0px;
    background: #6378E3;
  }

  /* .actve-button-chosen {
    height: 74px;
    padding-top: 5px;
    border-left: solid #6378E3;
} */

/* .row-for-vehicle-list {
  
    margin: 0;
} */

.ManageVehicleDetailCard {
    width: 195px;
    height: 22px;
    position: relative;
    top: 19px;
    left: 20px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #000000;
}

.ManageVehicleDetailsCarType1Box {
    width: 454px;
    height: 74px;
    background: #FFFFFF;
    border-radius: 4px;
}
.pendingFastagBalance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* position: relative;
    left: -24px;
    top: 16px; */
    color: #060606;
}
.scrollVehicleList {
    overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 700px;
 
    /* margin: 22px 0 0 0; */
}
.ManageVehiclesDetailsCardName {
    /* identical to box height */
    position: relative;
    margin-top: 15px;
    margin-left: 60px;
    color: #6C6C6C;
}
.ManageVehicleDetailsCardBalance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 19px;
    margin-left: 50px;
    color: #25252D;
}
.ManageVehicleDetailsCarType1Box:hover {
    background: #F3F5FD;
    border-radius: 4px;
}
.profile-img-vehicle-list {
    box-sizing: border-box;
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    top: 16px;
    background: #F3F5FD;
    border: 1px solid #E0E4F9;
}
.active-vehicel-no {
    background-color: blue;
}
.text-in-circle {
    position: relative;
    top: 8px;
}
.vehicle-number-in-vehicle-list {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #25252D;
}
.vehicle-name-in-vehicle-list {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #6C6C6C;
}
.vehicle-list-fastag-balance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.active-vehicle {
    background: #F3F5FD;
}
/* .not-active-vehcile{
    background-color: "white",
} */
.active-border {}
.low-balance-css {
    color: #FE2950;
}
.active-button-chosen {
    /* margin-top: 10px;
    height: 54px; */
    padding: 0px;
    
    border-left: 4px solid #6378E3;
}
.row-for-vehicle-list {
   
    height: 74px;
    margin: 0;
}

.active-button-list{
background: #F3F5FD;

border-radius: 4px;}

.salaryBtn1{
    padding: 0.6rem 2rem;
    border-radius: 0.8rem;
    border: 1px solid #2E7C7B;
    color:#2E7C7B;
    background-color: #FFFFFF;
}


.salaryBtn2{
    padding: 0.6rem 1rem;
    border-radius: 0.8rem;
    border: 1px solid #2E7C7B;
    color:#2E7C7B;
    background-color: #FFFFFF;
}

.flexCls{
    display: flex;
    flex-direction: row;
}