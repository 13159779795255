.notificationLogo{
    /* padding-top: 10px; */
    padding-top: 0.4rem;
    padding-right: 1rem;
    background: #FFFFFF;
    border: none;

}
.notificationLogo:hover{
    background-color: #EFEFEF;
    box-sizing: none;
}

.notificationLogo:focus{
    background-color: #EFEFEF;
    box-sizing: none;
}
.header-info{
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 7px 10px;
    margin-right:20px;
    border-radius: 8px;
}

.profile-img{
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    /* background: url(image.png); */
    border: 1px solid #F5F5F5;
}

.profile-desc{
    display: flex;
    flex-direction: column;
}

.header-info:hover{
    background-color:#EFEFEF;
} 



.profile-name{
    margin-left: 8px;
    margin-top: 2px;

    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #000000;
}

.profile-title{
    display: flex;
    flex-direction: row;

    margin: -16px 0 0 9px;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #6C6C6C;
}

.logout-btn{
    position: relative;
    width: 88px;
    height: 36px;
    margin-top : 8px;
    
    border: none;

    background: rgba(254, 41, 80, 0.1);
    border-radius: 4px;
}

.logout-btn-logo{
    position: absolute;
    left: 10px;
    top: 9px;
}

.logout{
    position: absolute;
    left: 29px;
    top: 6px;
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #FE2950;
}

.logout-btn:hover{
    background-color: rgba(254, 41, 80, 0.1);;
    box-sizing: none;
}

.logout-btn:focus{
    background-color: rgba(254, 41, 80, 0.1);;
    box-sizing: none;
}

.right-info{
    display: flex;
    flex-direction: row;
    position: relative;
  
}
    