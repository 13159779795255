.DriverRequestTypeBox{
    
    width: 278px;
    height: 49px;
   
    
   

}
.DriverTypeClicked{
    position: absolute;
width: 278px;
height: 49px;


background: #F0F2FD;
}
.DriverTypeNonCLicked{
    position: absolute;
    width: 278px;
    height: 49px;
  
    
    background:#fff;
    
}
.DriverRequestTextClicked{
    position: absolute;
   
    left: 45px;
    top: 11px;
   
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #6378E3;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
}
.DriverRequestNumberBoxClicked{
    position: absolute;
    width: 28px;
    height: 24px;
    left: 195px;
    top: 11.5px;
    
    background: #6378E3;
    border-radius: 2px;
}
.DriverRequestNumberClicked{
    position: absolute;
 left:10px;
 top: 3px;
   text-align: center;
    
   font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #FFFFFF;
}.DriverRequestTextNonClicked{
    position: absolute;
   
    left: 45px;
    top: 11px;
   
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #6C6C6C;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
}
.DriverRequestNumberBoxNonClicked{
    position: absolute;
    width: 28px;
    height: 24px;
    left: 195px;
    top: 11.5px;
    
    background: rgba(99, 120, 227, 0.1);
    border-radius: 2px;
}
.DriverRequestNumberNonClicked{
    position: absolute;
 left:10px;
 top: 3px;
   text-align: center;
    
   font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #6C6C6C;
}
.endBar{
    position: absolute;
width: 278px;
height: .1px;
top: 50px;
background: #d2d2d7;
}
.endBarClicked{
    position: absolute;
    width: 278px;
    height: 4px;
    top: 50px;

    
background: #6378E3;

}