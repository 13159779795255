.textCenter{
    text-align: center;
}

.pointing{
    cursor: pointer;
}

.transferButton{
    position: relative;
    width: 48px;
    height: 48px;
    border: none;
    background: #96A9CA;
    border-radius: 4px;
}


.transferButton:hover{
    background-color: #ED752E;
}

.transferButton:focus{
    background: #EBF2F2;
    box-shadow:none
}