.issueCardLink{
    text-decoration: none;
}

.issueCardLink:hover{
    color:#0a58ca !important;
    
}

.backFocus:hover{
    background-color:#EFEFEF;
}

.backFocusVKYC:hover{
    /* background-color:#EFEFEF;
    width: 70%;
    border-radius: 4px; */
}

.disabledRow{
    background-color:#F2F2F2;
}