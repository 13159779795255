.main-body{
    display: flex;
    flex-direction: row;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: local('Roboto'), url("./assets/fonts/Roboto-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Nunito-Regular';
    src: local('Nunito'), url("./assets/fonts/Nunito-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: local('Nunito'), url("./assets/fonts/Nunito-SemiBold.ttf") format('truetype');
}


@font-face {
    font-family: 'Nunito-Bold';
    src: local('Nunito'), url("./assets/fonts/Nunito-Bold.ttf") format('truetype');
}


@font-face {
    font-family: 'Nunito-Medium';
    src: local('Nunito'), url("./assets/fonts/Nunito-Medium.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local('Roboto'), url("./assets/fonts/Roboto-Medium.ttf") format('truetype');
}

.App{
    
    /* height: 1026px; */
}

.is-invalid{
    border-color: red;
}


.notifyDiv{
    position:absolute;
    right: 0;
    z-index: 10000;
}

.toast-header .btn-close{
    position: absolute;
    right:1rem;
}

.toast-header{
    background-color: #2e7c7b;
    color:#FFFFFF;
    font-weight: 500;
    font-size: 1.1rem;
    font-weight: 24px;
    padding: 0.6rem;
    border: 0.1rem solid black;
}

.toast-body{
    font-weight: 500;
    font-size: 1rem;
    font-weight: 20px;
    padding: 1rem;
    background-color:#FFFFFF;
}