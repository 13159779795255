.main-recent-transaction{
        /* Rectangle 828189 */
        /* width: 379px; */
        height: 106px;
    
    
}

.recent-Trans{
    width: 160px;
    height: 22px;
    padding: 0 0 0 12px;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 600;
    /* font-size: 16px;
    line-height: 22px; */
    /* identical to box height */

    /* display: flex;
    align-items: center; */

    color: #25252D;
}

.fastag-History{
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    /* left: 40px; */
    /* identical to box height */

    /* display: flex;
    align-items: center;
    text-align: right; */

    color: #485B7C;
}

.forward-arrow{
    position: relative;
    left: -8px;
    top: -5px;
}


.receiver-fastag{
    margin: 0px 0 0 20px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    /* display: flex;
    align-items: center; */

    color: #25252D;

}

.to-fastag{
    margin: 0px 0 0 20px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    /* display: flex;
    align-items: center; */

    color: #A7A7A7;
}

.amountt-fastag{
    /* ₹2,000.00 */
    margin : 0;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px;
    line-height: 16px; */
    /* display: flex;
    align-items: center;
    text-align: right; */

    color: #25252D;

}

.days-fastag{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px;
    line-height: 19px; */
    /* identical to box height */

    /* display: flex;
    align-items: center;
    text-align: right; */

    color: #A7A7A7;
}
.Box{
        display: flex;
}

.green-toll-booth-fastag{
    position: relative;
    left: 12px;
}