.manage-customer-dashboard-desc{
    position: relative;
   
    font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;

color: #6C6C6C;
}
.ManageCustomers-mid-section{
    padding: 16px;
    padding-top: 48px;

}
/* .ManageCustomerMidHeader{
    position: relative;
    font-family: 'Nunito';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 27px;

color: #25252D;
} */