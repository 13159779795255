.PGCollectionCardBox{
    position: relative;
    width: 811px;
    height: 80px;
    
    background: #FFFFFF;
    
}
.PGCollectionNameClass{
    position: relative;
    width: 77px;
    height: 22px;
    margin-left: 30px;
    
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;

    
}
.PGCollectionCurrentBal{
    /* position: relative;
    width: 74px;
    height: 22px;
    margin-left: 250px; */
   
   
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    
    /* color: #00E676; */
}


.PGCollectionRow2{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}
.PGCollectionCurrentBal1{
    position: relative;
    width: 74px;
    height: 22px;
    margin-left: 120px;
   
   
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}


.OlderHistoryButton{
    cursor: pointer;
    position: relative;
    text-align: center;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    top: 1rem;
    /* margin-bottom: 2.5rem; */
    background-color: white;
}

.PGCollectionRequestDate{
    position: relative;
    width: 91px;
    height: 22px;
    margin-left: 230px;
    
   
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
    

}
.PGCollectionRequestBal{
    position: relative;
    width: 84px;
    height: 22px;
    left: 575px;
  
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}
.PGCollectionCode{
    position: relative;
    width: 200px;
    height: 19px;
    margin-left: 30px;
    margin-top: 0px;
        
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #25252D;
}
.PGCollectionText{
    position: relative;
    width: 200px;
    height: 19px;
    margin-left: 30px;
    top: 0px;  
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #6C6C6C;
}
.PGCollectionDots{
    position: relative;
    width: 91px;
    height: 22px;
    left: 750px;
    top: -70px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;

}
.PGCollectionEndBar{
    position: relative;
width: 811px;
height: 0px;
    left: 0px;
margin-top:20px;


border: .1px solid #E2E2E2;
}


.PGCollectionRequestTime{
    position: relative;
    width: 200px;
    height: 22px;
    top:0;
    left: 27rem;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}

.PGCollectionRequestTime1{
    position: relative;
    width: 200px;
    height: 22px;
    top:0;
    left: 41rem;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}


