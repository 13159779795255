
.button{
    border:none;
    width: 130px;
    height: 70px;
    margin: 0 0 12px 0;

}
.main_div{

        
}
.Clicked{
    background-color: #ED752E;
}
.notClicked{
    background-color:#fff;
}
.nonClicked{
    /* display: flex; */
    flex-direction: column;
    width:130px;
    text-align:center;
    border: none;
    align-items:center;
}

.nonClicked:hover{
    background-color: #2E7C7B;
    color: white;
}
.nonClicked:focus{
    background-color: #2E7C7B;
    color: white;
}
.buttonShape{
    padding-top: 0px;
    color:'blue'
}
.textButton{
    position:absolute;                   
    text-align: center;
}
.buttonTextNonClicked{

width: 113px;
height: 16px;
margin-top: 5px;
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    

}
.buttonTextClicked{

    width: 60px;
    height: 16px;

        font-family: 'Nunito-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;

    
}

.text-Click{
    color:white;
}

.text-not-clicked{

    color:black;
}