.PGCollectionBox {
    position: relative;
    height: 100%;
    background: #fff;
}

.PGCollectionclose {
    display: inline;
    margin-left: 800px;
    position: relative;
    background: #fff;
    right: 10px;
    margin-top: -10px;
    border: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    top: 30px;
}

.PGCollectionCloseIcon {
    position: relative;
    width: 1%;
    height: 1%;
    margin-top: 38px;

}

.PGCollectionCloseText {
    position: relative;
    left: 47px;

    margin-top: 42px;
    bottom: 94.34%;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #25252D;
}

.PGCollectionHeader {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
}

.PGCollectiontText {
    /* margin: 4px 0 0 5rem; */
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-transform: capitalize;

    color: #25252D;
}

.PGCollectiontText1 {
    margin: 4px 0 0 5rem;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-transform: capitalize;

    color: #25252D;
}


.PGCollectionIcon {
    /* position: relative;
    margin-left: 70px;
    margin-top: 80px;
    bottom: 12.5%; */
    position: relative;
    left: 4rem;
}

.PGCollectionSearchBar {

    position: relative;
    margin-left: 70px;
    margin-top: 25px;
}

.PGCollectionButton {
    margin-left: 290px;
    margin-top: 75px;
    position: relative;
}

.PGCollectionHeaderBar {
    position: relative;
    margin-left: 80px;
    margin-top: 20px;
}

.PGCollectionCard {
    margin-left: 80px;
    margin-top: 20px;
    position: relative;
}

.PGCollection-modal {}

.closeBtnPG {
    display: none;
}

.linkToSettlement{
    position: relative;
    top:1rem;
    left:20rem;
    cursor: pointer;
}
.cashbackListing{
    max-height:650px;
    position: relative;
    top: 0;
    overflow-y: scroll;
    white-space: nowrap;
    direction: row;
   
}

.SettlementCurBalance{
    position: relative;
    /* width: 119px; */
    height: 19px;
    text-align: center;
    top:1rem;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}


.SettlementReqDate{
    position: relative;
    /* width: 93px; */
    height: 19px;
    top:1rem;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}



/* 
.modal-content{
    height: 10px !important;
} */
.collectionTableCss tr td{
    padding: 1rem 1rem 0 3.2rem!important;
    text-align: left !important;
}

.collectionTableCss tr th{
    padding: 1rem 3rem !important;
    text-align: left !important;
}

.collectionTableCss tbody {
    display: block;
    max-height: 30rem;
    overflow-y: scroll;
  }

.collectionTableCss thead, .collectionTableCss tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .colorText{
    color: #6C6C6C;
  }