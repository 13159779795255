.ManageVehicleDetailCard {

    width: 195px;
    height: 22px;
    position: relative;
    top: 19px;
    left: 20px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    color: #000000;
}



.ManageVehicleDetailsCarType1Box {
    width: 454px;
    height: 74px;


    background: #ffffff;
    border-radius: 4px;
}


.pendingFastagBalance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* position: relative;
    left: -24px;
    top: 16px; */
    color: #060606;
}


.scrollVehicleList {
    overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 700px;
   
    /* margin: 22px 0 0 0; */
}

.ManageVehiclesDetailsCardName {

    /* identical to box height */
    position: relative;
    margin-top: 15px;
    margin-left: 60px;

    color: #6C6C6C;
}

.ManageVehicleDetailsCardBalance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 19px;
    margin-left: 50px;
    color: #25252D;
}

.ManageVehicleDetailsCarType1Box:hover {

    background: #F3F5FD;
    border-radius: 4px;
}

.profile-img-vehicle-list {
    box-sizing: border-box;
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    top: 16px;
    background: #F3F5FD;
    border: 1px solid #E0E4F9;
}

.active-vehicel-no {
    background-color: blue;
}

.text-in-circle {
    position: relative;
    top: 8px;
}

.vehicle-number-in-vehicle-list {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #25252D;
}

.vehicle-name-in-vehicle-list {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #6C6C6C;

}

.vehicle-list-fastag-balance {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.active-vehicle {
    background: #F3F5FD;
}

/* .not-active-vehcile{
    background-color: "white",
} */

.active-border {}

.low-balance-css {
    color: #FE2950;
}

.actve-button-chosen {
    height: 74px;
    padding-top: 5px;
    border-left: solid #6378E3;
}


/* .row-for-vehicle-list {
   
    height: 74px;

    margin: 0;
} */

.active-button-list {
    background: #F3F5FD;
    background: #F3F5FD;
    border-radius: 4px;
    
}

.active-div {

    height: 74px;
    position: relative;
    left: -14px
}
    .ManageVehiclesDetailsCardName{
        font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
position: relative;
margin-top:15px;
margin-left:60px;

color: #6C6C6C;
    }
    .ManageVehicleDetailsCardBalance{
        font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
margin-top:19px;
margin-left:50px;
color: #25252D;
    }
.ManageVehicleDetailsCarType1Box:hover{
   
background: #F3F5FD;
border-radius: 4px;
}    


.customerTable th{
    width: 200px;
}


.fastagLinking{
    padding:0.5rem;
    width: 75%;
    border-radius: 5px;
    text-decoration: none;
    color:#0d6efd;
    /* color: #FE2950; */
}

.fastagLinking:hover{
    /* text-decoration:underline; */
    background-color:#EFEFEF;
}