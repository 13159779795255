.main-customer-vehicle-detail{

background: #FFFFFF;
border-radius: 4px 4px 0px 0px;
}


.vl{
    border-left: 1px solid black;
    height: 8px;
}

.recharge-fastagId-button{
    font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 1rem;
line-height: 22px;
/* identical to box height */

text-align: center;
    position: relative;
 
width: 160px;
height: 42px;
border: none;
color : white;
background: #485B7C;
border-radius: 4px;
}



.vehicle-no{
   
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
   
    
    
    color: #25252D;
}

.fastagId-title{
    position: relative;
  
    font-family: 'Nunito-Regular';
    font-style: normal;
   
    line-height: 25px;
   
    color: #25252D;

}

.fastag-Id-No{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
border-left: 1px solid ;
  height: 24px;
/* identical to box height */


color: #25252D;
}

.main-container-fastag{
    position: relative;
   
}


.main-vehcile-No{
    padding-left: 16px;
}


.auto-recharge{
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
@media (max-width: 1400px) and (min-width: 1200px) {
    .recharge-fastagId-button{
        font-size: 0.9rem;
    }
}


