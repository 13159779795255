.manage-vehicle-container{
  margin-top: 90px;
}

.manage-vehicle-card-details-container{
   
    padding-top: 10px;
    
}

.div-table-row-gps {
  width: auto;
  clear: both;
  padding: 0px 8px;
}
