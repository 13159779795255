.ManageDriverDetailsCardType2Box {
    position: relative;
    width: 784px;
    height: 78px;
    display: flex;
    flex-direction: row;
    background: #ffffff;
}

.ManageDriverCardType2Name {
    position: relative;
    width: 47px;
    height: 22px;
    margin-top: 17px;
    padding-left: 25px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #25252d;
}

.ManageDriverCardType2Amount {
    position: relative;
    width: 60px;
    padding-left: 20px;
    height: 22px;
    margin-top: 17px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #25252d;
}

.ManageDriverCardType2Remarks {}

.ManageDriverCardType2PaymentType {
    position: relative;
    width: 120px;
    height: 22px;
    margin-top: 17px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #25252d;
}

.ManageDriverCardType2Date {
    padding-top: 17px;
    position: relative;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #25252d;
}

.ManageDriverCardType2Attachment {
    margin-top: 17px;
    left: 40px;
    position: relative;
    font-family: "Nunito=Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    height: 22px;
    /* identical to box height */
    color: #25252d;
}

.ManageDriverCardType2Action {
    position: relative;
    margin-top: 17px;
    margin-right: 0px;
    left: 60px;
}

.ManageDriverCardType2Remarks {
    position: relative;
    margin-top: 5px;
    left: 20px;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    color: #898989;
}

.ManageDriverCardType2EndBar {
    position: relative;
    width: 811px;
    height: 0px;
    margin-left: -12px;
    border: 0.25px solid #e2e2e2;
}

.customerSDetailsCardName {
    margin-left: 20px;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #25252d;
}

.customerSDetailsCardBal {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #25252d;
}

.customerSDetailsCardId {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    color: #6c6c6c;
}

.customersDetailsCardDate {
    margin-left: 10px;
}

.customerDetailsAmountCard {
    margin-left: 10px;
}

.customersDetailsAttachment {
    margin-left: 10px;
}

.customerDetailsAction {
    padding-left: 0px;
}

.table-row-customer-details {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #25252d;
    height: 100px;
    text-align: center;
}

.table-header-customer-details {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    font-size: "14px";
    /* identical to box height */
    background: rgba(46, 124, 123, 0.1);
    color: #25252d;
    height: 51px;
    text-align: center;
}

/* .table-for-customer-receivable {
    overflow: hidden;
    overflow-y: scroll;
    height: 367px;
    position: sticky;
} */

.view-all-transaction-customer-receeivabl1 {
    position: relative;
    /* top: 40px; */

    background-color: white;
    padding: 2rem 10rem 0.5rem;

    font-family: "Nunito-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: #25252d;
    cursor: pointer;
}

.th {
    top: 0px;
}
.fontClass{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* width: auto !important; */
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.fontClass2{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D
}
.table-row-manage-driver{
    height:10px;
    border-bottom: 1px solid #E2E2E2;
}

.table-row-manage-driver-new{
    height:10px;
    border-width:0 !important;
    /* border-bottom: 1px solid #E2E2E2; */
}

.view-all-transaction-customer-receeivabl {
    position: relative;
    /* top: 95px; */
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #25252D;
    cursor: pointer;
}


.editDropdown{
        position: absolute;
        width: 100px;
       height: 100px;
       box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
       border-radius: 4px;
       padding:0.5rem;
       right: 45px;
}

.endingLine2{
    width: 100%;
    height: 0px;
    position: relative;
    border: 0.4px solid #E2E2E2;
}

.noPadding{
    padding-right: 0 !important;
    overflow-y: hidden;
}

.actionDots:hover{
    background-color: #EFEFEF;
    border-radius: 50%;
    width: "2.2rem" !important;
    height: "2.2rem" !important;
}