
  .form-control {
    display: block;
    margin: 0rem 5.2rem;
    width: 75%;
}
.sub-head-body{
margin-top: -15px;
width: 341px;
height: 19px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
color: #898989;
}

.input-amount{
width: 362px;
height: 0px;
margin: 8px 0 0 96px;
border: 1px solid #A7A7A7;
}
.searchInput{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: black;
    width: 95%;
   
    border: none;
  }
  
  .searchInput:hover{
    outline: none;
  }
  .searchInput:focus{
  outline: none;
  }

  .plusButton{
    left: 6px;
    top: -25px;
    position: relative;
  }