.main-body{
    display: flex;
    width:1440px;
    height:1024px;
    background: #e9edf8
    ;}

.roundCorner{
    border-radius: 4px;
}

.paddingR{
    padding-right:1.5rem !important;
}

.yellowBanner{
    background-color: #FEF1CD;
    border-radius: 0.5rem;
    padding: 1rem;
}

.BtnCursor{
    cursor: pointer;
}

.connectDiv{
    background-color: #96A9CA;
    padding: 0.8rem;
}