.Button2 {
  width: 120px;
  border: 1px solid #2e7c7b;
  border-radius: 0.5rem;
  background-color: #2e7c7b;
  padding: 5px;
}
.Button3 {
  width: 60px;
  border: 1px solid #2e7c7b;
  border-radius: 0.5rem;
  background-color: #2e7c7b;
  padding: 5px;
  margin: 5px;
}
.Button4 {
  width: 50px;
  border: 1px solid #4d5073;
  border-radius: 0.5rem;
  background-color: #4d5073;
  padding: 0px;
  height: 25px;
}

.fontNM15{
    font-family: 'Nunito-Medium';
    font-weight:500;
    font-size: 15px;

    line-height: 16px;
}

.fontNM12{
    font-family: 'Nunito-Medium';
    font-weight:500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 16px;
}
.fontNM10{
  font-family: 'Nunito-Medium';
  font-weight:500;
  font-size: 10px;
  color: #FFFFFF;
  line-height: 16px;
}

.listItems2{
    font-size: 13px;
    font-weight: 600;
    line-height: 21.82px;
    position: relative;
    left: 0.7rem;
    cursor: pointer;
}

.table-row-customer-details3 {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */
  color: #25252d;
  text-align: center;
  text-align-last: center;
}

.dropDownDivAction2 {
  /* height: 27.25rem; */
  box-sizing: border-box;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  margin-top: 5px;
  z-index: 1;
}