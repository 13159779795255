.manageLogipeMidCard{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    position: relative;
    /* width: 308px; */
    /* height: 100px; */
    cursor: pointer;
    
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    
}
.manageLogipeCardTitle{
/* height: 25px; */
/* margin-top:10px; */
/* padding-top:28px; */
margin-left:10px;
/* font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px; */
/* identical to box height */
display: flex;
align-items: center;

color: #25252D
}
.topUpWalletBox{
width: 48px;
height: 48px;
/* margin-top:20px;
margin-left:12px; */

background: #96A9CA;
}
.ManageLogipeCardVector{
    margin-left:5px;
    margin-top: 24px;
}
.topUpLogipeCard{
    margin-top:10px;
    margin-left:8px;
}