.TripCardContainer{
    padding-top:1rem;
    padding-left:1.5rem;
    border-radius:8px;
    padding-right:1.5rem;
    background-color: rgba(46, 124, 123, 0.05);
}
.TripCardBold{
    font-family: 'nunito-regular';
    font-size: 1.12rem;
    line-height: 1.5rem;
    font-weight: bold;
}
.TripCardMedium{
    font-family: 'nunito-regular';
    font-size: 0.85rem;
    line-height: 1.3rem;
    font-weight: medium;
}
.TripCardSemiBold{
    font-family: 'nunito-regular';
    font-size: 0.85rem;
    line-height: 1.3rem;
    font-weight: 600;
}
.dashedLineTrip{
    width: 50%;
     margin-top: 5%;
     margin-bottom: 5%;
     margin-left: 2%;
     margin-right: 2%;
    border-width: 0.5px 0 0 0 ; 
    /* border-top-width: 0.5px;  */
    border-style: dashed;
 }