.outer{
    position: relative;
width: 48px;
height: 48px;
left: 30px;
top: 233px;
border: none;
background: #EBF2F2;
border-radius: 4px;
}

.outer:hover{
    background-color: #FFFFFF;
}

.outer:focus{
    background: #EBF2F2;
    box-shadow:none
}


.last-text{
    margin: 244px 6px 0px 18px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
text-transform: capitalize;

color: #000000;
}




.butt-3{
    margin: 28px 0 0 -1px;
    box-sizing: border-box;
    width: 121px;
    height: 42px;
    background: #FFFFFF;
    color: #6C6C6C;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
}
.setting-btn-3{
    margin: 28px 0 0 -1px;
    box-sizing: border-box;
    width: 121px;
    height: 42px;
    color: #FFFFFF;
    background: #2E7C7B;
    border-radius: 4px;
    
}
.btn-container{
    display: flex;
    flex-direction: column;
}

.wallet-logo{
    margin-left: -3px;
}
