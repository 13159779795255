.infoDiv{
    height: 40%;
    padding: 1rem 1rem;
    background-color: #96A9CA;
    border-radius: 0 0 0.5rem 0.5rem ;
  }
  

.imgTyre{
    align-items: center;
    text-align: center;
    margin: auto;
  }

.imageDiv{
    height: 60%;
    background-color:#FFFFFF;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    border: 1px solid #E3E3E3;
    align-items: center;
    text-align: center;
  }

.linkTag{
    cursor: pointer;
  }

.itemContainer{
  height: 19rem;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  padding: 0;
  background-color: #F8FEFE;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.logiMartItemContainer{
    padding: 0.5rem 1.5rem;
  }

.modelName{
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 16.94px;
    padding-bottom: 0.5rem;
}

.priceMRP{
    font-weight: 600;
    font-size: 1rem;
    line-height: 21.82px;
    color: #FF3A44;
    text-decoration: line-through;
    margin-left: 8px;
  }

.priceSP{
    font-weight: 600;
    font-size: 1rem;
    line-height: 21.82px;
  }

.SaveWithCoins{
    color: #09BD7B;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 19.1px;
    display: flex;
    flex-direction: row;
    padding-top: 0.5rem;
  }  
  /* mobile version */
@media only screen and (max-width: 600px){

  .SaveWithCoins{
    font-size: 0.6rem;
    line-height: 14px;
    padding-top: 0.4rem;
  }
    .priceMRP{
        font-size: 0.75rem;
        line-height: 14.5px;
        padding: 0;
      }
    .priceSP{
        font-size: 0.75rem;
        line-height: 14.5px;
        padding: 0;
      }

    .modelName {
        font-size: 0.75rem;
        line-height: 14px;
        padding-bottom: 0.35rem;
      }
    .infoDiv{
        padding-top: 0.4rem;
        padding: 0.4rem 0.5rem 1rem;
      }

    .logiMartItemContainer{
        padding: 0.5rem 0.75rem;
    }
    .itemContainer{
        height: 15rem;
      }
}