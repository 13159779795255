.FullDriverDashboardBox{
    /* margin-top:90px; */
}
.FullDriverDashboardCard-correction{
   display: flex;
   flex-direction: row;
   margin-left: 1px;
}

.FullDriverDashboardEmpty{
   padding: 1.875rem 0 0 1.8rem ;
   /* margin-left: 29px;
   margin-top:30px; */
}

.backColor{
   background-color: rgba(46, 124, 123, 0.05);
}
body{
       font-family:'Nunito-Regular';
       /* height: 1026px; */
   }

.leftTopDiv {
   position: relative;
   text-transform: capitalize;
   padding:5px;
   border-radius: 8px;
   border: 0.5px solid #ededf1;
   background: #ededf1;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.bulkSalarySvgHeading{
   display: flex;
   align-items: center;
   justify-content: flex-start;
}