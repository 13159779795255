.fullDriverList-Bar{
    width: 733px;
    height: 51px;
    position: absolute;

}

.fullDriverListBarALL{
    position: absolute;
    width: 100px;
    height: 51px;
 padding-left:15px;
 padding-top:11px;
 font-family: 'Nunito-Regular';
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 22px;
 /* identical to box height */
 
 
 color: #6C6C6C;
}
.fullDriverListBarLow{
    width: 210px;
    height: 51px;
    margin-left:80px;
    padding-left: 15px;
    padding-top:11px;
    position: absolute;
    font-family: 'Nunito-Regular';
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #6C6C6C;
}
.fullDriverListBarKYC{
    width: 210px;
    height: 51px;
    margin-left:250px;
    padding-left: 15px;
    padding-top:11px;
    position: absolute;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #6C6C6C;

}
.fullDriverListBarALLClicked{
    position: absolute;

 padding-left:15px;
 padding-top:11px;
 font-family: 'Nunito-Regular';
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 22px;
 /* identical to box height */
 
 
 
color: #6378E3;
background-color: #F0F2FD;
}
.fullDriverListBarLowClicked{

    margin-left:80px;
    padding-left: 15px;
    padding-top:11px;
    position: absolute;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
   
color: #6378E3;
background-color: #F0F2FD;

}
.fullDriverListBarKYCClicked{

    margin-left:250px;
    padding-left: 15px;
    padding-top:11px;
    position: absolute;
    font-family: 'Nunito-Regular';
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    
color: #6378E3;
background-color: #F0F2FD;
}
.fulldriverEndBar{
    position: relative;
width:454px;
height: .1px;
margin-top: 40px;
background: #d2d2d7;
}
.fulldriverEndBarClicked{
    position: relative;
    width: 454px;
    height: 4px;
    margin-top: 30px;

    
background: #6378E3;

}