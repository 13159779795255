.model-width {
  max-width: 965px;
  /* border-radius: 10px; */
}

/* #modal3 + .modal-overlay {
    background-color: #bada55;
  } */

.closeBtnWithBackground {
  position: absolute;
  right: -12px;
  top: -12px;
  border: 1px solid;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}

.inputBoxAddCust {
  /* font-family: 'Roboto', sans-serif;
    color: #E2E2E2;
    font-size: 1.2rem;
      margin: 0 auto;
    padding: 1.5rem 2rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 90%;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s; */
  padding-left: 10px;
  color: #25252d;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  height: 30px;
}
.inputBoxAddCust:focus {
  outline: none;
}
.inputBoxAddCust::placeholder {
  color: #e2e2e2;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
}
.textAddCust {
  font-family: "Nunito-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.82px;
}



.minBox {
    /* font-family: 'Roboto', sans-serif;
      color: #E2E2E2;
      font-size: 1.2rem;
        margin: 0 auto;
      padding: 1.5rem 2rem;
      border-radius: 0.2rem;
      background-color: rgb(255, 255, 255);
      border: none;
      width: 90%;
      display: block;
      border-bottom: 0.3rem solid transparent;
      transition: all 0.3s; */
    padding-left: 10px;
    color: #25252d;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    height: 30px;
  }
  .minBox:focus {
    outline: none;
  }
  .minBox::placeholder {
    color: #e2e2e2;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
  }