.ManageDriverRadioButton{
    position: relative;
    margin-left:100px;
    margin-top:20px
}
.manage-driver-radio-1{

}
.manage-driver-radio-2{
margin-left: 25px;
}
.Radio-Button-Text-Style{
    margin-top:-5px;
 margin-left: 10px;
 font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;

color: #6C6C6C;
}
input[type="radio"] {
background-color:#2E7C7B;
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
}
.input-body-3{
    /* margin: auto; */
    /* width: 400px; */
}

.invalid-feedback {
    margin-left: 98px;
}

.form-control {
    width: -webkit-fill-available;}

.date-logo{
    margin-top: 5px;
    left: 436px;

}

.greyFontImp{
    color: #6C6C6C !important;
}