.kycText1{
    text-align: center;
    color:#6C6C6C;
}


.lowerBox{
    background-color: #F9F9F9;
    text-align: left;
    width: 80%;
    border-radius: 4px;
    border: 1px solid #EFEFEF;
    margin: auto auto auto 50px;
}

.copyButton{
    width:100%;
    height: 3rem;
    margin: 5px auto;
    border: 1px solid #2E7C7B;
    color:#2E7C7B;
    background-color: #F9F9F9;
    font-size: 18px;
}
