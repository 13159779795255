.vendor-list-Bg{
    
}
.vendor-List-title{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-transform: capitalize;
    color: #25252D;
    padding-top: 24px;
    margin-left: 18px;
}
.vendor-search-bar-section{
    margin: 0px 16px 0px 4px;
    align-items: center;
}
.table-for-Vendor {
    position: sticky;
    margin: 16px 16px 16px 16px;
}
.table-header-vendor-details{
    border-radius: 12px 12px 0 0 ;
    border: 1px solid #96A9CA;
    background-color: #96A9CA;
}
.table-row-vendor-details {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #25252D;
    height: 70px;
    text-align: left;
    background-color: white;
    border-left-width: 1px;
    border-right-width: 1px;
}
.tbodyVendor{
    z-index: -1;
    overflow-y:scroll;
    margin: 0 -10px 0 0px;
}