.sub-title-recharge-fastag-Id {
    height: 25px;
    margin-top: 32px;
    margin-left: 26px;

    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    text-transform: capitalize;

    color: #25252D;

}

.current-fastag-balance-for-recharge-wallet-id {
    position: relative;
    left: 96px;
    top: 7px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    color: #38AC36;
}

.fastag-balance-by-fastag-ID {
    margin-top: 10px;
    margin-left: 96px;
    width: 341px;
    height: 19px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #898989;
}
button:disabled{
    background-color:gray;
    }