.ManageCustomerDetailsCardType2Box{
    box-sizing: border-box;

height: 80px;


border: 1px solid #E2E2E2;
border-radius: 8px;
}
.ManageCustomerDetailsCardType2Upper{
    display: flex;
    flex-direction: row;

}
.ManageCustomerDetailsCardType2Bar{
    position: relative;
width: 4px;
height: 22px;
margin-top:15px;
margin-left:0;

background: #25252D;
}
.ManageCustomerDetailsCardType2Text{
    position: relative;
width: 84px;
height: 22px;
margin-top:-23px;

margin-left:12px;
font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 21.8px;

color: #252d26;
}
.ManageCustomerDetailsCardType2Bottom{
    position: relative;
    margin-top: 10px;
    margin-left:12px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #282872;
}