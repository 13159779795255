.quoteButtonDark{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color:#ffffff;
    background-color: #485B7C;
    border: none;
    padding: 0.5rem;
    width: 100%;
    height: 100%;
  }
  
  
  .quoteButtonDark1{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color:#ffffff;
    background-color: #485B7C;
    border: none;
    padding: 0.5rem;
    width: 100%;
    height: 100%;
  }
  
  
  .quoteButtonLight{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color:#485B7C;
    background-color: #ffffff;
    border: 0.1rem solid #485B7C;
    padding: 0.5rem;
    width: 100%;
  }
  

.buttonLightSmall{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color:#485B7C;
    background-color: #ffffff;
    border: 0.1rem solid #485B7C;
    padding: 0.5rem;
    width: 180px;
  }

.quoteButtonLight{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color:#485B7C;
    background-color: #ffffff;
    border: 0.1rem solid #485B7C;
    padding: 0.5rem;
    width: 100%;
  }
.startBtn{
    border: none;
}


.TopBanner{
    background-color: #FBD56A;
    padding: 1rem;
}

.loadingText{
    text-align: center;
    padding-top: 1rem;
    cursor: pointer;
  }

.ItemNum{
  position: absolute;
  left:6rem;
  height: 2rem;
  width: 2rem;
  border-radius:50%;
  color:#ffffff;
  background-color: #E57B4D;
  text-align: center;
  padding-top: 4px;
}
.boxContainer{
  text-align-last: center;
  display: flex;
}