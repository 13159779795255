.impInfo{
    /* display:flex;
    flex-direction: row; */
    background-color: #FFF3EA;
    border-radius: 0.5rem;
}

.impInfoDiv{
    margin: 1rem 0 0 6.5rem;
    width: 21.25rem;
}

.linkedAccounts{
    display:flex;
    flex-direction: row;
}

.circleForList{
    padding-top: 2px;
    padding-right: 1px;
    /* margin: 32px 0 0 35px; */
    height: 28px;
    width: 28px;
    background: #FCF2ED;
    border-radius: 50%;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #25252D;
}

.linkedAccountsDiv{
    margin: 4px 0 0 5.5rem;
}


.btnSelect{
    padding: 0.5rem 0.7rem;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    border-radius: 0.3rem;
    color: #ffffff;
    background-color:#485B7C ;
    border: 1px solid #2e7c7b;
    box-sizing: border-box;
    text-align: center
}


.btnSelect:hover{
    color: #ffffff;
    background-color:#ED752E ;
    border: 1px solid #485B7C;
}


.btnSelect:active{
    color: #ffffff;
    background-color:#485B7C ;
    border: 1px solid #485B7C;
}

.btnNotSelect{
    padding: 0.5rem 0.7rem;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    border: 1px solid #96A9CA;
    border-radius: 0.3rem;
    color:#485B7C;
    background-color: #ffffff;
    box-sizing: border-box;
    text-align: center
}


.btnNotSelect:hover{
    border: 1px solid #485B7C;
    
    color:#485B7C;
    background-color: #ffffff;
}


.btnNotSelect:active{
    border: 1px solid #485B7C;
    
    color:#485B7C;
    background-color: #ffffff;
}