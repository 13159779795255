.expenseCard {
  border-radius: 10px;
  border: 0.5px solid rgba(108, 108, 108, 0.2);
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 15px 8px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 180px; */
}
.expenseCard2 {
  border-radius: 10px;
  border: 0.5px solid rgba(108, 108, 108, 0.2);
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 10px 20px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: "20px";
  /* width: 180px; */
}
