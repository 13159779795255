.vehicleHeadCard{
    height: 75px;
    background-color: rgba(229, 114, 49, 0.1);
    border: 3px solid rgba(229, 114, 49, 0.1);
}
.ButtonHead{
    border: 0.5px solid #6C6C6C33;
    background-color: #E57231    ;
    padding: 5px;
    box-shadow: 0px 4px 8px 0px #0000000F;
    border-radius: 3px;
}
@media (max-width: 1400px) and (min-width: 1200px) {
    .paddingBtm{
        padding-bottom: 0.5rem;
    }
}