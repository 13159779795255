.topBox{
    position: relative;
    width: 330px;
    height: 222px;
padding: 10px;
background:  #FFFFFF;
border-radius: 4px;
}
.topCardicon{
    position: relative;
margin-left:15px;

margin-top: 10px;


}
.topCardText{
    
position: relative;

margin-left : 20px;
margin-top:10px;
font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;
/* identical to box height */


color: #25252D;
}
.TopCardfixedText{
   
    position: relative;
    width: 266px;
    height: 57px;
margin-left: 16px;
margin-top: 10px;
margin-right: 8px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #A7A7A7;

}
.TopCardvalue{
    position: relative;
    margin-left:17px;
    margin-top:20px;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 33px;
/* identical to box height */


color: #25252D;
}
.TopCard-Collections{
    position: relative;
left: 15px;
right: 152px;
margin-top: 22px;
bottom: 20px;
cursor: pointer;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
text-align: right;

color: #2E7C7B;

}
.topcard-arrow-icon{
    position: relative;
    margin-top:0px;
    margin-left:20px;
}



.redeemButton{
    align-items: center;
    box-sizing: border-box;
    gap: 4px;
    text-align: center;
    height: 42px;
    width: 100px;
    background: #FFFFFF;
    border: 1px solid #2E7C7B;
    border-radius: 4px;
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #2E7C7B;
}