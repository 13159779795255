
.mainn{
    position: relative;
width: 411px;
height: 456px;
left: 0px;
background: #FFFFFF;
border-radius: 4px;
margin-right: 28px;
border: none;

}

.title{
    position: absolute;
width: 14.5rem;
height: 33px;
left: 16px;
    top: 24px;

font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;
/* identical to box height */

color: #25252D;

}

.amount{
    position: absolute;
    width: 20rem;
    height: 33px;
    left: 64px;
    top: 89px;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    color: #25252D;
}

.options-title{
    position: absolute;
left: 16px;
top: 294px;


font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;

color: #25252D;

}
.options{
    display: flex;
    flex-direction: row;
}

.title-logo{
    position: absolute;
    top : 85.76px;
    left: 16.76px;
}


