.DiscountCol {
  width: 948px;
  height: 45px;
}

.table-header-trip-without-color {
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* letter-spacing: 1px; */
    line-height: 21.82px;
    height: 45px;
  }


.minBox {
    /* font-family: 'Roboto', sans-serif;
      color: #E2E2E2;
      font-size: 1.2rem;
        margin: 0 auto;
      padding: 1.5rem 2rem;
      border-radius: 0.2rem;
      background-color: rgb(255, 255, 255);
      border: none;
      width: 90%;
      display: block;
      border-bottom: 0.3rem solid transparent;
      transition: all 0.3s; */
    padding-left: 10px;
    color: #25252d;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    height: 30px;
    width: 48px;
  }
  .minBox:focus {
    outline: none;
  }
  .minBox::placeholder {
    color: #e2e2e2;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }


.minBox2 {
    /* font-family: 'Roboto', sans-serif;
      color: #E2E2E2;
      font-size: 1.2rem;
        margin: 0 auto;
      padding: 1.5rem 2rem;
      border-radius: 0.2rem;
      background-color: rgb(255, 255, 255);
      border: none;
      width: 90%;
      display: block;
      border-bottom: 0.3rem solid transparent;
      transition: all 0.3s; */
    padding-left: 10px;
    color: #25252d;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    height: 30px;
    width: 75px;
  }
  .minBox2:focus {
    outline: none;
  }
  .minBox2::placeholder {
    color: #e2e2e2;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }

  .table-Div{
    border-left: 1px solid #6C6C6C33;
    border-right: 1px solid #6C6C6C33;
    border-bottom: 1px solid #6C6C6C33;

  }