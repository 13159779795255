
.buttonV2{
    border:none;
    height: 70px;
    margin: 0;
    padding: 0 0 0 8px;
}

.main_div{

        
}
.Clicked{
    background-color: #ED752E;
    border-radius: 12px;
}
.notClicked{
    /* background-color:#fff; */
    border-radius: 12px;
}
.nonClickedV2{
    /* display: flex; */
    /* width:90px; */
    height:54px;
    text-align:center;
    border: none;
    align-items:center;
    /* justify-content:center; */
    /* background-color: #FFFFFF; */
    margin: 0 0 0 0;
}
.nonClickedV2:hover{
    /* background-color: #FFFFFF; */
}
.nonClickedV2:focus{
    /* background-color: #FFFFFF; */
}
.buttonShape{
    padding-top: 0px;
    color:'blue'
}
.textButton{
    position:absolute;                   
    text-align: center;
}
.buttonTextNonClickedV3{
    /* margin-top: 5px; */
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color:#E2E2E2 ;
    text-align: start;
    margin: 0 0 0 20px;
}
.buttonTextClickedV3{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color: #25252D;
    text-align: start;
    margin: 0 0 0 20px;
}

.text-Click{
    color:white;
}

.text-not-clicked{

    color:black;
}
.nonClicked4{
    width:30px;
    height:34px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    padding: 3px;
    margin: 0 0 0 8px;
}
.nonClickedHover{
    width:176px;
    height:54px;
    text-align:center;
    border: none;
    align-items:center;
    /* background-color: #FFFFFF; */
    padding-left: 24;
    padding-right: 24;
    padding-top: 12;
    padding-bottom: 12;
    margin: 0 0 0 0;
    cursor: pointer;
}

.nonClickedHover:hover{
    /* background-color: #FFFFFF; */
    cursor: pointer;
}
.nonClickedHover:focus{
    /* background-color: #FFFFFF; */
    cursor: pointer;
}
.firstHalf{
    justify-content: center;
    margin: 0;
}
.activeHover{
    width:176px;
    height:54px;
    text-align:center;
    border: none;
    align-items:center;
    background-color: #485B7C;
    border-radius: 15px;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    cursor: pointer;
}
.pointingV2{
    margin: 0 0 0 -12px;
    cursor: pointer;
}
.disableHover{
    width:176px;
    height:54px;
    text-align:center;
    border: none;
    align-items:center;
    /* background-color: #FFFFFF; */
    border-radius: 15px;
    margin: 0 0 0 0;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
}