.RemoveDiv1{
    width: 25rem;
    height: 10rem;
    position: relative;
    top: 15rem;
    left: 0;
    background-color: #FFFFFF;
    border: none;
    border-radius: 10px;
}

.RemoveDiv1 .modal-content{
    width:25rem !important;
    border-radius: 10px;
}

.RemoveDiv1 .PGCollectionCloseIcon{
    padding-top: 45px;
    margin-top: 0;
}

.optionBox{
    border-radius: 0.6rem;
    border: 1px solid #E2E2E2;
}
.input-body-new{
    padding:1rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1rem;
    width: 100%;
    text-align: left;
    border: 1px solid #E2E2E2;
    border-radius: 0.5rem;
}

.blockDiv{
    display: block;
}
.closeRemove{
    cursor: pointer;
    position: absolute;
    top:10px;
    right: 10px;
}


.dobEmi .input-body-3{
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    text-align: left;
    border: 1px solid #E2E2E2;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    margin-left: 0.5rem;
}

.outlineDot{
    border: 1px dotted #E2E2E2;
    border-radius: 0.4rem;
}


.RemoveDiv2{
    width: 30rem;
    height: 43rem;
    position: relative;
    top: 4rem;
    left: 0;
    background-color: #FFFFFF;
    border: none;
    border-radius: 10px;
}

.RemoveDiv2 .modal-content{
    width:30rem !important;
    border-radius: 10px;
}

.RemoveDiv2 .PGCollectionCloseIcon{
    padding-top: 45px;
    margin-top: 0;
}

.uploadContain{
    border: 1px dotted #C4C4C4;
    
}