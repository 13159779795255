.searchInput{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: black;
    width: 95%;
   
    border: none;
  }
  
  .searchInput:hover{
    outline: none;
  }
  .searchInput:focus{
  outline: none;
  }


.searchBox{
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    box-sizing: border-box;
    width: 60%;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    display: flex;
  }
.loginHeaderContainer{
    background-color: #EAF2F2;
    position: relative;
    margin: 0;
  }

.loginImage{
    position: absolute;
    bottom: 0;
    right: 0;
  }


.headerSubtext{
    color: #25252D;
    font-size: 0.8rem;
    line-height: 15px;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }


.loginHeader{
    color: #2E7C7B;
    font-size: 1.5rem;
    line-height: 29px;
    font-weight: 700;
  }