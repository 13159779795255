.fuelCardContainer{
    border-radius: 0.5rem;
    border: 0.1px solid rgba(108, 108, 108, 0.2);
    box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important;
    padding: 0.5rem;
}

.leftMarginLine{
    border-left: 1px solid rgba(108, 108, 108, 0.2);
}

.greyBox{
    border-radius: 0.5rem;
    border: 1px solid #E2E2E2;
}

.greenBackBox{
    background-color: rgba(46, 124, 123, 0.1);
    border-radius: 0.4rem;
    padding: 0.5rem;
    text-align: center;
    align-items: center;
}

.centralItems{
    text-align: center;
    align-items: center;
}

.greenCirleInitial{
    background-color:#96A9CA;
    padding: 0.3rem;
    border-radius: 50%;
    color:#485B7C;
    text-align: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    display: grid;
}