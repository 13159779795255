.manage-logipe-card-midSection{
     
      /* width: 808px; */
height: 302px;
background: #FFFFFF;
border-radius: 4px;
}
.manage-logipe-card-midSection-1{
padding-top:3%;
padding-left:4%;
position: relative;
}
.manage-logipe-card-midsextion-2{
padding-left:12%;
padding-top:1.8%;
}
.manage-logipe-card-midSection{

  
}