.Vendor-Details-Heading{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #25252D;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin:  0 0 0 0;
}
.Vendor-Details-SubHeading{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #25252D;
    margin: 8px 0 0 0;
}
.Vendor-Details-Section{
    margin: 24px;
}
.Vendor-Details-Section-3{
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(46, 124, 123, 0.05);
    border-radius: 15px;
}
.Edit-Vendor-Details{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #485B7C;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content:center;
}
.transfer-box-3{
    border-radius: 15px;
    padding: 12px 12px 12px 12px;
    margin: 0px 0px 0px 0px;
}
.Transfer-To-Logipe-Vendor-Bg-2{
    height: 48px;
    background: #96A9CA;
    border: 0.5px solid rgba(108, 108, 108, 0.2);
    border-radius: 8px;
    text-align: center;
}
.Pay-Vendor-Details{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content:center;
}
.Vendor-Details-Section-4{
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(46, 124, 123, 0.05);
    border-radius: 15px;
    margin:  0px;
}

.vendor-List-title1{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    text-transform: capitalize;
    color: #25252D;
    padding-top: 24px;
    margin-left: 18px;
    background: #E57231;
}



.ButtonVendorTransaction{
    width: 23%;
    border: 0.5px solid #6C6C6C33;
    border-radius: 10px 10px 0px 0px;
    background-color: #E57231;
    padding: 5px;
    box-shadow: 0px 4px 8px 0px #0000000F;
    height: 40px;
    /* margin:5px; */
    color: #FFFFFF;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
}

.ButtonVendorTransactionInactive{
    width: 23%;
    border: 0.5px solid #6C6C6C33;
    border-radius: 10px 10px 0px 0px;
    background-color: #FFFFFF    ;
    padding: 5px;
    box-shadow: 0px 4px 8px 0px #0000000F;
    height: 40px;
    color: #E57231;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    /* margin:4px; */
}
.ButtonVendorTransactionInactive:hover {
    width: 23%;
    border: 0.5px solid #6C6C6C33;
    border-radius: 10px 10px 0px 0px;
    background-color: #FFFFFF;
    cursor: default;
    padding: 5px;
    box-shadow: 0px 4px 8px 0px #0000000F;
    height: 40px;
    color: #E57231;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    /* margin:4px; */
  }