.linkStyle {
  position: absolute;
  width: 200px;
  height: 16px;
  left: 20px;
  top: 186px;

  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
