
.ManageExpenseManageCardBoxClicked {

    position: relative;
    background: #f0f2fd;
}

.ManageExpenseBox-1 {
    position: relative;
    width: 210px;
    height: 51px;
    /* left: -25px; */
    padding-top: 11px;
    
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    cursor: pointer;
    border: none;
    color: black;
    outline: none;
    background: #fff;
}

.ManageLogepeCardBox-1 {
    left: -25px;
    margin-left: 5px;
    padding-top: 11px;
    position: relative;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    cursor: pointer;
    border: none;
    color: white;
    background: #fff;
    outline: none;
}

.ManageExpenseBox-1:hover {
    background: #fff;
    color: white;
}

.ManageLogepeCardBox-1:hover {
    background: #fff;
    color: white;
}

.ManageExpenseBox-1Clicked {
    position: relative;
    width: 210px;
    height: 51px;
    left: -25px;
    padding-top: 11px;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    cursor: pointer;
    border: none;
    color: #6378e3;
    background-color: #f0f2fd;
    outline: none;
}

.ManageExpenseBox-1Clicked:focus {
    position: relative;
    width: 210px;
    height: 51px;
    padding-top: 11px;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #6378e3;
    background-color: #f0f2fd;
    cursor: pointer;
    border: none;
    outline: none;
}

.ManageLogepeCardBox-1Clicked {
    height: 51px;
    left: -25px;
    padding-top: 11px;
    width: 210px;
    position: relative;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #6378e3;
    background-color: #f0f2fd;
    cursor: pointer;
    border: none;
    outline: none;
}

.ManageLogepeCardBox-1Clicked:focus {
    height: 51px;
    padding-top: 11px;
    width: 210px;
    position: relative;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #6378e3;
    background-color: #f0f2fd;
    cursor: pointer;
    border: none;
    outline: none;
}

/* .ExpenseEndBar {
    position: relative;
    width: 808px;
    height: 0.1px;
    top: 0px;
    left: 0px;
    background: #d2d2d7;
} */

.ExpenseEndBarClicked {
    position: absolute;
    width: 210px;
    height: 4px;
    top: 0px;
    background: #6378e3;
}

.DriverDashboardManageExpenseDetails {
    position: relative;
    /* margin-left: -25px; */
    /* margin-top: 270px; */
}

.DriverDashboardManageCardText {
    
    display: flex;
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #6c6c6c;
    /* padding-bottom: 2rem; */
}

.DriverDashboardManageCardButton {
    position: relative;
  
}



.DriverDashboardLogipeCard {
    position: relative;
    margin-top: 30px;
    /* margin-left: -12px; */
}



.cardStatus {
    font-size: 2rem;
    align-content: center;
}