.DriverDashboardManageExpenseBox{
width: 808px;
/* height: 830px; */


background: #FFFFFF;
border-radius: 4px 4px 0px 0px;
}
.DriverDashboardCardType3{
    position: relative;
    margin-left: 16px;
    /* margin-top: 30px; */
    margin-right: 20px;
}

.DriverDashboardManageExpenseHeader{
    position: relative;
    /* margin-top:40px; */
    padding-top: 48px;
}
.DriverDashboardLogipeCard{
    margin-left:0px;
    margin-top:20px;
    position: relative;
}
