
.FullCustomerListTopBox{
    display: flex;
    flex-direction: row; 
    align-items: center;
}
.FullCustomerListAddButton{
    position: relative;
   padding: 12px;
}
.FullCustomerListHeader{
    position: relative;
    
text-transform: capitalize;
padding: 12px;
color: #25252D;
}

.FullCustomerListBar{
    position: relative;
    margin-top:20px;
    margin-left :20px;
}
.FullCustomerListCard{
    position: relative;
    margin-top:10px;
    margin-left:20px
}


@media (max-width: 1400px) and (min-width:1200px){
    .FullCustomerListTopBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 1rem;
        padding-left: 0.5rem;
    }

    .FullCustomerListAddButton{
        position: relative;
        padding: 6px;
    }

    .FullCustomerListHeader {
        position: relative;
        text-transform: capitalize;
        padding: 0px;
        color: #25252D;
    }
}




