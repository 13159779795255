.AddNewCustomerBox2{
/* width: 180px; */
height: 42px;
cursor: pointer;
background: #F2F2F2;
border-radius: 40px;
}

@media (max-width: 1400px) and (min-width:1200px){
    .AddNewCustomerBox2 {
        padding: 10px 10px 10px 6px;
        position: relative;
        /* width: 178px; */
    }}
