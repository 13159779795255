.copyRightText {
  position: absolute;
  padding: 1.5rem 0rem;
  bottom: 0px;
  color: #ffffff;
  text-align: center;
  margin: auto;
  display: flex;
  width: 100%;
}

.infoText1 {
  color: #485B7C;
  font-size: 2rem;
  font-weight: 600;
  line-height: 35.2px;
}

.carousel-texts{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.infoText2 {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 27.28px;
  display: flex;
  cursor: pointer;
}

#carouselServicesDisplay {
  padding-top: 1rem;
  /* margin-top: 1rem; */
}

.carousel-indicators {
  position: relative;
  top: 1rem;
  left: 0;
  margin-left: 0;
  justify-content: left;
  padding-left: 1rem;
  padding-top: 1rem;
}

.carousel-indicators [data-bs-target] {
  border-top: none;
  border-bottom: none;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  /* opacity: 0.3; */
}

.carousel-indicators .active {
  border-top: none;
  border-bottom: none;
  width: 18.2px;
  height: 4px;
  border-radius: 5px;
}
.paddingText2Top {
  padding-top: 1.8rem;
}

.infoLogo {
  /* position: absolute;
  bottom: 0;
  height: 100%; */
}


.computerIconStyle{
    margin: auto;
    position:absolute;
    right: -0.75rem;
    padding-top: 3.5rem;
}

.carouselImages{
    margin: auto;
}

.fadeOuterDiv{
    /* display: inline-flex; */
    padding-left: 2rem;
    padding-right: 0px;
}


#fadeDesign{
    text-align: right;
    position: relative;
    width: 11rem;
    margin: auto;
    right: 0 !important;
    margin-top: -5rem;
    margin-right: -1rem;
}

.CarouselTopDiv{
  padding-top:10rem;
}

@media (max-width: 1400px) and (min-width: 1200px) {

  #carouselServicesDisplay {
    padding-top: 1rem;
  }
  .carousel-indicators {
    top: 1rem;
  }

  .infoText1 {
    font-size: 1.6rem;
  }

  .infoText2 {
    font-size: 1.2rem;
  }
}


.paddingTextCarousel{
    padding: 2rem 0 0 1rem;
}


.paddingCarouselImage{
    padding: 1rem 0 1rem 1rem;
}


.shadowBottom{
    position: absolute;
    bottom: 0px;
    height: 10rem;
    background-image: linear-gradient(to bottom, #0D234400, rgba(13, 35, 68, 0.111752));
    width: 100%;
    padding: 0;
}

.carouselImageHeight{
    height: 35vh;
}

.marginTop3rem{
    margin-top: 3rem;
}


@media only screen and (max-width: 1026px) {

  .CarouselTopDiv{
    padding-top:1rem;
  }

  .carousel-indicators {
    position: relative;
    top: 1rem;
    left: 0;
    margin-left: auto;
    justify-content: center;
    /* padding-left: 1rem; */
    padding-top: 1rem;
  }
    #fadeDesign {
        display: none;
    }
    
    .marginTop3rem{
        margin-top: 0;
    } 

    .marginTop1rem{
        margin-top: 0;
    }

    .carousel-texts{
        padding: 0px;
    }

    .paddingTexts{
        padding:4rem 0 1rem 2rem;
    }

    .carousel-item-next, .carousel-item-prev, .carousel-item.active{
        display: flex;
    }

    .carouselImageHeight{
        height: 22vh;
    }
    
    .paddingTextCarousel{
        /* position: absolute;
        top:30%;
        transform: translate(0, 30%); */
  /* left: 50%;
  transform: translate(-50%, -50%); */
        /* height: 17rem; */
        /* margin: auto; */
        /* padding: 1rem 0.5rem 1rem 2rem; */
        /* align-items: center;
        justify-content: center;
        width: 50%; */
    }

    .paddingCarouselImage{
        position:relative;
        margin-top: -1rem;
        /* position: absolute;
        right: 0;
        transform: translate(0, 10%); */
        /* top:30%; */
    }

    .shadowBottom{
        display: none;
    }
}