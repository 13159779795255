.LogipeCard2Box{
    position: absolute;
    width: 415px;
    height: 246px;
    background-image: linear-gradient(145deg, #4C4059 0% 72%,#52465e 72% 79%,#584e65 79% 100%);

    margin-left: 1rem;
    background: #4C4059;
    border-radius: 12px;
}
.LogipeCard2Name{
height: 22px;
width :250px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    position:absolute;
    left:20px;
    top:35px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    
    color: rgba(255, 255, 255, 0.6);
}
.LogipeCard2Active{
    text-align: center;
    margin: auto;
    height: 19px;
    margin-left:220px;
    margin-top:35px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    
    color: #FFFFFF;
}
.LogipeCard2Slider{
    position: absolute;
width: 67px;
height: 36px;
left: 320px;
top: 28px;

/* s */
border-radius: 60px;
}
.LogipeCard2Header{
    position: absolute;
width: 114px;
height: 22px;
left: 20px;
top: 76px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.04em;
text-transform: uppercase;

color: #B7B3BD;
}
.LogipeCard2CardNumber{
    position: absolute;
height: 25px;
left: 20px;
top: 145px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 25px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.1em;

color: #FFFFFF;
}
.LogipeCard2ExpiryDate{
    position: absolute;
width: 63px;
height: 16px;
left: 20px;
top: 186px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;

color: rgba(255, 255, 255, 0.6);
}
.LogipeCard2CVV{
    position: absolute;
width: 25px;
height: 16px;
left: 125px;
top: 186px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;

color: rgba(255, 255, 255, 0.6);
}
.LogipeCard2Date{
    position: absolute;
width: 50px;
height: 22px;
left: 20px;
top: 206px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.1em;

color: #FFFFFF;
}
.LogipeCard2CVVN{

    position: absolute;
    width: 32px;
    height: 22px;
    left: 125px;
    top: 206px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    
    color: #FFFFFF;
}
.ownerLogipeCard{
    width:20px;
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .LogipeCard2Box{
        width: 368px;
    }
}