.PaymentsText {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 43.65px;
  color: #25252d;
  text-align: center;
}

.squareDiv {
  height: 200px;
  width: 200px;
  background-color: #ededf1;
  border: 1px solid #6c6c6c9c;
  border-radius: 10px;
}
.addPayText {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27.28px;
  color: #25252d;
  text-align: center;
  padding-top: 10px;
}
