.ManageLogipeCardControlsBox{
    /* width: 454px;
    margin-left:0px; */

/* margin-top: 90px; */
height: 934px;
background: #FFFFFF;
border-radius: 4px 0px 0px 0px;
}
.card-controls-ManageLogipe{
    position: relative;
    margin-left: 0px;
    top:37px; width: 454px;
}
.Manage-logipe-card-controls-header{
    position: relative;
    margin-left:100px;
    width: 454px;
}
.card-control-manage-logipe-card-toggle{
    position: relative;
   top:-20px; width: 454px;
}
.card-control-Date{
    width:300px;
    height:19px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
flex-direction: row;



color: #A7A7A7;
}
.cardControlLogipe{
    margin-top: -15px;
    padding-left: 70px;
    width: 92px;
    height: 27px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    
    color: #2E7C7B;
}
.cardControlEdit{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
padding-left: 0px;
display: flex;
align-items: center;
text-transform: uppercase;

color: #2E7C7B;
}
.fontCardControl{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;

color: #25252D;
}

.btnActive{
    cursor: pointer;
}

.btnInactive{
    cursor: default !important;
}
.amountFontCardControl{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;

color: #25252D;
}
.dateCardControld{
    width: auto;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */

align-items: center;

color: #A7A7A7;
}
.activeCardControld{
    width: auto;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */

align-items: center;

color: #2E7C7B;

}
.changePinCardControl{
/* padding-left: 5px;
width: auto; */
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
cursor: pointer;
/* identical to box height */

align-items: center;
/* text-align: right; */
text-decoration-line: underline;

color: #ED752E;
}
.changeATMpin{
    position: relative;
    left:-40px;
}
.headerTextCardControls{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    
    color: #25252D;
    
}