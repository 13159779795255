.PGCollectionBox {
    position: relative;
    /* width: 950px;
    height: 1024px;
    left: -200px;
    top: -38px; */
    background: #fff;
}

.PGCollectionclose {
    display: inline;
    margin-left: 800px;
    position: relative;
    background: #fff;
    right: 10px;
    margin-top: -10px;
    border: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    top: 30px;
}

.PGCollectionCloseIcon {
    position: relative;
    width: 1%;
    height: 1%;
    margin-top: 25px;

}

.PGCollectionCloseText {
    position: relative;
    left: 47px;

    margin-top: 25px;
    bottom: 94.34%;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #25252D;
}

.PGCollectionHeader {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
}

.PGCollectiontText {
    /* margin: 4px 0 0 5rem; */
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-transform: capitalize;
display: flex;
align-items: flex-end;
    color: #25252D;
    padding:0;
}

.PGCollectionIcon {
    /* position: relative;
    margin-left: 70px;
    margin-top: 80px;
    bottom: 12.5%; */
    position: relative;
    left: 4rem;
}

.PGCollectionSearchBar {

    position: relative;
    margin-left: 70px;
    margin-top: 25px;
}

.PGCollectionButton {
    margin-left: 290px;
    margin-top: 75px;
    position: relative;
}

.PGCollectionHeaderBar {
    position: relative;
    margin-left: 80px;
    margin-top: 20px;
}

.PGCollectionCard {
    margin-left: 80px;
    margin-top: 20px;
    position: relative;
}

.PGCollection-modal {
    position: relative;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translate(-100%,0)!important;
    margin: 0!important;
}

.closeBtnPG {
    display: none;
}


.cashbackListing{
    max-height: 460px;
    position: relative;
    top:0;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    direction: row;
   
}

/* 
.modal-content{
    height: 10px !important;
} */

.transactionButton{
    background: white;
    color: black;
    border-width: 0px;
    font-weight: 500;
    text-decoration: underline;
    display: flex;
    margin: auto;
}

.transactionButton:hover{
    background: white;
    color: black;
    border-width: 0px;
    font-weight: 500;
    text-decoration: underline;
    display: flex;
    margin: auto;
}

.transactionButton:focus{
    background: white;
    color: black;
}

.transactionButton:active{
    background: white;
    color: black;
}

.btn-secondary:disabled {
    color: black;
    background-color: white;
    border-color:transparent;
}

.fastagTableCss tr td{
    padding: 1rem 0 1rem 5rem!important;
    text-align: left !important;
}

.fastagTableCss tr th{
    padding: 1rem 2rem 1rem 4.5rem!important;
    text-align: left !important;
}


.fastagTableCss thead, .fastagTableCss tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }


.fastagTableCss tbody{
  
    overflow-y: auto;
    height: calc(100% - 50px) !important;
 }

 .fastagTableCss{
    height: 100%;
 }
