
      
      .modal-content {
        height: 100%;
       width: 950px;
        border-radius: 0;
      }
    
    .positon{
        position: fixed;
        right: 0px;
        /* top: -29px; */
        height: 100%;
    }

    .Add-balance-title{
        margin: 18px 25px 15px 20px;
        /* margin-left: 35px; */
        font-family: 'Nunito-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        /* identical to box height */

        text-transform: capitalize;

        color: #0e0e0f;
    }
    .modal-head-part{
        display: flex;
        flex-direction: row;
        border-bottom-style: groove;
        padding: 15px 0 0 15px;
    }
    .close-btn{
        position: absolute;
    top: 15px;
    right: 10px;
        background-color: #FFFFFF;
        border: none;
    }
    .close-btn:hover{
        background-color: #FFFFFF;
    }
    .close-btn:focus{
        background-color: #FFFFFF;
        box-sizing: none;
    }
    .body-part{
        margin: -17px 0 0 -16px;
    width: 518px;
    height: 95px;
    
    background: #FFF3EA;
    }
    
    .head-part{
        font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
    }

    
    .body-sub-part1{
        padding: 20px 0 0 96px;
        margin-left: 10px;
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */
        
        text-transform: capitalize;
        
        color: #6C6C6C;
        
    }
    .body-sub-part2{
        margin-top: -9px;
    padding-left: 106px;
    width: 92px;
    height: 27px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    
    color: #25252D;
    }
    
    .body-sub-part3{
    padding: 0px 0 0 44px;
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    
    display: flex;
    align-items: center;
    
    color: #6378E3;
    }
    
    .body-sub-part4{
        padding-left: 43px;
        padding-right: 32px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    
    display: flex;
    align-items: center;
    
    color: #25252D;
    
    }
    
    .sub-head1{
    margin-top: 29px;
    margin-left: 26px;
    width: 140px;
    height: 25px;
    
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
    }
    
    .butt1{
    
    width: 181px;
    height: 42px;
    margin: 0px 0 0 96px;

    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;

    
    background: #2E7C7B;
    border-radius: 4px;
    color: #FFFFFF;
    }
    
    .butt2{
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        box-sizing: border-box;
    width: 181px;
    height: 42px;
    color: #6C6C6C;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    
    }
    
    .butt2-new{
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19.1px;
        text-align: center;
        text-transform: capitalize;
        box-sizing: border-box;
    width: 181px;
    height: 42px;
    color: #6C6C6C;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    position: relative;
    top: -0.7rem;
    }


    .set-btn-1{
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        width: 181px;
    height: 42px;
    margin: 0px 0 0 96px;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    color: #6C6C6C;
    }
    
    .set-btn-2{
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        box-sizing: border-box;
        width: 181px;
        height: 42px;
        color: #FFFFFF;
        background: #2E7C7B;
        border-radius: 4px;
        
    }
    
    .sub-head2{
        margin: 33px 0 0 26px;
    width: 20rem;
    height: 25px;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
    }
    
    .sub-head2-body{
    width: 367px;
    height: 38px;
    margin: 4px 0 0 5.5rem;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    
    color: #898989;
    }
    
    .radio-1{
        margin: 20px 0 0 96px;
        box-sizing: border-box;
    
    width: 22px;
    height: 22px;
    
    border: 1px solid #2E7C7B;
    background: #2E7C7B;
    }
    
    .radio-label-1{
    width: 200px;
    height: 22px;
    margin: -28px 0 0 126px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    
    color: #25252D;
    
    }
    
    .radio-label-2{
        width: 200px;
        height: 22px;
        margin: -5px 0 0 9px;
        
        font-family: 'Nunito-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */
        
        display: flex;
        align-items: center;
        
        color: #25252D;
        
        }
    
    .transfer-head{
        margin: 33px 0 0 26px;
        width: 390px;
        height: 25px;
        font-family: 'Nunito-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */
        
        text-transform: capitalize;
        
        color: #25252D;
    }
    .label-1{
        margin: -24px 0 0 0;
    }
    
    .butt3{
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    
    height: 52px;
    margin: 80px 0px 0 60px;
    top: calc(50% - 52px/2 + 426px);

    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    
    background: #2E7C7B;
    border-radius: 4px;
    color: #FFFFFF;
    }
    
    .card-wallet-body1{
        margin: 0px 0px 0 -16px;
    width: 265px;
    height: 95px;
    
    
    background: #FFF3EA;
    }
    .card-wallet-main-body{
        display: flex;
        flex-direction: row;
        margin-top: -17px;
    }
    
    .sub-head-3{
    height: 25px;
    margin-top: 32px;
    margin-left: 26px;
    
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
    }
    
    .sub-head-body{
        margin-top: -15px;
    margin-left: 90px;
    width: 341px;
    height: 19px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13.8px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #898989;
    
    }
    
    .input-amount{
    width: 362px;
    height: 0px;
    margin: 8px 0 0 88px;
    border: 1px solid #A7A7A7;
    }
    
    .sub-head-4{
        margin-top: 32px;
    margin-left: 26px;
    width: 214px;
    height: 25px;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
    }
    

    .sub-head-neww{
        margin-top: 32px;
    margin-left: 26px;
    
    height: 25px;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
    }


    .butt4{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;

        color: #FFFFFF;
        
        width: 400px;
        height: 52px;
        margin: 80px 0px 0 60px;
        top: calc(50% - 52px/2 + 426px);
        border:none;
        background: #485B7C;
        border-radius: 4px;
    }
    .space-div{
        margin-top: 100%;
    }

    .card-wallet-main-sub-body{
        margin: 22px 0 0 103.1px;
        width: 113px;
        height: 22px;
    }
    .card-wallet-sub-body1{

    
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #6C6C6C;
    }
    

    .card-wallet-sub-body-new{

    
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */
        
        text-transform: capitalize;
        
        color: #6C6C6C;
        }
    .card-wallet-sub-body2{
    margin-top: -11px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    
    color: #25252D;
    
    }
    

    
    .label-body{
        margin: 10px 0 0 96px;
    }

    .bottom-card{
        margin: 10px 0 0 96px;
        width: 362px;
        height: 188px;
        /* overflow-y: scroll; */
    }
    .bottom-card-title{

        font-family: 'Nunito-SemiBold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #25252D;
    }
    .card-border{
        border: 1px solid #E2E2E2;
        Width: 338px;
    }
    .card-text-1{
        /* margin: 20px 0 0 0px; */
        font-family: 'Nunito-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #A7A7A7;
    }
    .card-text-2{
        margin: -15px 0 18px 0;
        font-family: 'Nunito-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #25252D;

    }

    .butt3-proceed{
        background: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 400px;
        
        height: 52px;
        margin: 80px 0px 0 60px;
        top: calc(50% - 52px/2 + 426px);
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        border-radius: 4px;
        border-color: #2E7C7B;
        color: #2E7C7B;
    }

    .modal-bank-logo{
        position: absolute;
        position: absolute;
        left: 44.1px;
        top: 26.1px;
    }

    .sub-title-1{
        display: flex;
        flex-direction: row;
    }

    .circle-logo{
        padding-top: 2px;
        padding-right: 1px;
        margin: 32px 0 0 35px;
        height: 28px;
        width: 28px;
        background: #96A9CA;
        border-radius: 50%;
        font-family: 'Nunito-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        text-align: center;

        color: #485B7C;
    }

    .plus-circle-logo{
        margin: -6px 0 0 90px;
    }

    .card-copied-logo{
        position: relative;
        display: flex;
        flex-direction: row;
    }
    .copied-logo{
        position: absolute;
        left: 250px;
    }

    .card-copied-body{
        position: absolute;
        top: -24px;
    }
    .copy-logo{
        position: absolute;
        left: 265px;
    }

    .new_position{
        padding: 15px;
        cursor: pointer;
    }

    .settingModalMargin{
        margin-top: 0px;
        margin-bottom: 0px;
    }