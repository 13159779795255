.custText {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-size: 25px;
  font-weight: 700;
  color: #2e7c7b;
  line-height: 34.1px;
  color: #25252d;
  padding: 32px 0 25px 32px;
}

.custNamepadd {
  padding: 0px 0 0px 35px;
}

.custNamepadd2 {
  padding: 5px 0 0px 35px;
}

.inputBoxCust {
  /* font-family: 'Roboto', sans-serif;
  color: #E2E2E2;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s; */
  padding-left: 10px;
  color: #25252d;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
}
#CustSectionTable1 table {
  margin-bottom: -30px !important;
}
.inputBoxCust:focus {
  outline: none;
}
.inputBoxCust::placeholder {
  color: #e2e2e2;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
}
.cusTableDiv {
  padding: 25px;
}

.cusTableDiv table {
  margin-bottom: -42px !important;
}

.table-header-cust {
  font-family: "Nunito-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* letter-spacing: 1px; */
  line-height: 24.55px;
  background: #EDEDF1;
  height: 45px;
}
.CustSectionTable tbody {
  overflow-y: auto;
  /* height: calc(100% - 50px) !important; */
  overflow-x: hidden;
}

.CustSectionTable {
  height: 100%;
}

/* .CustSectionTable tr td {
  padding: 1.2rem 0 1.2rem 2rem !important;
  text-align: left !important;
} */

.CustSectionTable tr td {
  padding: 0.2rem 0.2rem 1.2rem 1rem !important;
  text-align: left !important;
}

.CustSectionTable tr th {
  /* padding: 0.5rem 0.3rem !important; */
  text-align: left !important;
}

.CustSectionTable thead {
  border-color: transparent !important;
}

.CustSectionTable thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.lowerBorderLineCust{
  border-bottom: 0.1px solid #EDEDF1;
  border-left: 1px solid #EDEDF1;
  border-right: 1px solid #EDEDF1;
}
.table-row-manage-cust-new {
  height: 40px;
  border-width: 0 !important;
  color: #25252d;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  /* border-bottom: 1px solid #E2E2E2; */
}

.AddCustStartBtn {
  height: 35px;
  width: 174px;
}

.AddCust {
  text-align: center;
  font-family: "Nunito-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #f8f9fa;
  line-height: 21.82px;
}

.addCustcenter {
  padding: 12px 0 0 50px;
}
