.lowerBorderLine{
    border-bottom: 0.1px solid rgba(108, 108, 108, 0.6);
}

.omcBanner{
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba(251, 213, 106, 0.8);
}

.boxShadowIntro{
    box-shadow: 0 4px 20px 0 #25252D1A;
}