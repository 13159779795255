.sub-title-edit-vehicle-detial{
        height: 25px;
        margin-top: 32px;
        margin-left: 26px;
        
        font-family: 'Nunito-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */
        
        text-transform: capitalize;
        
        color: #25252D;
        
}

.butt-Update-Vehicle-Details{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    width: 400px;
    height: 52px;
    position: absolute;
    left: 70px;

    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    
    border-radius: 4px;
    color: #B5B5B5;
    background: #EFEFEF;


}

.butt-Update-Vehicle-Details:hover{
    background: #EFEFEF;
    color: #B5B5B5;
}
.butt-Update-Vehicle-Details:focus{
    background: #EFEFEF;
    color: #B5B5B5;
    outline: none;
    
}