::placeholder
{
overflow : visible;


}
.input-body-1
{
    width:288px;
}
