.box{
    position: relative;
width: 411px;
height: 200px;
margin-right:28px;
background: rgb(255, 255, 255);
border-radius: 4px 4px 0px 0px;
}
.icon{
    position: relative;
margin-left: 12px;
top: 28px;
cursor: pointer;
color: #2E7C7B;

}
.cardtype1-text{
    
position: relative;
margin-left:10px;
margin-top : 30px;
font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;
cursor: pointer;
text-decoration: underline;
/* identical to box height */


color: #2E7C7B;
}
.fixedText{
position: relative;
width: 211px;
height: 38px;
margin-left: 12px;
margin-top: 10px;

font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;

color: #A7A7A7;


}
.value{
    position: relative;
    margin-left:12px;
    margin-top:15px;
    font-family: 'Nunito-SemiBold';
    font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 33px;
cursor: pointer;
/* identical to box height */


color: #25252D;
}
