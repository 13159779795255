.PGCollectionButtonBox{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
/* cursor: pointer; */
position: relative;
width: 202px;
height:42px;

background: #2E7C7B;
border-radius: 4px;
}
.PGCollectionButtonIcon{
    position: relative;
    left: -10px;
    top: 0px;
    
}
.PGCollectionButtonText{
position: relative;
height: 22px;
left: 1px;
top:0px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;
}