.main-customer-vehicle-detail{

background: #FFFFFF;
border-radius: 4px 4px 0px 0px;
}


.vl{
    border-left: 1px solid black;
    height: 8px;
}

.recharge-fastagId-button{
    font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 1rem;
line-height: 22px;
/* identical to box height */

text-align: center;
    position: relative;
 
width: 160px;
height: 42px;
border: none;
color : white;
background: #2E7C7B;
border-radius: 4px;
}



.vehicle-no{
   
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
   
    
    
    color: #25252D;
}

.fastagId-title{
    position: relative;
  
    font-family: 'Nunito-Regular';
    font-style: normal;
   
    line-height: 25px;
   
    color: #25252D;

}

.fastag-Id-No{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
border-left: 1px solid ;
  height: 24px;
/* identical to box height */


color: #25252D;
}

.main-container-fastag{
    position: relative;
   
}


.main-vehcile-No{
    padding-left: 16px;
}


.auto-recharge{
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
@media (max-width: 1400px) and (min-width: 1200px) {
    .recharge-fastagId-button{
        font-size: 0.9rem;
    }
}

.omcBox{
    border: 1px solid rgba(0,0,0,.125);
    border-Radius: 0.25rem;
    height: 88px;
    text-align: center;
    padding-top: 10px;
}


.BoldText{
    font-weight: 600;
}

.Bold300{
    font-weight: 300;
}
.cardButton2{
    /* width: 120px; */
    border: 1px solid #4D5073;
    border-radius: 0.5rem;
    background-color: #4D5073;
    padding: 5px;
    /* margin: 5px; */
    color: #FFFFFF;
}

.ButtonNew{
    width: 150px;
    border: 0.5px solid #6C6C6C33;
    border-radius: 10px 10px 0px 0px;
    background-color: #E57231    ;
    padding: 5px;
    box-shadow: 0px 4px 8px 0px #0000000F;
    height: 40px;
    /* margin:5px; */
}

.ButtonNewInactive{
    width: 150px;
    border: 0.5px solid #6C6C6C33;
    border-radius: 10px 10px 0px 0px;
    background-color: #FFFFFF    ;
    padding: 5px;
    box-shadow: 0px 4px 8px 0px #0000000F;
    height: 40px;
    /* margin:4px; */
}
.ButtonNewInactive:hover {
    width: 150px;
    border: 0.5px solid #6C6C6C33;
    border-radius: 10px 10px 0px 0px;
    background-color: #FFFFFF;
    cursor: default;
    padding: 5px;
    box-shadow: 0px 4px 8px 0px #0000000F;
    height: 40px;
    /* margin:4px; */
  }

.vehicleHeader {
    padding: 5px;
    margin-top: -10px;
    display: flex;
}
.vehicleHeaderV2 {
    padding: 5px;
    font-weight: 600;
    margin-top: -10px;
    font-size:larger;
    display: flex;
}

.map-container {
    height: 400px; /* Fixed height */
    width: 100%; /* Or fixed width as needed */
    overflow: hidden; /* Prevent scrollbars if the map tries to overflow */
    position: relative;
    z-index: 0;
  }