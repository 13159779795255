.fastagHomeBox{
    box-sizing: border-box;
    position: relative;
    /* width: 25rem; */
    /* min-height: 7rem; */
    border: 1px solid #EFEFEF;
    border-radius: 8px;
}

.line-for-separation{
        /* width: 362px; */
        height: 0px;
        /* margin: 3px 0 0 96px; */
        border: 0.4px solid #EFEFEF;
}

.kyc{
    color: white;
    background-color: #09BD7B;
    width:5rem;
    text-align: center;
    position: relative;
    left:21rem; 
}

.kyc1{
    color: white;
    background-color: #09BD7B;
    width:5rem;
    text-align: center;
    position: relative;
    left:22rem;
    
}
.qrImg{
    width: 300px;
    height: 300px;
}
.qrText{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-transform: capitalize;
    color: #25252D;
}