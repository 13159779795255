.add-attachment-btn{
    width: 36px;
    height: 36px;
    border-radius: 20px;
    border: 2px #2E7C7B;
    border-style: dotted;
    margin-left: -36px;
    opacity: 0;
    cursor : pointer
}



.add-attachment-btn-2{
    width: 10rem;
    height: 20px;
    border-radius: 20px;
    border:none;
    /* border: 2px #2E7C7B; */
    /* border-style: dotted; */
    margin-left: -10rem;
    opacity: 0;
    cursor : pointer
}

.add-attachment-btn-3{
    width: 9rem;
    height: 7rem;
    border-radius: 20px;
    border: 2px #2E7C7B;
    border-style: dotted;
    margin-left: -36px;
    opacity: 0;
    cursor : pointer;
    position:absolute;
    top:0;
    left:3rem;
}


.attach-logo{
    cursor: pointer;
}

.selected-img{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #898989;
}
button:disabled{
    background-color:gray;
    }

.btn-1-selected{
    width: 181px;
    height: 42px;
    margin: 0px 0 0 96px;
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    background: #2E7C7B;
    border-radius: 4px;
    color: #FFFFFF;
}    

.btn-2-selected{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    box-sizing: border-box;
    width: 181px;
    height: 42px;
    color: #FFFFFF;
    background: #2E7C7B;
    border-radius: 4px;
}

.btn-2-unselected{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.1px;
    text-align: center;
    text-transform: capitalize;
    box-sizing: border-box;
    width: 181px;
    height: 42px;
    color: #6C6C6C;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    position: relative;
}

.btn-1-unselected{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    width: 181px;
    height: 42px;
    margin: 0px 0 0 96px;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    color: #6C6C6C;
}