.main-body {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-SemiBold";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito"), url("./assets/fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Medium";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto"),
    url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

.App {
  font-family: "Nunito-Regular";
  /* height: 1026px; */
}

.posRelative {
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto-Regular",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(243, 245, 253); */
  background-color: #fef9f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.FontBig {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 32px;
}

.FontMedium {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
  /* letter-spacing: 1px; */
}

.FontSMedium {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
}

.FontBold {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24.5px;
  /* letter-spacing: 1px; */
}

.FontSuperBold {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;

  /* letter-spacing: 1px; */
}

.FontSmall {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  color: #6c6c6c;
  overflow: hidden;
}

.FontNM2 {
  font-family: "Nunito-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.82px;
  /* letter-spacing: 1px; */
}
.FontNS2 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.82px;
  /* letter-spacing: 1px; */
}

.FontNB {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 22px;
}

.FontNB1 {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
}

.padding8 {
  padding: 0.5rem;
}

.padding16 {
  padding: 1rem;
}
.padding-16 {
  padding: -1rem;
}
.padding8R {
  padding-right: 0.5rem;
}

.padding8T {
  padding-top: 0.5rem;
}

.padding8B {
  padding-bottom: 0.5rem;
}

.padding8L {
  padding-left: 0.5rem;
}
.padding9L {
  padding-left: 1rem;
}

.padding16L {
  padding-left: 1rem;
}

.padding16R {
  padding-right: 1rem;
}

.padding16T {
  padding-top: 1rem !important;
}

.padding16B {
  padding-bottom: 1rem;
}

.padding24L {
  padding-left: 1.5rem;
}

.padding24R {
  padding-right: 1.5rem;
}

.padding24T {
  padding-top: 1.5rem;
}

.padding24B {
  padding-bottom: 1.5rem;
}

.padding24 {
  padding: 1.5rem;
}

.form-control:focus {
  box-shadow: none !important;
}
.greenButtonNewNotSelected {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  height: 35px;
  /* left: 282px; */
  /* top: 25px; */
  /* background: #485b7c; */
  padding: 2px;
  padding-inline: 20px;
  border: 1px solid #4d5073;
  background-color: white;
  border-radius: 20px;
  color: #4d5073;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}
.greenButtonNew {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  height: 35px;
  padding: 2px;
  padding-inline: 20px;
  /* left: 282px; */
  /* top: 25px; */
  background: #4d5073;
  border: 1px solid #96a9ca;
  border-radius: 20px;
  color: white;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}
.greenButton {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  height: 35px;
  /* left: 282px; */
  /* top: 25px; */
  background: #485b7c;
  border: 1px solid #96a9ca;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}
.greenButton:hover {
  /* background: #4D5073; */
  background: #ed752e;
}
.greenButtonMini {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  height: 29px;
  /* left: 282px; */
  /* top: 25px; */
  background: #4d5073;
  border: 1px solid #4d5073;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}
.greenButtonMini:hover {
  /* background: #4D5073; */
  background: #ed752e;
}
.greenOutlineButton {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  height: 35px;
  /* left: 282px; */
  /* top: 25px; */
  color: #485b7c;
  border: 1px solid #485b7c;
  background-color: transparent;
  border-radius: 4px;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}

.greenOutlineButton:hover {
  color: #ed752e;
  border: 1px solid #ed752e;
  background-color: transparent;
}

.greenOutlineButton:active {
  color: #ed752e;
  border: 1px solid #ed752e;
  background-color: transparent;
}

.greenOutlineButtonMini {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  height: 29px;
  /* left: 282px; */
  /* top: 25px; */
  color: #4d5073;
  border: 1px solid #4d5073;
  background-color: transparent;
  border-radius: 4px;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}

.greenOutlineButtonMini:hover {
  color: #4d5073;
  border: 1px solid #4d5073;
  background-color: transparent;
}

.greenOutlineButtonMini:active {
  color: #4d5073;
  border: 1px solid #4d5073;
  background-color: transparent;
}

.greenOutlineButton1 {
  box-sizing: border-box;
  /* position: absolute; */
  width: 20rem;
  height: 45px;
  /* left: 282px; */
  /* top: 25px; */
  color: #4d5073;
  border: 1px solid #4d5073;
  background-color: transparent;
  border-radius: 4px;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}

.greenOutlineButton1:hover {
  color: #4d5073;
  border: 1px solid #4d5073;
  background-color: transparent;
}
.greenOutlineButton1:active {
  color: #4d5073;
  border: 1px solid #4d5073;
  background-color: transparent;
}

.padding0 {
  padding: 0px;
}
.paddingimp0 {
  padding: 0px !important;
}

.margin0 {
  margin: 0px;
}

.padding0L {
  padding-left: 0px;
}

.padding0R {
  padding-right: 0px;
}

.padding0T {
  padding-top: 0px;
}

.padding0B {
  padding-bottom: 0px;
}

/* extra global */

.padding32T {
  padding-top: 2rem;
}

.padding32B {
  padding-bottom: 2rem;
}

.padding32R {
  padding-right: 2rem;
}

.padding32L {
  padding-left: 2rem;
}

.padding32LR {
  padding-left: 2rem;
  padding-right: 2rem;
}

.rightAlignment {
  text-align: right;
}

.bottomLine {
  border-bottom: 1px solid #6c6c6c33;
}

.flexStyling {
  display: flex;
  flex-direction: row;
}

.padding32 {
  padding: 2rem;
}

.div-table {
  display: table;
  width: 100%;

  /* cellspacing:poor IE support for  this */
}
.div-table-row {
  display: flex;
  width: auto;
  clear: both;
  padding: 0px 8px;
}
.div-table-col {
  float: left; /* fix for  buggy browsers */
  display: table-column;
}

.rowCont {
  width: 100%;
  cursor: pointer;
  padding: 8px 0px;
}

.width50 {
  width: 50%;
  padding: 8px;
}

.width75 {
  width: 75%;
  padding: 8px;
}

.width50-vehicle {
  width: 50%;
  /* padding: 1rem; */
}

.width33 {
  width: 33.33%;
  padding: 8px;
}

.width40 {
  width: 40%;
  padding: 8px;
}

.width30 {
  width: 30%;
  padding: 8px;
}
.width20 {
  width: 20% !important;
  padding: 8px;
}

.width25 {
  width: 25%;
  padding: 8px;
}

.width70 {
  width: 70%;
  padding: 8px;
}

.rowContN {
  width: 100%;
  cursor: pointer;
  padding: 4px 0px;
}

.width60N {
  width: 60%;
  padding: 4px;
}
.width40N {
  width: 40%;
  padding: 3px;
}

.width75N {
  width: 75%;
  padding: 5px;
}
.width25N {
  width: 25%;
  padding: 4px;
}
.width15N {
  width: 15%;
  /* padding: 4px; */
}
.FontNBList {
  font-family: "Nunito-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 22px;
}
[data-letters]:before {
  position: relative;
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 1.5em;
  left: 10px;
  top: 0px;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  border-radius: 50%;
  background: #e0e4f9;
  vertical-align: middle;
  margin-right: 1em;
  color: black;
}
[data-letters-vehicle]:before {
  position: relative;
  content: attr(data-letters-vehicle);
  display: inline-block;
  font-size: 1em;
  width: 2.2em;
  left: 10px;
  top: 0px;
  height: 2.2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  background: #e0e4f9;
  vertical-align: middle;
  margin-right: 1em;
  color: black;
}

[data-letters-recharge]:before {
  position: relative;
  content: attr(data-letters-recharge);
  display: inline-block;
  font-size: 1em;
  width: 2.2em;
  left: 10px;
  top: 10px;
  height: 2.2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  background: #fae5db;
  vertical-align: middle;
  margin-right: 1.5em;
  color: black;
}

.vehSubInfo {
  margin-left: 4.7em;
  color: #485b7c;
}

.nameSubInfo {
  margin-left: 3.25em;
}

.nameSubInfoVehicle {
  margin-left: 4.2em;
}

.nameSubInfo2 {
  margin-left: 2.65em;
}
.actve-button-chosen-for-customer .rowCont {
  background-color: #f3f5fd;
}

.actve-button-chosen-for-customer {
  border-left: solid #6378e3;
}

.actve-button-chosen-for-driver {
  background-color: #e5723180;
}

.actve-button-chosen-for-driver {
  border-left: solid #6378e3;
}

.displayFlex {
  flex: 1;
}

.listHeaderCont {
  padding: 12px 0px;
  border-bottom: solid 1px #e2e2e2;
  width: 100%;
}

.BottomBorder {
  /* padding: 12px 0px; */
  border-bottom: solid 1px #e2e2e2;
}

.FUllListSearchBar {
  margin: auto;
  padding: 16px 12px;
  position: relative;
}

.FullCustomerListBox {
  position: relative;
  width: auto;

  background: #ffffff;
  border-radius: 4px 0px 0px 0px;
}

.PrevButton,
.nextButton {
  padding: 6px 12px;
  background-color: white;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #e1e1e1;
  outline-style: none;
  box-shadow: none;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
}
.nextButton {
  border-radius: 0px 4px 4px 0px;
  border-left-width: 0px;
}

.nextRow {
  padding: 8px;
  text-align: right;
}

.nextRowCont {
  padding: 8px 0px;
  padding-top: 16px;
  border-top: solid 1px #eee;
}

.ManageCustomerCardType-1 {
  padding: 8px;
  padding-top: 0px;
  width: 33.33%;
}

.firstCard {
  padding-left: 0px;
}

.lastCard {
  padding-right: 0px;
}

.flexRow {
  display: flex;
}

.flex1 {
  flex: 1;
}

.ManageCustomerMidBar {
  position: relative;
  border: 1px solid #e2e2e2;
}
.contentMiddle {
  justify-content: center;
  align-items: center;
}

.transTionContainer {
  padding-top: 32px;
}

.normalGrey {
  font-family: "Nunito-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;

  color: #6c6c6c;
}

.reactPagination .page-link {
  color: #485b7c;
}

.page-item.active .page-link {
  background-color: #485b7c;
  color: #fff;
}

.reactPagination {
  padding: 24px 16px;

  font-weight: bold;
}

.pagination {
  margin: 0px;
}

.number {
  position: relative;

  height: 25px;
  padding-left: 8px;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #25252d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.flexGrow {
  flex-grow: 1;
}

.first-expand {
  width: 220px;
}

.driverexpensed td {
  padding: 24px;
  text-align: left;
}

tr td {
  padding: 24px 14px 24px 24px !important;
  text-align: left !important;
}

tr th {
  padding: 7px !important;
  text-align: left !important;
}

.driverexpensed td:first-child {
  max-width: 180px;
}

.borderRound {
  border-radius: 4px;
}

/* font styles */

.heading1 {
  font-size: 24px;
  line-height: 32.74px;
}

.headingLow {
  font-size: 24px;
  line-height: 32.74px;
}

.headingBottom {
  font-size: 14px;
  line-height: 20.46px;
}

.textDiv {
  font-size: 14px;
  line-height: 19.1px;
}
.textDiv1 {
  font-size: 14px;
  line-height: 19.1px;
}
.textDiv2 {
  font-size: 12px;
  line-height: 16px;
}

.heading3 {
  font-size: 16px;
  line-height: 21.82px;
}

.heading2 {
  font-size: 18px;
  line-height: 24.55px;
}

.ContainerWidth {
  width: 24rem;
}

.paddingCash {
  padding-left: 1.8rem;
}

.ownerCardDiv {
  width: 406px;
  height: 246px;
  background-image: linear-gradient(
    145deg,
    #4c4059 0% 72%,
    #52465e 72% 79%,
    #584e65 79% 100%
  );
  background: #4c4059;
  border-radius: 12px;
}

.paddingLR {
  padding-left: 2rem;
  padding-right: 2rem;
}

.FontSmall1 {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  color: #6c6c6c;
}

.table-header-customer-details th {
  /* letter-spacing: 1px; */
  font-size: 14px;
}

.table-header-customer-details-dr th {
  /* letter-spacing: 1px; */
  font-size: 12px;
  line-height: 16.37px;
  background: #ededf1;
}

.text3 {
  font-size: 14px;
  line-height: 19.1px;
}

.text4 {
  font-size: 12px;
  line-height: 16.37px;
}

.heading4 {
  font-size: 14px;
  line-height: 20.46px;
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .heading4 {
    font-size: 11px;
    line-height: 16px;
  }

  .heading1 {
    font-size: 20px;
    line-height: 30px;
  }
  .text3 {
    font-size: 12px;
    line-height: 16.5px;
  }
  .text4 {
    font-size: 10px;
    line-height: 14px;
  }
  .headingLow {
    font-size: 20px;
    line-height: 32.74px;
  }
  .FontSmall1 {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 19px;
    color: #6c6c6c;
  }
  .headingBottom {
    font-size: 11px;
    line-height: 20.46px;
  }
  .textDiv {
    font-size: 12px;
    line-height: 19.1px;
  }
  .heading3 {
    font-size: 14px;
    line-height: 21.82px;
  }
  .heading2 {
    font-size: 16px;
    line-height: 24.55px;
  }

  .ContainerWidth {
    width: 20rem;
  }

  .paddingCash {
    padding-left: 1.875rem;
  }

  .textDiv1 {
    font-size: 13px;
    line-height: 19.1px;
  }

  .ownerCardDiv {
    width: 23.5rem;
    height: 246px;
    background-image: linear-gradient(
      145deg,
      #4c4059 0% 72%,
      #52465e 72% 79%,
      #584e65 79% 100%
    );
    background: #4c4059;
    border-radius: 12px;
  }

  .paddingLR {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .FontNB {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 22px;
  }
  .FontNB1 {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 22px;
  }

  .FontSmall {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 0.775rem;
    line-height: 19px;
    color: #6c6c6c;
  }
  .nameSubInfo {
    margin-left: 3em;
  }

  .FontBold {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    /* letter-spacing: 1px; */
  }
  .FontBoldMedium {
    font-family: "Nunito-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 24px;
    /* letter-spacing: 1px; */
  }

  .table-header-customer-details th {
    font-size: 14px;
  }

  .table-header-customer-details-dr th {
    font-size: 10px;
  }

  tr th {
    padding: 24px 14px !important;
    text-align: left !important;
  }

  tr td {
    padding: 24px 11px 24px 18px !important;
    text-align: left !important;
  }
  .FontSuperBold {
    font-size: 1.2rem;
  }
}

.borderRad7 {
  border-radius: 0.7rem;
}

.text1 {
  font-size: 18px;
  line-height: 24.55px;
}

.text2 {
  font-size: 16px;
  line-height: 21.82px;
}

.margin16L {
  margin-left: 1rem;
}

.blackWeight {
  font-weight: 900;
}

.extraBold {
  font-weight: 800;
}

.Bold {
  font-weight: 700;
}

.semiBold {
  font-weight: 600;
}

.Medium {
  font-weight: 500;
}

.Regular {
  font-weight: 400;
}

/* light-mode colors */

.primaryWhite {
  color: #ffffff;
}

.secondaryWhite {
  color: rgba(255, 255, 255, 0.6);
}

.disabledWhite {
  color: rgba(255, 255, 255, 0.4);
}

/* dark-mode colors */

.primaryBlack {
  color: #25252d;
}

.secondaryBlack {
  color: rgba(37, 37, 45, 0.6);
}

.disabledBlack {
  color: rgba(37, 37, 45, 0.4);
}

.whiteBackground {
  background-color: #ffffff;
}

.greenFont {
  color: #485b7c;
}

.greenColor {
  color: #09bd7b;
}

.redColor {
  color: #fe2950;
}

.greyFont {
  color: #6c6c6c;
}

.greyFont2 {
  color: #a7a7a7;
}

.orangeModalBAckground {
  background-color: rgba(229, 123, 77, 0.1);
}

.greenModalBackground {
  background-color: #96a9ca;
}

.modalPagination {
  /* margin:auto; */
  font-weight: bold;
  padding: 1.5rem 1rem 0;
}

.modalPagination .pagination {
  position: relative;
  /* top:10%; */
  /* left:10%; */
  bottom: 0;
  /* transform: translate(-25%,5%); */
}

.modalPagination .page-link {
  color: #485b7c;
}

/* sachin changes */

.closeEffectRow {
  width: 100%;
  position: relative;
}

.closeEffect {
  position: absolute;
  right: 50px;
}

.all-transaction-main-modal {
  max-width: 100% !important;
  position: fixed;
  left: 50%;
  transform: translateX(-50%) !important;
  height: 100%;
  margin: 0px !important;
}

.center-main-modal {
  max-width: 100% !important;
  position: fixed;
  left: 50%;
  transform: translateX(-50%) !important;
  height: 100%;
  margin: 0px !important;
}

.transitionContainer {
  padding: 16px 1.5rem;
  height: calc(100% - 140px);
}

.tableContaincer {
  height: calc(100% - 50px);
}

.MasterFastagTable tbody {
  overflow-y: auto;
  height: calc(100% - 50px) !important;
  overflow-x: hidden;
}

.MasterFastagTable {
  height: 100%;
}

.DriverSectionTable tbody {
  overflow-y: auto;
  /* height: calc(100% - 50px) !important; */
  overflow-x: hidden;
}

.DriverSectionTable {
  height: 100%;
}

/* asmita changes */

.transitionContainerDrivers {
  padding: 0rem 2.5rem 0.5rem;
  height: calc(100% - 200px);
}

.tableContaincerDrivers {
  height: calc(100% - 50px);
}

.transitionContainerCashback {
  padding: 0rem 3rem 0.5rem;
  height: calc(100% - 165px);
}

.transitionContainerFastag {
  padding: 0 2rem 0.5rem;
  height: calc(100% - 45px);
}

.transitionContainerCollection {
  padding: 2rem 2.5rem;
  height: calc(100% - 90px);
}

.lightGreenBackground {
  background-color: #96a9ca;
}

input[type="radio"] {
  accent-color: #485b7c;
}
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.sideBarMargin {
  margin-left: 120px;
  transition: 1s;
}
.topMargin {
  margin-top: 60px;
  padding-top: 30px;
}

.small-modal {
  position: fixed;
  transform: translateX(50%) !important;
  height: 70%;
  width: 0%;
  margin: 100px !important;
  padding: 50px !important;
}
.closeBtn-small-modal {
  position: absolute;
  right: -15px;
  top: -18px;
  border: 1px solid;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.small-modal-heading {
  margin-left: 400px;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
  color: #25252d;
}
.boxTxns {
  margin-top: 10px;
  border: 0.5px solid #e2e2e2;
  border-radius: 0.5rem;
  box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important;
}
.boxTxn1 {
  border: 0.5px solid #e2e2e2;
  border-radius: 0.5rem;
  box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important;
}
.model-width-2 {
  max-width: 1200px;
  /* border-radius: 10px; */
}
.div-table-row-new {
  display: flex;
  width: auto;
  clear: both;
  padding: 8px 0px;
}
.DateInput {
  width: "150px";
  font-size: 10;
}

.FontNS21 {
  font-family: "Nunito-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  /* letter-spacing: 1px; */
}
.FontNS18 {
  font-family: "Nunito-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* letter-spacing: 1px; */
}

.FontNR14 {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* letter-spacing: 1px; */
}
.padding20 {
  padding: 16px 24px 16px 24px !important;
}

.FontNS16 {
  font-family: "Nunito-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* letter-spacing: 1px; */
}

.FontNB20 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* letter-spacing: 1px; */
}
