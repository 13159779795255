.ManageDriverDetailsBarBox{
    width :434px;
    height:40px;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color:#fff;
}
.ManageDriverDetailsBarName{
    position: relative;
text-align: center;
margin-top :10px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #000000;
}
.ManageDriverDetailsBarVehicle{
    position: relative;
width: 82px;
height: 22px;
text-align: center;
margin-top :10px;


font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #000000;
}
.ManageDriverDetailsBarBal{
    position: relative;
width: 70px;
height: 22px;
padding-left: 20px;
margin-top :10px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #000000;
}
