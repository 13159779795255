.VehicleListBox{
    /* position: absolute;
width: 1000px;
height: 1024px; */


background: #FFFFFF;
}
.VehicleListclose{
    display: inline;
   
}
.VehicleListCloseIcon{
    /* position: relative;
    top: 6.3rem;
    left: 51rem; */
    /* position: absolute;
    left: 81.8%;
    right: 21.04%;
    top: 10%;
    bottom: 94.34%; */
    /* cursor: pointer; */

}
.VehicleListCloseText{

    position: absolute;
    left: 85.11%;
    
    top: 10%;
    bottom: 94.34%;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}
.VehicleListHeader{
    padding-top: -100px;
}

.VehicleListText{
    position: absolute;
    width: 290px;height: 33px;
left: 15%;
top: 10%;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.VehicleListIcon{
    position: absolute;
left: 9%;
right: 15.62%;
top: 10%;
bottom: 12.5%;

}
.VehicleListSearchBar{

/* position: absolute; */
/* width: 828px; */
/* left: 9%; */
top: 155px;
padding: 0 3rem;
}
.VehicleListButton{
    left: 72%;
top: 92px;
position: absolute;
}
.VehicleListBar{
    position: absolute;
    left: 7.5%;
    top:240px;
}
.VehicleListCard{
    left: 9.5%;
    top:300px;
    position:absolute;
}


.vehicleAssign-scroll{
    overflow-y: scroll;
    direction: row;
    overflow-x: hidden;
}

.driverTable tr td{
    padding: 1.2rem 0.5rem 1.2rem 2.2rem!important;
    text-align: left !important;
}

.driverTable tr th{
    padding: 1rem 0 1rem 2rem !important;
    text-align: left !important;
}

table tbody {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
  }

table thead, table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

.driverTable tbody{
  
    overflow-y: auto;
    height: calc(100% - 50px) !important;
 }

.driverTable{
    height: 100%;
 }  