.DriverRequestFrameBox{
    /* position: absolute;
width: 950px;
height: 1024px;
left: -200px;
top: -30px; */

background: #fff;
}
.DriverRequestHeader{
    padding-top: -100px;
}
.close-request-bal{
    display: inline;
   
}
.closeIcon{
    position: absolute;
    left: 812px;
    right: 21.04%;
    top: 2rem;
    bottom: 94.34%;

}
.closeText{
    position: absolute;
    left: 835px;
    right: 20.04%;
    top: 40px;
    bottom: 94.34%;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}
.DriverRequestText{
    position: relative;
width: 262px;
height: 33px;
left: 2.5rem;
/* top: 2.5rem; */
display: flex;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.DriverRequestIcon{
    position: relative;
left: 4.5rem;
/* top: 2rem; */
/* padding-top: 2rem; */
padding-bottom: 2rem;
}
.DriverRequestCard{
    position: absolute;
    left: 6%;
    top: 173px;
}
.Pending{
   position: absolute;
   
}
.Approved{
    position: absolute;
    left: 278px;

}
.Declined{
    position: absolute;
    left: 556px;

}
.RequestTitle{
    position: absolute;
    left: 6%;
    top: 260px;
}
.Details{
position: absolute;
width: 90px;
height: 19px;
left:30px;
font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.CurBalance{
position: absolute;
width: 90px;
height: 19px;
left: 188px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ReqDate{
position: absolute;
width: 90px;
height: 19px;
left: 346px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ReqBalance{
    width: 90px;
height: 19px;
position: absolute;
left: 498px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.Requests{
    position: absolute;

left: 6%;
top: 297px;


background: #F5F5F5;
}

.CashbackBarDetails{
    position: relative;
    /* top:3rem; */
    left: 5rem;
    width: 811px;
    display: flex;
    flex-direction: row;
    height: 51px;    
    margin-top: 7px;  
    background: rgba(46, 124, 123, 0.1);
}


.DriverRequestIcon1{
    position: relative;
    top:2.5rem;
    left: 10rem;
}

.DriverRequestText1{
    position: relative;
    top: 2.7rem;
    left: 8rem;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.28px;
    font-family: 'Nunito-Regular';
font-style: normal;
}


.cashbackNameClass{
    position: relative;
    width: 15rem;
    height: 25px;
    margin-left: 30px;
    
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;

}

.cashbackAmount{
    position: relative;
    width: 100px;
    height: 22px;
    margin-left: 6.25rem;
   
   
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    color: #09BD7B;
}

.cashbackDate{
    position: relative;
    width: 8rem;
    height: 22px;
    margin-left:190px;
    
   
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
    
}

.cashbackBox{
    position: relative;
    width: 811px;
    height: 20px;
    /* top:4rem; */
    left:5rem;
    background: #FFFFFF;
}

.cashbackMode{
    position: relative;
    width: 200px;
    height: 19px;
    /* top:10px; */
    left: 5rem;
    margin-left: 30px;
    margin-top: 0px;
        
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #25252D;
}

.cashbackTime{
    position: relative;
    width: 200px;
    height: 22px;
    /* top:0.5rem; */
    left: 32rem;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;
}

.cashbackListing1{
    max-height: 460px;
    /* position: relative; */
    /* top: 3.5rem; */
    padding-top: 1rem;
    overflow-y: scroll;
    white-space: nowrap;
    direction: row;
    overflow-x: hidden;
}

.PGCollectionclose1 {
    display: inline;
    margin-left: 800px;
    position: relative;
    background: #fff;
    right: 10px;
    /* margin-top: -10px; */
    border: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    /* top:20px */
    /* padding-top: 20px; */
}

.horizontalLine{
    margin: 2rem auto;
    width: 790px;
}

.filterText{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color:#25252D;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 16px;
}

.filterDates{
    font-family: 'Nunito-Regular';
    font-style: normal;
    color:#25252D;
    display: flex;
    flex-direction: row;
    padding: 3px 5px;
    background-color: #96A9CA;
    text-align: center;
    margin-right: 20px;
}

.downloadText{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #25252D;
    cursor: pointer;
}



.filterImg{
    padding-left: 1rem;
}

.newCashback{
    box-sizing: border-box;
    /* position: relative; */
    /* width: 25rem; */
    /* min-height: 7rem; */
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    text-align: center;
}

.cashbackTableSetting tr td{
    padding: 1rem 1rem 1rem 5rem!important;
    text-align: left !important;
}

.cashbackTableSetting tr th{
    padding: 1rem 2rem 1rem 4.5rem !important;
    text-align: left !important;
}

.cashbackTableSetting thead, .cashbackTableSetting tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

.cashbackTableSetting tbody{
    overflow-y: auto;
    height: calc(100% - 50px) !important;
 }  

 .cashbackTableSetting{
    height: 100%;
 }