.table-header-trip {
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* letter-spacing: 1px; */
    line-height: 21.82px;
    background: #ededf1;
    height: 45px;
  }

  .table-row-manage-trip-new{
    height:40px;
    border-width:0 !important;
    color: #25252D;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* border-bottom: 1px solid #E2E2E2; */
  }


  .TripSectionTable tbody {
    overflow-y: auto;
    /* height: calc(100% - 50px) !important; */
    overflow-x: hidden;
  }
  
  .TripSectionTable {
    height: 81%;
  }
  
  .TripSectionTable tr td{
    padding: 12px;
    text-align: left !important;
  }
  
  .TripSectionTable thead{
    border-color: transparent !important;
  }
  
  .TripSectionTable thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  