.Enter-Fleet-Number{
    font-family: 'Nunito-Regular';
    font-style: normal;
    width: 100%;
    background-color: #FFFFFF;
    border: none;
    border-bottom: 1px solid #A7A7A7;
    min-width: 260px;
    max-width: max-content;
    margin: 105px 0 0 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    /* display: flex; */
    align-items: center;
    text-align: center;
}

.Enter-Fleet-Number:hover{
    outline: none;
}
.Enter-Fleet-Number:focus{
    outline:none;
}
.Enter-Fleet-Number::placeholder{
    color: #A7A7A7;
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
}

.input-amount111{
    margin: 8px 0 0 105px;
    }