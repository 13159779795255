.ApproveButton{
    box-sizing: border-box;

    position: absolute;
    width: 78px;
    height: 32px;
    border: 1px solid #2E7C7B;
    border-radius: 20px;
}
.ButtonTextContainer{
    position: absolute;
    width: 55px;
    height: 19px;
    left: 11px;
   top:2px;
   font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #2E7C7B;
}
.DeclineButton{
    box-sizing: border-box;

    position: absolute;
    width: 78px;
    height: 32px;
    border: 1px solid #E2E2E2;
    border-radius: 20px;

}
.DeclineButtonText{
    position: absolute;
    width: 55px;
    height: 19px;
    left: 15px;
    top:2px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #FE2950;
}