.hrPosition1{
    border-top: 0.1rem solid;
    position: relative;
    top:0.5rem;
    
  }

.boxBtn{
    border: 0.1rem solid #E2E2E2;
    border-radius: 0.4rem;
  }

.cartDetails2{
    box-shadow: 0 4.77px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem 1.5rem;
    height: 1050px;
}

.padding24LR{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.cartItemContainer1{
    height: 45%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

.hrPosition{
    width: 95%;
    position: relative;
    left: 2.5%;
    color: #898989;
  }