.logipeDetialCardHeader{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}