.ManageDriverDetailsBar2Box{
    position: relative;
width: 808px;
height: 51px;
display: flex;
flex-direction: row;

background: rgba(46, 124, 123, 0.1);
}
.ManageDriverDetailsBar2Type{
    position: relative;
    left :24px;
    top:16px;
    width: 111px;
height: 19px;


font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ManageDriverDetailsBar2Amount{
    position: relative;
width: 53px;
height: 19px;
left: 110px;
top: 16px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ManageDriverDetailsBar2By{
    position: relative;
width: 18px;
height: 19px;
left: 100px;
top: 16px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ManageDriverDetailsBar2Mode{
    position: relative;
width: 37px;
height: 19px;
left: 60px;
top: 16px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ManageDriverDetailsBar2Date{
    position: relative;
width: 32px;
height: 19px;
left: 70px;
top: 16px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ManageDriverDetailsBar2Action{
    position: relative;
width: 50px;
height: 19px;
left: 55px;
top: 16px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ManageDriverDetailsBar2AmountImage{
    position: relative;
    left:165px;
    top:-5px;
}
.ManageDriverDetailsBar2DateImage{
    position: relative;
    left:105px;
    top:-7px;
}
.ManageDriverDetailsBar2ByImage{
    position: relative;
    left:115px;
    top:-6px; 
}
.ManageDriverDetailsBar2ModeImage{
    position: relative;
    left:100px;
    top:-7px; 
}