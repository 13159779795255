.SearchBarBox {
    box-sizing: border-box;

    position: relative;
    width: 811px;
    height: 46px;
    
    padding-left: 50px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
  }
  .SearchBarBox::placeholder{
    position: relative;
    width: 151px;
    height: 22px;
  
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #A7A7A7;
  }
  .SearchIcon{
   
    
    
  }