.invalid-feedback {
  /* margin-left: 98px; */
}

.form-control {
  margin-left: 5rem;
  width: 365px;
}

.body-sub-part1-ab {
  padding: 30px 0 0 0px;
  margin-left: 107px;
  font-family: "Nunito-Medium";
  font-weight: 550;
  font-size: 18px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6c6c6c;
}

.body-sub-part2-ab {
  margin-top: -9px;
  padding-left: 110px;
  width: 92px;
  height: 27px;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #25252d;
}

.select-option2 {
  width: 140px;

  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  color: black;
}

.invalid-type {
    width: 100%;
    font-size: .875em;
    color: #dc3545;
}

.circle-logo-2{
  padding-top: 2px;
  padding-right: 1px;
  margin: 32px 0 0 35px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  font-family: 'Nunito-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #485B7C;
}