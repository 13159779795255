.ManageDriverCardType4Box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 308px;
  height: 72px;

  border: 1px solid #e2e2e2;
  border-radius: 8px;
}
.ManageDriverCardType4ImageBox {
  position: relative;
  width: 48px;
  height: 48px;
  top: 12px;
  left: 8px;

  background: #f0f2fd;
  border-radius: 4px;
}
.ManageDriverCardType4Image {
  position: relative;
  left: 0px;
}
.ManageDriverCardType4Text {
  position: relative;
  left: 25px;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 25px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #25252d;
}
.ManageDriverCardType4Chevon {
  position: absolute;
  left: 270px;
  top: 20px;
}


@media (max-width: 1400px) and (min-width: 1200px) {
    .ManageDriverCardType4Text {
        font-size: 1rem;
    }
}