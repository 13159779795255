.input-amount-lining{
    width:380px;
    height: 0px;
    margin: 2px 0 0 30px;
    border: 1px solid #A7A7A7;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #96A9CA !important;
}

.MuiIconButton-colorSecondary:hover{
    background-color: #FFFFFF !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover{
    background-color: #FFFFFF !important;
}

.MuiIconButton-root:hover{
    background-color: #FFFFFF !important;
}

.endingLine{
    width: 90%;
    height: 0px;
    position: relative;
    left: 0.5rem;
    border: 0.4px solid #E2E2E2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
