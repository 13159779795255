.ManageDriverDetailsCardBox{

    position: relative;
    width: 410px;
    height: 60px;
    display: flex;
    flex-direction: row;
    background: hsl(0, 0%, 100%);
  cursor: pointer;
    border-radius: 4px;
}
.ManageDriverDetailsCardName{
    position: relative;
margin-top:17px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #000000;
}

    .ManageDriverDetailsCardVehicleNo{
        text-align: center;
        
      
     
        position: relative;
margin-top: 17px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #000000;
    }
    .ManageDriverDetailsCardBalance{
        position: relative;
        text-align: center;
margin-top: 17px;
font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #2E7C7B;
    }
    .ManageDriverIndustry{
        position: relative;
        margin-left:43px;
        margin-top:-20px;
    }