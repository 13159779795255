.extraInfo{
    background-color: #ebf2f2;;
    border-radius: 0.5rem;
}

.masterAccountImage{
    padding: 1rem 3rem;
}

.masterAccountAddText{
    padding: 0 5rem;
    text-align: center;
}