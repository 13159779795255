.main1 {
  border: none;
  padding: 35px 25px;
  border-radius: 8px 8px 0 0;
}
.vrnRowRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.con1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: "white";
}
.vrnRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.btn-edit-details {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  position: relative;

  /* width: 120px; */
  /* height: 30px; */
  min-height: 30px;
  min-width: 120px;
  border: none;
  color: white;
  background: #485B7C;
  border-radius: 8px;
}
.detailsCard {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.expenseCards {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.featureCards {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.transactions {
  margin-top: 35px;
}
.txnCategory {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px 0 15px;
}
.btnCat {
  border-radius: 16px;
  border: 1px solid #485B7C;
  background: #fff;
  width: 80px;
  color: #485B7C;
}

.btnCatSelected {
  border-radius: 16px;
  /* border: 1px solid #ED752E; */
  background: #485B7C;
  width: 80px;
  color: #fff;
}
