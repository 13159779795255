.main1 {
  padding: 25px 35px;
}
.btnCat {
  border-radius: 16px;
  border: 1px solid #2e7c7b;
  background: #fff;
  width: 80px;
  color: #2e7c7b;
}

.btnCatSelected {
  border-radius: 16px;
  border: 1px solid #2e7c7b;
  background: #2e7c7b;
  width: 80px;
  color: #fff;
}
.txnCategory {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px 0 15px;
}
.listAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jWpMhA {
  background: rgba(46, 124, 123, 0.05) !important;
}
.dqfNny {
  font-size: 18px;
  font-weight: 600;
}
.goRgCU {
  background-color: #ebf7f7;
}
.buGObk {
  background-color: #ebf7f7;
}
.eoCEVt {
  font-size: 16px !important;
}
.eoCEVt:hover {
  background-color: #e0e0e0;
}
.form-control-1 {
  display: block;
  width: 200px;
  color: #000;
  border-radius: 8px;
  margin-left: 0;
  box-sizing: border-box;
  /* position: relative; */
  height: 40px;
  border: 1px solid rgba(108, 108, 108, 0.2);
  border-radius: 4px;
}
.leftAlign {
  display: flex;
  justify-content: flex-end;
}
.searchBar-input {
  left: -1rem;
  position: relative;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: black;
  top: 0;
  border: none;
  width: 180px;
}
.boxContainer {
  text-align-last: center;
  display: flex;
}
.custNamepadd3 {
  padding-top: 5px;
  margin-right: 20px;
}

.inputBoxCust1 {
  /* font-family: 'Roboto', sans-serif;
  color: #E2E2E2;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s; */
  padding-left: 10px;
  color: #25252d;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #e2e2e2;
  height: 30px;
}
.inputBoxCust1:focus {
  outline-style: auto;
}
.inputBoxCust1::placeholder {
  color: #e2e2e2;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
}

.inputBoxCust2 {
  padding: 2px 10px 3px 10px;
  color: #e2e2e2;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #e2e2e2;
  /* height: 30px; */
  cursor: "pointer";
  background: #2e7c7b;
}
.inputBoxCust2:focus {
  outline-style: auto;
}
.inputBoxCust2::placeholder {
  color: #e2e2e2;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
}

.searchBar-input1 {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: black;
  top: 0;
  border: none;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  height: 30px;
}

.searchBar-input1:hover {
  outline: none;
}
.searchBar-input1:focus {
  outline: none;
}

.searchBar-input::placeholder {
  color: #e2e2e2;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
}
.btn-success-blue {
  background-color: #4d5073;
  color: white;
}
.countAll{
  border-radius: 50%;
    width: 24px;
    height: 24px;
    align-content: center;
    /* padding: 2px; */
    /* padding-top: 3px; */
    background: #FFFFFF;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    font: 12px Arial, sans-serif;
}

.countAllInactive{
  border-radius: 50%;
    width: 24px;
    height: 24px;
    align-content: center;
    /* padding: 2px; */
    /* padding-top: 3px; */
    background: #4d5073;
    border: 2px solid #666;
    color: #FFFFFF;
    text-align: center;
    font: 12px Arial, sans-serif;
}