.main-container-vehicle-details{
    position: relative;
    /* top: 50px; */
}

.main-edit-detail-button{
    position: relative;
    top: 24px;
    left: 49px;
}
.edit-detail-button{
color:#6378E3;
width: 101px;
height: 23px;


}

.vehicle-no-sub-title{

    position: relative;
    top: 18px;
    left: 16px;

}

.all-value{

    position: relative;
    right: 6px;
    width: 120px;
}

.RCpresent{
    text-decoration-line: "underline";
    color: "#2E7C7B";
}

.paddingBtm{
    padding-bottom: 1rem;
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .paddingBtm{
        padding-bottom: 0.5rem;
    }
}