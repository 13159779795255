.card-control-toggle-slider{
    
    position: relative;
   top:-70px;
    left:360px;
    
  }
  .card-control-atm-pin{
      width:185px;
      height:19px;
      font-family:'Nunito-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  flex-direction: row;
  
  
  
  color: #A7A7A7;
  }
  .card-control-logo{
      position:relative;
      left: 44.1px;
      top: 26.1px;
  }
  .card-control-value-edit{
    font-family:'Nunito-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  top:2px;
  left:20px;
  position:relative;
  align-items: center;
  text-transform: uppercase;
  
  color: #2E7C7B;
  }