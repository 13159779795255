.cardContainer{
    background-color: #D5E5E5;
    border-radius: 8px;
}

.cardLivquik{
    background-image: linear-gradient(to right, #4C4059 , #4C405999);
    border-radius: 8px;
}

.textFont{
    color: #616161;
    font-size: 10px;
    line-height: 10.91px;
    font-weight: 400;
}

.line1{
    width: 20%;
    height: 0px;
    position: relative;
    text-align: right;
    left: 7rem;
    border: 0.4px solid #E2E2E2;
    margin-top: 0.7rem;
}

.line2{
    width: 20%;
    height: 0px;
    position: relative;
    text-align: left;
    left: 5rem;
    border: 0.4px solid #E2E2E2;
    margin-top: 0.7rem;
}

.orText{
    text-align: center;
    font-size: 20px ;
    line-height: 27.28px;
    position: relative;
    left:6rem;
}