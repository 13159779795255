.ownerDetailsBox{
    /* width:750px; */
    height: 210.59px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    background-color: rgb(154, 19, 19);
    margin-top:20px;
    /* margin-left: -8rem; */
}




/* wallet css */

.walletBox{
    width:300px;
    height: 133px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #441b1b;
    border-radius: 4px;
    margin: auto;
}

.walletHeading{
    font-size: 20px;
    font-weight: 700;
    line-height: 27.28px;
    font-family: Nunito;
    color: #2E7C7B;
    position: relative;
    margin-top: 24px;
    margin-left: 20px;
    text-align: left;
}


.walletInfo{
    padding: 24px 20px;
}

.walletAmount{
    font-size: 24px;
    line-height: 32.74px;
    font-weight: 600;
    font-family: Nunito;
    text-align: center;
}
/* owner's details css */

.owner-details{
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
}

.owner-details-heading{
    font-weight: 400;
    color:#25252D;
    font-size: 14px;
    line-height: 21.82px;
    font-family: Nunito;
    padding-left: 1.05rem;
    text-align: left;
    position: relative;
    left: 1rem;
}

.onwer-div-heading{
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    color: #000000;
    font-family: Nunito;
    text-align: left;
    padding: 29px 0 10px 1.05rem;
}

.owner-details-info{
    font-size: 16px;
    font-weight: 600;
    line-height: 21.82px;
    color: #25252D;
    font-family: Nunito;
    text-align: right;
    /* padding-left: 150px; */
}

.walletsDisplay{
    display: flex;
    flex-direction: row;
}

.individualWallets{
    /* margin-left:2rem ; */
    margin: 10px auto;
    /* margin-top: 10px; */

}

.detailHead{
   margin-top: 16px;
}

/* .owner-detail-box{
    display: flex;
    flex-direction: row;
} */

.bank-title{
    width: 104px;
    height: 19px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-left: 0.5rem;
    margin-bottom: 10px;
    position: relative;
    top: 15px;
}
.bank-title-p{
    
    height: 19px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-left: 0.5rem;
    margin-bottom: 10px;
    position: relative;
    top: 15px;
}



/* selection card css */

.selectionBoxDiv{
    /* width:308px; */
    height: fit-content;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    background-color: white;
    position: relative;
    padding: 8px 0;
    margin: 0;


    /* top: 20px;
    
    left: 2rem;
    padding: 10px;
    margin-bottom: 15px; */
    
    /* margin-top:98px;
    margin-left: 28px; */
}

.selectionBoxDiv-text{
    position: relative;
    text-align: left;
    font-size: 18px;
    line-height: 24.55px;
    font-weight: 400;
}


.selectionBoxDiv-text-1{
    color:#2E7C7B;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
}

.selectionBoxDivText{
    font-size: 18px;
    font-weight: 400;
    line-height: 24.55px;
    font-family: Nunito;
}

.Horizon3{
    width: 310px;
    position: relative;
    left: 1.05rem;
    /* top: -15px; */
    margin: 0;
}

.downArrowPic{
    position: relative;
    align-self: center;
    cursor: pointer;
}


/* css for the dropdown */

.dropDownDiv{
    width: 362px;
    /* height: 27.25rem; */
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    background-color: white;
    position: absolute;
    margin-top: 5px;
}

.DriverName {
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #6c6c6c;
}

.search-Bar1{
    box-sizing: border-box;
    position: relative;
    margin: 12px;
    width: 278px;
    height:44px;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
}

.search-bar-logo1{
    position:relative;
    height: 24px;
    width: 24px;
    padding: 8px 15px;
}

.search-bar-input1{
    position: relative;
    width:800px;
    height:30px;
    align-items: left;
    margin: 6px;
    margin-left: 20px;
}

.dropDownText{
    position: relative;
    left: 1.3rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.1px;
}

.listItems{
    font-size: 16px;
    font-weight: 600;
    line-height: 21.82px;
    position: relative;
    left: 1rem;
    cursor: pointer;
}
.wonerName{
    vertical-align: middle;
    margin-left: 4px;
    font-size: 18px;
}
.headingRight{
    vertical-align:middle;
    color:darkslategray;
    text-align: right;
    font-size: 16px;
    /* margin-left: 6px; */



}
.detailsHeadings{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Nunito',sans-serif;
    padding-right: 0;


}
.userDetails{
    font-weight: 500;
    font-family: 'Nunito',sans-serif;
    font-size: 14px;
line-height: 18px;
text-align: right;

color:darkslategray;
}
@media only screen and (max-width:320px){
    .headingRight{
        font-size: 12px;
    }
    .detailsHeadings{
        font-size: 10px;
    }
    .userDetails{
        font-size: 10px;
    }
    .bold-text{
     font-weight: 500;
    }
    .border-cashback{
        height: 180px;
    }
    .TransactionBoxHeading{
        font-size: 17px !important;
        font-weight: 400;
    }
    .tabs {
        height: 62px !important;
        margin-left: 29px !important;
        margin-top: 1px !important;
        width: 100px;
    }
    .table-header-transaction-details{
        display:grid;
        align-content: stretch;
        align-items: center;
        padding: 0px !important;
    }
   
}
@media only screen and (min-width:320px) and (max-width:550px){
    .table-header-transaction-details{
        display:grid;
        align-content: stretch;
        align-items: center;
        padding: 0px !important;
        margin-top: 30px;
        margin-left: -10px;
    }
    
}
