.search-bar-input3{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    color: black;
    border: none;
}

.search-bar-input3:hover{
        outline: none;
}
.search-bar-input3:focus{
    outline: none;
}
.SerachLogoV2{
    margin-top: 7px;
}