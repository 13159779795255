
.quantityDiv1{
    border: 1px solid #e2e2e2;
    padding: 0.2rem;
    border-radius: 0.4rem;
    width: 50%;
  }

.closeBtn{
    position: absolute;
    right: 0.5rem;
    top:0.5rem;
    cursor: pointer;
  }

.cartItem{
    /* box-shadow: 0 5.63px 25px 14px #F7F7F7; */
    box-shadow: 0 4.77px 25px 0 rgba(0, 0, 0, 0.1);
  }

.padding0BB{
  padding-bottom: 0px !important;
}  
.btnCursor{
cursor: pointer;
}

.availableCoin{
  color: #A7A7A7;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 19px;
  text-align: left;
  padding-left: 1.5rem;
}

.PriceHeading{
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 24px;
}


.coinPaymentDiv{
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.5rem;
  padding-top: 1rem;
}

.coinInfo{
  background-color: #EFB09433;
  color: #485B7C;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.discAmount{
  color: #09BD7B;
  font-size: 1rem;
  line-height: 24px;
}

/* .addAddress1{
  font-size: 1.5rem;
  line-height: 24px;
  font-weight: 600;
  /* cursor: pointer; */



@media only screen and (max-width: 600px){
    .quantityDiv1{
        width: 80%;
        
      }

}  
  