
    
    .head-part{
        font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
    }
    .card-wallet-body2{
        display: flex;
        flex-direction: row;
        position: relative;
        margin: 0px -16px 0 4px;
        width: 258px;
        height: 95px;
    
    }


    .title-color1{
        background: rgba(226, 66, 162, 0.1);
    }

    .title-color2{
        background: #ECF7EB;
    }
    
    
    
    .input-body{
        border: none;
        margin-left: 8px;
        font-family: 'Nunito-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;

        color: #25252D;

    }
    .input-body:focus {
        outline: none;
    }

    .bank-card-wallet{
        position: relative;
    top: 26.1px;
    left: 25px;
    }
    
    .card-wallet-logo-cardWallet{
        position: absolute;
        left: 41.33px;
        top: 28px;
    }
    .circle-logo-forward-arrow{
        position: absolute;
        left: 230px;
        top: 30px;
        height: 36px;
        width: 36px;
        background: #FFFFFF;
        border-radius: 50%;
    }
