.ManageDriverCardType3Box{
    box-sizing: border-box;

position: relative;

height: 80px;
padding: 8px;
border: 1px solid #E2E2E2;
border-radius: 8px;
}
.ManageDriverCardType3Vehicle{
  
    position: relative;
   
   
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 22px;
}
.ManageDriverCardType3Text{
    cursor: pointer;
    margin-top:-15px;
    position: relative;
    margin-left:160px;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
/* identical to box height */


color: #2E7C7B;

}
.ManageDriverCardType3Assigned{
    position: relative;

    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;

padding-top: 16px;


color: #25252D;
}


.ManageDriverCardType3Click{
    position: absolute;
    top: 16px;
    right: 8px;
}


@media (max-width: 1400px) and (min-width:1200px){
    .ManageDriverCardType3Text {
        margin-left: 145px;
        font-size: 13px;
    }
}