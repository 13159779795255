.set-btn-2-new{
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;
    box-sizing: border-box;
    width: 181px;
    height: 42px;
    color: #FFFFFF;
    background: #2E7C7B;
    border-radius: 4px;
    position: relative;
    top: -0.7rem;
}