.OwnerLogipeCardHeader{
    /* position: absolute;
width: 305px;
height: 22px;
left: 20px;
top: 76px; */

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: left;
letter-spacing: 0.04em;

color: #09BD7B;
}
.OwnerLogipeCardGreenBar{
    position: absolute;
width: 4px;
height: 36px;
left: 0px;
top: 69px;

background:#09BD7B;
}
.logipe-card-toggle-slider{
    margin-top:7px;
    margin-left:10px;
}

/* .ownerCardDiv{
    width: 406px;
    height: 246px;
    background-image: linear-gradient(145deg, #4C4059 0% 72%,#52465e 72% 79%,#584e65 79% 100%);
    background: #4C4059;
    border-radius: 12px;
} */

.cardOwnerName{
    color: hsla(0,0%,100%,.6);
}

.borderGreenInCard{
    border-color: #09BD7B;
    border-width: 0 0 0 4px;
    border-style: solid;
}

.whiteColor{
    color: #fff;
}