.customerSDetailsCardName {
    margin-left: 20px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #25252D;
}

.customerSDetailsCardBal {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #25252D;
}

.customerSDetailsCardId {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    color: #6C6C6C;
}

.customersDetailsCardDate {
    margin-left: 10px;
}

.customerDetailsAmountCard {
    margin-left: 10px
}

.customersDetailsAttachment {
    margin-left: 10px;
}

.customerDetailsAction {
    padding-left: 0px;
}

.table-row-logipe-details {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #25252D;

    height: 100px;
}

.table-header-logipe-details {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    /* background: rgba(46, 124, 123, 0.1); */
    color: #25252D;

    height: 51px;
    text-align: center;
}
.table-for-logipe-receivable{
    overflow:hidden;
    overflow-y: scroll;
    height: 367;
    position: sticky;
}

.table-for-logipe-receivable{
    overflow: hidden;
    overflow-y: scroll;
    height: 295px;
    position: sticky;
}

.view-all-transaction-customer-receeivabl{

    position: relative;
    /* top: 40px; */
    font-family: 'Nunito-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
/* identical to box height */

text-align: center;


}
.th{
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: #fff;

}
.table-row-transaction-history{
}
.table-for-logipe-receivable::-webkit-scrollbar {
    width: 1em;
}
.tbody{
    overflow-y:scroll;
    }
.fixed-header {
    position: sticky;
    top: 0;


    /* background-color: white; */
  
}    
.tableHeader{
}
.fixed-header > :not(:first-child) {
    border-top: 0;
}

.paddingL{
    padding-left: 1rem!important;
}

.bottomBorderStyle{
    border-bottom: 1px solid #E2E2E2;
}