.ManageLogipeCardType1Box{
width: 390px;
height: 76px;
background: #FFFFFF;
border-radius: 4px;
}
.ManageLogipeCardType1Icon{
margin-left: 8px;
margin-top: 14px;
background: #EBF9F2;;
}
.ManageLogipeCardType1Text{
    position: relative;
    margin-left:20px;
    margin-top:8px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    
    color: #31BF7A;
}
.ManageLogipeCardType1BottomText{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    position: relative;
    margin-left :74px;
    margin-top:-18px;
    color: #25252D;
}
.ManageLogipeCardType1IconBox{
    display: flex;
    flex-direction: row;

}

.borderDiv{
    align-items: center;
}

.cardTopBox{
    /* width: 420px; */
    /* margin: auto; */
    border-radius: 4px;
}

