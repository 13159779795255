.selectOtp{
    padding: 0.8rem 0.8rem;
    background-color: #4D5073;
    color:#ffffff;
    border: 1px solid #96A9CA;
    border-radius: 0.4rem;
    display: flex;
    width:100%;
}

.selectOtp:hover{
    background-color: #485B7C;
    color:#ffffff;
}

.selectOtp:focus{
    background-color: #485B7C;
    color:#ffffff;
}

.notSelectOpt{
    padding: 0.8rem 0.8rem;
    background-color: #ededf1;
    color:#000000;
    border: 1px solid rgba(108, 108, 108, 0.2);
    border-radius: 0.4rem;
    display: flex;
    width:100%;
}


.notSelectOpt:hover{
    background-color: #96A9CA;
    color:#000000;
}

.notSelectOpt:focus{
    background-color: #96A9CA;
    color:#000000;
}


.notGenerateOpt{
    padding: 0.5rem 0.8rem;
    background-color: transparent;
    color:#485B7C;
    border: 1px solid #485B7C;
    border-radius: 0.2rem;
    text-align: center;
    width:100%;
}


.notGenerateOpt:hover{
    background-color:transparent;
    color:#485B7C;
    border: 1px solid #96A9CA;
}

.notGenerateOpt:focus{
    background-color:transparent;
    color:#485B7C;
    border: 1px solid #96A9CA;
}


.generateOtp{
    padding: 0.5rem 0.8rem;
    background-color: #485B7C;
    color:#ffffff;
    border: 1px solid #485B7C;
    border-radius: 0.2rem;
    text-align: center;
    width:100%;
}

.generateOtp:hover{
    background-color: #96A9CA;
    color:#ffffff;
}

.generateOtp:focus{
    background-color: #96A9CA;
    color:#ffffff;
}

.absPos{
    position: absolute;
}

.centrPos{
    text-align: center;
}

.greenBack{
    background-color: #96A9CA;
}

.searchBox{
    box-sizing: border-box;
    position: relative;
    height: 40px;
    border: 1px solid rgba(108, 108, 108, 0.2);
    border-radius: 4px;
}

.searchBar-input{
    left : -1rem;
    position: relative;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: black;
    top: 0;
    border: none;
}

.searchBar-input:hover{
        outline: none;
}
.searchBar-input:focus{
    outline: none;
}

.leftBorder{
    border-left: 1px solid #6C6C6C;
}