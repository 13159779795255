.divider{
    border: 1px;
    margin-left: -24px;
    margin-right: -24px;
}
.background-box{
    background-color: #bbc7da;
    border-radius: 8px;
    padding: 0 34px 38px 34px;
    margin-inline: auto;
}
.companyDetailsHeading{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
}
.input-body-5{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: left;
    width: 100%;
    background-color: #F5F9F9;
    border: none;
    border-bottom: 1px solid #A7A7A7;
    min-width: 260px;
    max-width: 50%;
}
.NameHeading{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}
.input-body-5:hover{
    outline: none;
}
.input-body-5:focus{
    outline:none;
}
.input-body-5::placeholder{
    color: #A7A7A7;
}
.select-option1{
    border: none;
    width: 140px;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: black;
}
