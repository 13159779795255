.table-header-Fastag-details {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
   
    line-height: 22px;
    /* identical to box height */
    background: linear-gradient(0deg, rgba(77, 80, 115, 0.1), rgba(77, 80, 115, 0.1)),
linear-gradient(0deg, rgba(108, 108, 108, 0.2), rgba(108, 108, 108, 0.2));
    color: #25252D;
    height: 36px;
    text-align: left;
};
