.AllTransactionDetailsCard1{
    top:24px;
left:1rem;
position: relative;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.AllTransactionDetailsCard2{
    top:24px;
left:10px;
position: relative;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.AllTransactionDetailsCard3{
    top:24px;
left:70px;
position: relative;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.AllTransactionDetailsCardRemarks{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */


color: #898989;
}
.AllTransactionEndBar{
    position: relative;
width: 811px;
height: 0px;
    left: -10px;
top:20px;


border: .25px solid #E2E2E2;
}
.AllTransactionBox{
  
width: 784px;
height: 78px;


background: #FFFFFF;
}

.all-transaction-history-box{
    overflow-y: scroll;
    max-height: 490px;
    margin-left: 80px;
}

.all-transaction-history-box1{
    overflow-y: scroll;
    max-height: 450px;
    /* margin-left: 80px; */
    padding: 0 3rem 0 4.5rem;
}

.AllTransactionDetailsTime{
    position: relative;
    left: 41.5rem;
    top:-2rem;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
width: 90px;
}