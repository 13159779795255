.toastDiv{
    width: 33.875rem;
    height: 17.32rem;
    position: relative;
    top: 15rem;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 0.25rem;
}

.toastDiv .modal-content{
    width:33rem !important;
}

.toastDiv .PGCollectionCloseIcon{
    padding-top: 45px;
    margin-top: 0;
    cursor: pointer;
    width: 1rem;
}
.toastSVGImage{
    position: relative;
    top:-2.5rem;
    left:7rem;
}


.toastText{
    color: #6378E3;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
/* display: flex; */
    align-items: center;
    text-transform: capitalize;
    position: relative;
    left: 2rem;
    text-align: center;
    width: 27rem;
    top:-1rem
}


.modal-body-toast{
    height: 0;
}

.toastCloseHeader{
    display: inline;
    margin-left: 800px;
    position: relative;
    background: #fff;
    right: 21rem;
    top:-2rem;
    border: none;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

/* .modal-content{
    height: 0 !important;
} */

/* @media (min-width: 576px){
    .modal-dialog {
        max-width: 530px;
        margin: 1.75rem auto;
    }
} */