

.LoginScreenInputBox[type=text] {
    background: transparent;
    border: none;
    width: 250px;

    border-bottom: 1px solid #000000;
    margin-left: 343px;
    margin-top: 150px;
   
    border-color: #C4C4C4;
}
.LoginScreenInputBox::placeholder {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    /* identical to box height */
    
    
    color: #AAAAAA;
}
.LoginScreenPassWordInput{
 margin-top: 150px;
 position: relative;
 left:-20px;
}
.LoginScreenSubmitButton{

}
.LoginScreenSubmitButtonClass{
    margin-left: 280px;
    margin-top: 62px;
    text-align: center;
    width: 400px;
height: 60px;
background: #2E7C7B;
border-radius: 9px;
color: #fff;
border: none;
background: none;}

.LoginScreenBottomText{
    margin-top:70px;
    margin-left:350px;
}
.LoginScreenBottomText1{
    width:266px;
   display: flex;
   flex-direction: row;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    
    color: #AAAAAA;
}
.LoginScreenBottomText2{
    display: flex;
   flex-direction: row;
    width:266px;
    margin-top:25px;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
text-align: center;

color: #AAAAAA;
}
.CreateAccountEndText{
    margin-top: 100px;
    width:247px;
    height:32px;
    margin-left:510px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    
    color: #AAAAAA;
}
.login-page-TnC{
    width:247px;
    margin-top: 20px;
    margin-left:515px;
    height:32px;
    font-family:'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
text-align: center;

color: #AAAAAA;
}