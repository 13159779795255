.set-update-payment-btn {
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    box-sizing: border-box;
    width: 190px;
    height: 42px;
    color: #FFFFFF;
    background: #2E7C7B;
    border-radius: 4px;
}

.radio-label-for-update-payment {
    width: 200px;
    height: 22px;
    margin: -28px 0 0 126px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #6C6C6C;
}

.update-payment-submit-btn {
    width: 400px;
    height: 52px;
    position: relative;
    top: 43px;
    left: 70px;
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    background: #2E7C7B;
    border-radius: 4px;
    color: #FFFFFF;
}