.KYCNotDone-container{
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    position: relative;
    /* margin: auto; */
}

.KYCtext{
    width: 70%;

    margin-left: 7rem;
    padding-left: 15rem;
}
.full-container{
    align-items: center;
}