.Manage-Driver-details-bar-name {

    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    margin-top: 5px;
    text-align: center;
    color: #000000;
}

.manage-Driver-details-bar-vehicleno {
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    margin-top: 5px;
    text-align: center;
    color: #000000;
}

.manage-Driver-details-bar-bal{
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;  
    /* identical to box height */
    margin-top: 5px;
    text-align: left;
    color: #000000;
}