.outer{
    position: relative;
width: 48px;
height: 48px;
left: 30px;
top: 233px;


background: #EBF2F2;
border-radius: 4px;
}

/* .inner{
    position: absolute;
left: 8.33%;
right: 8.33%;
top: 20.83%;
bottom: 20.12%;

background: #2E7C7B;
}

.inner-icon{

position: absolute;
left: 63.82%;
right: 21.84%;
top: 60.41%;
bottom: 35.41%;

background: #2E7C7B;
} */
.adjustingMargin{
    margin-bottom: 0px!important;
}
    
    .positons{
        max-width: 520px;
        border: 1px #fff;
        margin-right: 0%;
        top: -20px;
        width: 100%;
        height: 100%;
    }

    .positons1{
        max-width: 520px;
        border: 1px #fff;
        margin-right: 0%;
        /* top: -29px; */
        width: 100%;
        height: 100%;
        position: fixed;
        top:0;
        right: 0;
        margin-top: 0;
    }

    .positons2{
        max-width: 520px;
        border: 1px #fff;
        margin-right: 0%;
        /* top: -29px; */
        width: 100%;
        /* height: 110%; */
    }
    .btn-text{
        font-family: 'Nunito-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */
        
        text-align: center;
        text-transform: capitalize;
    }

    .butt-1{

        width: 131px;
        height: 42px;
        margin: 29px 0 0 96px;
        
        background: #2E7C7B;
        border-radius: 4px;
        color: #FFFFFF;
        }
        
        .butt-2{

            margin: 28px 0 0 100px;
            box-sizing: border-box;
        width: 131px;
        height: 42px;
        background: #FFFFFF;
        color: #6C6C6C;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        
        }
        
        .setting-btn-1{
            width: 131px;
        height: 42px;
        margin: 29px 0 0 96px;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        background: #FFFFFF;
        color: #6C6C6C;
        }
        
        .setting-btn-2{
            margin: 28px 0 0 100px;
            box-sizing: border-box;
            width: 131px;
            height: 42px;
            color: #FFFFFF;
            background: #2E7C7B;
            border-radius: 4px;
            
        }

.butt-3{
    margin: 28px 0 0 -1px;
    box-sizing: border-box;
    width: 121px;
    height: 42px;
    background: #FFFFFF;
    color: #6C6C6C;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
}
.setting-btn-3{
    margin: 28px 0 0 -1px;
    box-sizing: border-box;
    width: 121px;
    height: 42px;
    color: #FFFFFF;
    background: #2E7C7B;
    border-radius: 4px;
    
}


.card-wallet-icon-body2{
    display: flex;
    flex-direction: row;
    margin: -17px 0px 0 -16px;
width: 520px;
height: 95px;
background: rgba(226, 66, 162, 0.1);
}

.card-wallet-icon-body3{
    display: flex;
    flex-direction: row;
    margin: -17px 0px 0 -16px;
width: 520px;
height: 95px;
background: #ECF7EB;
}

.select-option{
    border: none;
    margin: 0 0 0 96px;
    width: 362px;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: black;
}


.sub-head-3{
    margin-top: 30px;
    

    
}
.label-body{
    margin-top: 0px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    align-items: center;

    color: #25252D;
} 
