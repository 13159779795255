.CompletedTripsBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
    padding-left: 0.5rem;
}
.completedText{
    font-size: 16px;
    line-height: 21px;
}