.FullVehicleList-Bar{
    display: flex;
    flex-direction: row;
}
.FullVehicleListBarALL{
    
    margin-left: 18px;
    margin-top: 5px;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
}
.FullVehicleistBarLow{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
margin-left: 36px;
margin-top: 5px
}
.FullVehicleListBarKYC{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
margin-left: 36px;
margin-top: 5px
}
.FullVehicleListBarKYCClicked{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-left: 36px;
    margin-top: 5px;
    color: #6378E3;
}
.FullVehicleistBarLowClicked{
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-left: 36px;
    margin-top: 5px;
    color: #6378E3;

}
.FullVehicleListBarALLClicked{
    margin-left: 18px;
    margin-top: 5px;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;color: #6378E3;
}
