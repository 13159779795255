.dashedLineTripDetails{
    border-bottom: 1px dotted #000;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 80%;  
    margin-top:10px;
    margin-left:2px;
}
.TripDetailsBorder{ 
    margin-left: '16px';
    margin-right: '16px'; 
    width: '96%';
    height: '.5px';
     border-width: '1px'; background-color: '#6C6C6C' 
    }

 .TripDetailsFontBold{
    font-family: 'nunito-regular';
    font-weight: 700;
 } 
 .tripDetailsFontMedium{
    font-family: 'nunito-regular';
    font-weight: 500;
 }  
 .tripDetailsSemiBold{
    font-family: 'nunito-regular';
    font-weight: 600;
 }
 .tripDetailsRegular{
    font-family: 'nunito-regular';
    font-weight: 400;
 }
 .headingTripDetails{
   line-height: 24px;
   font-size: 18px;
 }