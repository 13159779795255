.leftBorderLine{
    border: none;
    border-left: 1px solid rgba(108, 108, 108, 0.2) ;
}

.driverCardColor{
    background-color: #96A9CA;
}

.selectedCard{
    background-color: #485B7C;
    color: #ffffff;
    border: 1px solid #96A9CA;
    border-radius: 0.5rem;
}

.notSelectedCard{
    background-color: #96A9CA;
    color: #000000;
    border: 1px solid rgba(108, 108, 108, 0.2);
    border-radius: 0.5rem;
}

.greyShadeText1{
    color: rgba(108, 108, 108, 0.6);
}

.greyShadeText2{
    color: rgba(255, 255, 255, 0.6);
}





.DriverSectionTable tr td{
    padding: 0.7rem 0 0.7rem 0.5rem!important;
    text-align: left !important;
}

.DriverSectionTable tr th{
    padding: 0.5rem 0.3rem !important;
    text-align: left !important;
}

.DriverSectionTable thead{
    border-color: transparent !important;
}

.DriverSectionTable thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

.whiteBack{
    border: 0.5px solid #E2E2E2 ;
    border-radius: 0.5rem;
    box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 0.06) !important;
}


#driver_tables th:first-child{
    border-radius:10px 0 0 10px;
  }
  
#driver_tables th:last-child{
    border-radius:0 10px 10px 0;
  }