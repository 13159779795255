.managecustomerBar{
    position: relative;
    width: 808px;
    display: flex;
    flex-direction: row;
    height: 51px;    background: rgba(46, 124, 123, 0.1);
}
.managecustomerName{
    position: relative;
    width: 105px;
    height: 19px;
    margin-left:10px;
    margin-top:15px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.managecustomerDate{
    position: relative;
    width: 119px;
    height: 19px;
    margin-left: 10px;
    margin-top:16px;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.managecustomerAmount{
    position: relative;
    width: 105px;
    height: 19px;
    margin-top:15px;

    margin-left: 10px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.managecustomerAttachement{
    position: relative;
    width: 98px;
    height: 19px;
    margin-left: 10px;
    margin-top:15px;

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.managecustomerActions{
    position: relative;
width: 43px;
height: 19px;
margin-left: 10px;

margin-top:15px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.ManageDriverVehicleImage{
    left: 600px;
    top:15px;
    position: absolute;
}