.ManageDriverCardType2Box{
    box-sizing: border-box;

position: relative;

height: 80px;


border: 1px solid #E2E2E2;
border-radius: 8px;

}
.ManageDriverCardType2Bal{
    position: relative;
width: 103px;
height: 22px;
margin-top:14px;
margin-left:12px;
font-family:'Nunito-Regular';
font-style: normal;
font-weight:550;
font-size: 18px;
line-height: 19px;

color: #FE2950;
}
.ManageDriverCardType2Text{
    position: relative;
/* width: 135px; */
height: 19px;
margin-top:10px;
margin-left:12px;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;

/* identical to box height */


color: #25252D;

}