.mini-modal{
    border: 1px #fff;
    top: 0px;
}

.main-payment-link-box{
    box-sizing: border-box;
width: 450px;
height: 42px;
margin: 0;
font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */


color: #25252D;

background: #FFFFFF;
border: 1px solid #E2E2E2;
border-radius: 2px;
}

.cancel-btn-for-ask-payment{
    margin: 0 0 0 39px;
    width: 130px;
height: 42px;

background: #FFFFFF;
border-radius: 4px;

font-family: 'Nunito-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
/* identical to box height */
text-align: center;

color: #2E7C7B;

}

.share-in-whatsapp-btn{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 168px;
height: 42px;

font-family: 'Nunito-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
/* identical to box height */

color: #FFFFFF;

background: #2E7C7B;
border-radius: 4px;
}

.ask-for-payment-btns{
    margin: 42px 0 0 0;
}

.close-btn-ask-for-payment{
    position: relative;
    top: 0px;
    /* right: 0px; */
    left: 200px;
    background-color: #FFFFFF;
    border: none;
}

.copy-logo-ask-for-payment{

    margin: 10px 0 0 0;

}

.sub-title-ask-for-payment{
    font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */


color: #6C6C6C;
}

.copy-text{
    cursor: pointer;
}

.copied-logo-ask-for-payment {
    margin: 10px 0px 0 -11px;
}