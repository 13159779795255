.customerHome {
  margin-top: 90px;
  max-width: 100%;
  max-height: 100%;
}

.salesText {
  text-align: center;
  font-family: "Nunito-Bold";
  font-style: normal;
  font-size: 40px;
  font-weight: 700;
  color: #2e7c7b;
  line-height: 54.56px;
  padding: 35px 0 0 0;
}

.salesDesc {
  text-align: center;
  font-family: "Nunito-SemiBold";
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  padding: 20px 0 0 0;
}

.imgMain {
  width: 477px;
  height: 217px;
}

.customerIconInfo {
  position: relative;
  top: 478px;
}

/* .imgMainDiv{
    height: 350px;
  } */
.imgAll {
  /* height: 350px; */
  padding: 20px 0px;
}

.customerText {
  text-align: center;
  font-family: "Nunito-Bold";
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  color: #2e7c7b;
  line-height: 32.74px;
  padding: 10px;
}

.pinIcon {
  padding-left: 45%;
}

.pinIconTitle {
  text-align: center;
}

.pinIconSubTitle {
  text-align: center;
}

.textcenter {
  text-align: center;
  padding: 35px;
}

.startBtn {
  height: 49px;
  width: 224px;
}

.startNow {
  text-align: center;
  font-family: "Nunito-Bold";
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  color: #f8f9fa;
  line-height: 32.74px;
  padding: 10px;
}
