.main-side-bar {
  /* position : fixed; */
  z-index: 1;
  /* top: 70px;
    left: 0px; */
  width: 130px;
  height: 100%;
  background: #ffffff;
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}
.side-bar {
  position: absolute;
  background-color: #ffffff;
  /* top: 0px; */
  padding-top: 28px;
  width: 90px;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 18px;
  color: #2e7c7b;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #2e7c7b;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: gray;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  left: 0px;
  height: calc(100% - 70px);
  top: 70px;
  width: 130px !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;

  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding-top: 5%;
  /* padding: 0.8em; */
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 70px;
  height: calc(100% - 70px) !important;
}

/* Individual item */
.bm-item {
  display: inline-block;

  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}
.side-bar2 {
  position: absolute;
  background-color: #ffffff;
  height: 100%;
  /* top: 70px; */
  left: 60px;
  padding-top: 38px;
  width: 90px;
}
.side-bar3 {
  position: absolute;
  background-color: #6c6c6c99;
  height: 100%;
  /* top: 70px; */
  left: 70px;
  padding-top: 28px;
  width: 0.3px;
  border-left-width: 0.3px;
  border-left-color: #6c6c6c99;
  padding: 0;
}
.side-bar2-upper-part {
  margin: 0 0 40px 0;
}
.sidebar-height {
  /* height: 10rem; */
}
.sidebar-height2 {
  /* position: sticky; */
  /* top: 70px; */
  /* overflow-y:scroll; */
  transition: height 1s;
}
.width90 {
  width: 90px;
  transition: width 0.3s;
}
.showMenu {
  width: 30px;
  height: 30px;
  border: none;
  margin: 0 0 0 12px;
  padding-left: 24px;
  padding-right: 24px;
}
/* @media (max-height: 600px) {
  .sidebar-height{
      height: fit-content;
  }
}  */
.boxContainer2 {
  text-align-last: center;
}
