.main-header{
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0px;
    top: 0px;
  
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
    z-index: 1;
    padding: 8px;
}

.logo{
    width: 130px;
    position: relative;
    top: 8px;
    height: 40px;
}


.rel{
    position: relative;
}

.flex{
    display: flex;
    flex-flow: row;
}




.flex1{
    flex: 1;
}
.applogolink{
    background-color:transparent !important;
}
.notClicked
{
    background-color:transparent !important;

}