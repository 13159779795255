.main-table-for-customer-list {
    border-collapse: none;
}

.main-all-customer-list {
    position: relative;
   
}

.customerTable {
    overflow-x: hidden;

}

.table-header-all-customer-list {
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  

    border-bottom: solid 1px #e1e1e1;

    text-transform: capitalize;

    color: #25252D;
   padding: 12px;
}

#scrollableDivCustomer {
    overflow-y: auto;
    overflow-x: hidden;
   
    text-align: left !important;
}

.table-row-all-customer-list {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    cursor: pointer;

    color: #25252D;

    height: 74px;
}

.customer_chosen {
    /* height: 58px;
    padding-top: 5px; */
    /* border-left: solid #6378E3; */
}




.arrow-up-logo {
    position: relative;
    right: 12px;
}

.paid-all-customer {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.pending-all-customer {
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #FCC900;
}

.table-row-all-customer-list-after-active {
    background: #F3F5FD;
    border-radius: 4px;
}

.rectangle {
    width: 4px;
    height: 58px;
    /* background: red; */
    min-width: -38px;
    position: relative;
    left: -8px;
    background: #6378E3;
}

.intialsCircle {
    border-radius: 50%;
    height: 28px;
    width: 28px;
    background-color: #E0E4F9;
    margin-right: 8px;
    text-align: center;

}



.firstNameDiv {
    display: flex;
    padding-left: 4px;
    position: relative;
}

.customerName {
    font-size: 17px;
    padding-right: 5px;
    padding-top: 2px;
}

.customerLName {
    font-size: 17px;

    padding-top: 2px;
}

.companyName {
    color: #6C6C6C;
    font-size: 14px;
    padding-left: 39px;

}

.intialsCircle {
    display: block;
    position: relative;
}


.intialsCircleTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}


/* .customer_chosen td{
   
    background-color: #F3F5FD;

}

.customerTable td
{
    margin-left: 4px;
} */

.customer-list-main-body {
    margin: 0;
}


.active-customer-row {
    background: #F3F5FD;
    border-radius: 4px;

}