.input-body-1
{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 27px;
align-items: center;
    border: none;
    margin-left: 0px;
}
.input-body-1:focus
{
    outline: none;
}
.card-wallet-icon-body1{
    display: flex;
    flex-direction: row;
    margin: -17px 0px 0 -16px;
width: 520px;
height: 95px;


background: #FFF3EA;
}

.card-wallet-logo-BankLogo{
    position: absolute;
    left: 44.1px;
    top: 26.1px;
}

.beneficiary-card{
    position: relative;
    /* margin: 10px 0 0 96px; */
    left: 5rem;
    width: 390px;
    height: 76px;
    cursor: pointer;
    /* overflow-y: scroll; */
}

.rectangle-for-logo {

    width: 52px;
    height: 48px;


    background: rgba(46, 124, 123, 0.1);
    border-radius: 4px;
}

.people-Logo{

    margin: 6px 0 0 9px;
}


.beneficiary-details-main {
    /* margin: 15px 0 0 96px; */
    padding: 15px 0 0 108px;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    height:24rem;
    overflow-y: scroll;
}

.main-body-beneficiary-details {
    margin: 32px 0 0 0;
}

.main-body-beneficiary-details-title {
    width: 300px;
    height: 25px;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    text-transform: capitalize;

    color: #25252D;
}

.beneficiary-details-sub-heading {
    width: 380px;
    height: 22px;
    /* margin: -28px 0 0 126px; */

    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    /* display: flex;
    flex-direction: row; */
    color: #25252D;
    position: relative;
    margin-left: -25px;
    margin-bottom: 20px;
}
.sub-title-edit-vehicle-detial 
{
    color: #25252D;

}
