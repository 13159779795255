.main-body{
    display: flex;
    width:1440px;
    height:1024px;
    background: #e9edf8
    ;}
.LogipeCard-hompage{
  
}
.main-home{
    margin-top: 60px;
    padding-top: 30px;
    margin-right: 24px;
}
.homepage-first-card-row{

    display: flex;
    flex-direction: row;

}

.homepage-topcard{
    position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 30px;

}
.homepage-bottomcard{
display: flex;
flex-direction: row;
position: relative;
margin-top: 35px;

}
.homeCard-1-homePage{
    
}
.homepage-topcard-element{
    margin-left: 1.5rem;
}
.Homepage-Homecard{
    margin-top:40px;
}
