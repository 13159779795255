.myModalBanner .modal-content{
    width: 1250px;
} 

.bannerTitle{
    font-size: 1.7rem;
    line-height: 42px;
    color: #25252D;
    text-align: center;
}

.Bannerbox{
    border: 0.2px solid #2E7C7B;
    border-radius: 0.8rem;
    background-color: #FBFFFF;
    color: #25252D;
    height: 11rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.paddingBtnBanner{
    padding-top: 2rem;
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .paddingBtnBanner{
        padding-top: 0.5rem;
    }
}