.phoneNumberBox{
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    width: 350px;
}

.input-body-4 {
    margin-top: 0px;
    width: 210px;
    border: none;
    /* color: #c4c4c4; */
    text-align: left;
                     
}

.input-body-4:focus{
    outline: none;
}

.marginLeftVal{
    margin-left: 0.8rem;
}

.centerText1{
    padding-left: 0.5rem;
}

.newUserBtn{
    color: #485B7C;
    border: 0.1rem solid #485B7C;
    border-radius: 0.4rem;
    background-color: #FFFFFF;
    width: 350px;
}

.margin10T{
    margin-top: 10px;
}
/* .newUserBtn:hover, :active{
    color: #2E7C7B;
    border: 0.1rem solid #2E7C7B;
    border-radius: 0.4rem;
    background-color: #FFFFFF;
    width: 335px;
} */

@media (max-width: 1400px) and (min-width: 1200px) {
    .phoneNumberBox{
        width: 335px;
    }

    .marginLeftVal{
        margin-left: 0;
    }

    .centerText1{
        padding-left: 0.2rem;
    }

    .newUserBtn{
        width: 335px;
    }
    
}

