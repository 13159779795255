
.btnPadding{
    padding: 0px 3rem 1rem 6px;
  }

.specTable{
    border:1px solid #E3E3E3;
    border-radius: 0.4rem;
  }
  
  .specTable tr{
    width: 80% !important;
    border:1px solid #E3E3E3;
    border-radius: 0.4rem;
  }
  
  .specTable tr th{
    width: 40% !important;
    background-color: #96A9CA !important;
    padding: 0.5rem !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14.52px !important;
    color: rgba(37, 37, 45, 0.6) !important;
  }
  
  .specTable tr td{
    padding: 0.5rem !important;
    width: 40% !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14.52px !important;
    color: rgba(37, 37, 45, 0.6) !important;
  }


.prSpec{
    font-weight:550;
    font-size:1rem;
    line-height:19.36px ;
    color: #25252D;
    border-bottom: 1px solid #96A9CA;
    width: 4rem;
    padding-bottom: 0.3rem;
  }

.prDelivery{
    font-weight:400;
    font-size:0.8rem;
    line-height:19.36px ;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    color: #25252D;
  }

.quantityDiv{
    border: 1px solid #e2e2e2;
    padding: 0.2rem;
    border-radius: 0.4rem;
    width: 90%;
  }

.infoColumn{
    padding-left: 3rem;
    padding-right: 2rem;
  }

.imgTyre{
    align-items: center;
    text-align: center;
    margin: auto;
  }

.detailImgContainer{
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

.imageColumn{
height: 17rem;
width: 22rem;
background-color:#FFFFFF;
padding: 0.5rem;
border-radius: 0.5rem ;
border: 1px solid #E3E3E3;
align-items: center;
text-align: center;
}

.prPrice{
    font-weight:700;
    font-size:0.8rem;
    line-height:19.36px ;
    text-decoration: line-through;
    color: #FF3A44;
    padding-top: 0.1rem;
    padding-left: 0.5rem;
  }

.prCoins{
    font-weight:700;
    font-size:0.8rem;
    line-height:18px ;
    color: #09BD7B;
    padding-bottom: 1rem;
  }  
.prMRP{
    font-weight:600;
    font-size:20px;
    line-height:26px ;
  }
.prodPrices{
    display: flex;
    flex-direction: row;
    padding: 0.7rem 0;
  }

.prodName{
    font-weight:600;
    font-size:24px;
    line-height:29.05px ;
  }

.subIcon{
    padding-left: 6px;
    padding-right: 6px;
    border-right: 0.1rem solid #e2e2e2;
}

.addIcon{
    padding-left: 6px;
    padding-right: 6px;
    border-left: 0.1rem solid #e2e2e2;
}
  .specContainer{
    padding: 1rem 1rem 3rem;
  }
@media only screen and (max-width: 600px){

    .btnPadding{
        padding: 0px;
      }
    .specContainer{
        padding: 1rem 0 2.5rem;
      }
    .prSpec{
        font-size: 0.75rem;
        line-height: 0.9rem;
      }
    .prDelivery{
        font-size: 0.75rem;
        line-height: 0.9rem;
      }
    .quantityDiv{
        width: 100%;
        position: relative;
        top: -1rem;
      }

    .quantityContainer{
        padding-left: 1.2rem;
        padding-right: 1rem;
      }
    .prCoins{
        font-weight: 600;
        font-size: 0.7rem;
        line-height: 0.9rem;
        padding-left: 0;
      }
    .prPrice{
        font-size:0.75rem;
        line-height: 0.9rem;
        padding-top: 0.2rem;
      }
    .prMRP{
        font-size:1rem;
        line-height: 19px;
      }

    .prodName{
        font-size: 0.875rem;
        line-height: 19px;
      }
    .infoColumn{
        padding-left: 1rem;
        padding-right: 1rem;
      }

    .imageColumn{
        height: 15rem;
        width: 20rem;
      }    

    .detailImgContainer{
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
}