.Name{
    position: relative;
 padding-left: 16px;

/* identical to box height */


color: #25252D;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.Bar{
    border: .1px solid #C4C4C4;
    height: 30px;
    width:1px;
    margin-left:10px;
    position: relative;
}

.Card{
    height: 25px;
    position: relative;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    margin-left:10px;
    margin-top:5px;
    color: #25252D;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 2;
    flex-grow: 0;
}

.ask-for-payment-btn{

    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

box-sizing: border-box;

/* Auto layout */

gap: 4px;
text-align: center;

height: 42px;

width :200px;
background: #FFFFFF;
border: 1px solid #2E7C7B;
border-radius: 4px;

font-family: 'Nunito-SemiBold';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */
text-align: center;

color: #2E7C7B;
}

.askForButton{
    padding-right: 16px;
}

.leftBoxLine{

    border-left: .1px solid #C4C4C4;;
}