.closeBtnWithBackgroundForTrip {
    position: absolute;
    right: 36px; 
    top: 0px;
    border: 1px solid;
    border-radius: 20px;
    background: #ffffff;
    cursor: pointer;
  }
.trigger {
  border-bottom: 2px solid #d7f2d7 ;
  background: rgb(159, 175, 170);
  background: linear-gradient(180deg, rgb(213, 246, 204) 0%, rgb(159, 207, 146) 35%, rgb(111, 133, 115) 100%);
}