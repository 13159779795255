.addButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 16px 14px 4px;
    cursor: pointer;
    position: absolute;
    width: 105px;
height: 12px;
    
    background: #F2F2F2;
    border-radius: 40px;
}
.addButtonApproved{
    box-sizing: border-box;

position: absolute;
width: 78px;
height: 32px;
left: 961px;
top: 421px;

border: 1px solid #2E7C7B;
border-radius: 20px;
}
.addNewButton{
    position: absolute;
    width: 69px;
    height: 22px;
    left: 42px;
    top: -18px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #2E7C7B;
}
.addNewButtonText{
    /* position: absolute;
    width: 69px;
    height: 22px;
left: 34px;
top: 7px; */
margin-bottom: 0 !important;
font-family:'Nunito-Regular';
font-style: normal;
font-weight: 600;

text-transform: capitalize;
font-size: 16px;
line-height: 19px;

color: #2E7C7B;
}
.addSign{

color: #2E7C7B;
margin-right: 6px;
    
}
.AddNewButtonApproved{
    position: absolute;
    width: 69px;
    height: 22px;
    left: 42px;
    top: -18px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #2E7C7B;
}
.AddNewButtonTextApproved{
    position: absolute;
width: 55px;
height: 19px;
left: 972px;
top: 428px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #2E7C7B;
}
.addnewbtn{
    background-color: #f2f2f2 !important;
    display: flex;
    border-radius: 40px;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: 6px 15px;
    margin-top: 16px;
    align-items: self-end;


}
