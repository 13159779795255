.add-attach-for-fastag-linked-vehicle {
    position: relative;
    /* left: 83px; */
    top: 28px;
    background-color: #ffffff;
    border: none;
}

.add-attach-for-fastag-linked-vehicle:hover{
    background-color: #ffffff;
    border: none;
}

.add-attach-for-fastag-linked-vehicle:focus{
    background-color: #ffffff;
    border: none;
}

.input-body-3{
    color:black;
}
button:disabled{
    background-color:gray;
    }