.Pin-Modal{
    /* box-sizing: border-box; */
    width: 500px;
    height: 350px;
    background: #FFFFFF;
    border: 1.06769px solid #E2E2E2;
    border-radius: 16px;
    margin: 0 0 0 0;
    position: relative;
    top: 30%;
    left: 30%
}
.Pin-Modal .modal-content{
    width: 500px;
    border-radius: 16px;
}
.ENT-Security-Pin-Title{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    text-transform: capitalize;
    color: #25252D;
    margin-bottom: 4px;
}
.ENT-Security-Pin-subTitle{
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #898989;
}
.Ent-Pin-Box{
    margin: 58px 0 0 0;
}
.Verify-Pin-Btn{
    width: 80%;
    margin: 20px;
}
.Success-Modal{
    /* box-sizing: border-box; */
    width: 400px;
    height: 200px;
    background: #FFFFFF;
    border: 1.06769px solid #E2E2E2;
    border-radius: 16px;
    margin: 0 0 0 0;
    position: relative;
    top: 35%;
    left: 35%
}
.Success-Modal .modal-content{
    width: 400px;
    border-radius: 16px;
}

.Select-Vendor-Modal{
    /* box-sizing: border-box; */
    width: 500px;
    height: 558px;
    background: #FFFFFF;
    border: 1.06769px solid #E2E2E2;
    border-radius: 16px;
    margin: 0 0 0 0;
    position: relative;
    top: 12%;
    left: 30%
}
.Select-Vendor-Modal .modal-content{
    width: 500px;
    border-radius: 16px;
}

.Payment-Amount-Modal{
    width: 528px;
    height: 558px;
    left: 456px;
    top: 178px;

    background: #FFFFFF;
    border: 1.06769px solid #E2E2E2;
    border-radius: 16px;
    margin: 0 0 0 0;
    position: relative;
    top: 12%;
    left: 30%
}
.Payment-Amount-Modal .modal-content{
    width: 528px;
    border-radius: 16px;
}
.Amount-Entry-Field{
    font-family: 'Nunito-Regular';
    font-style: normal;
    width: 100%;
    background-color: #FFFFFF;
    border: none;
    border-bottom: 1px solid #A7A7A7;
    min-width: 260px;
    max-width: max-content;
    margin: 36px 0 0 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    /* display: flex; */
    align-items: center;
    text-align: center;
}

.Amount-Entry-Field:hover{
    outline: none;
}
.Amount-Entry-Field:focus{
    outline:none;
}
.Amount-Entry-Field::placeholder{
    color: #A7A7A7;
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
}

.Remarks-With_Bg{
    font-family: 'Nunito-Medium';
    font-style: normal;
    width: 70%;
    background-color: rgba(234, 149, 113, 0.1);
    border: none;
    min-width: 260px;
    max-width: max-content;
    margin: 36px 0 0 0;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.004em;
    padding: 10px 0 10px 0;
}
.Remarks-With_Bg:hover{
    outline: none;
}
.Remarks-With_Bg:focus{
    outline:none;
}
.Remarks-With_Bg::placeholder{
    color: #6C6C6C;
    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.004em;

}
.vendorList-Header{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #25252D;
}

.greenButtonSelect {
    box-sizing: border-box;
    /* position: absolute; */
    /* width: 113px; */
    /* height: 35px; */
    /* left: 282px; */
    /* top: 25px; */
    background: #2e7c7b;
    border: 1px solid #2e7c7b;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-family: "Nunito-Regular";
    font-style: normal;
  }
  .greenButtonSelect:hover{
    /* background: #2e7c7b; */
  }
  .greenOutlineButtonSelect {
    box-sizing: border-box;
    /* position: absolute; */
    /* width: 113px; */
    /* height: 35px; */
    /* left: 282px; */
    /* top: 25px; */
    color: #2e7c7b;
    border: 1px solid #2e7c7b;
    background-color: transparent;
    border-radius: 4px;
    text-align: center;
    font-family: "Nunito-Regular";
    font-style: normal;
  }
  
  .greenOutlineButtonSelect:hover {
    color: #2e7c7b;
    border: 1px solid #2e7c7b;
    background-color: transparent;
  }
  
  .greenOutlineButtonSelect:active {
    color: #2e7c7b;
    border: 1px solid #2e7c7b;
    background-color: transparent;
  }