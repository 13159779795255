.ManageDriverCardType1Box{
    position: relative;

height: 76px;
/* cursor: pointer; */

background: #ffffff;
border-radius: 4px;
}
.ManageDriverTyp1IconBox{
    position: relative;
    width: 52px;
    height: 48px;
   /* margin-top:14px; */
    margin-left:8px;
    background: #ffffff;
    border-radius: 4px;
}
.ManageDriverCardType1Number {
    position: relative;
    width: 29px;
    height: 30px;
    margin-left: 8px;
    /* margin-top: 10px; */
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #25252D;
}
.ManageDriverCardType1Text{
    position: relative;
  
    margin-left: 67px;
    margin-top: -15px;
    
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    
    color: #25252D;
}
.ManageDriverType1Icon{
    position: relative;
  margin-left:5px;
  margin-top: 5px;
    
   
}
.ManageDriverCardType1Extra{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
position: relative;
left:102px;
top:-42px;
color: #6C6C6C;
}


@media (max-width: 1400px) and (min-width:1200px){
    .ManageDriverCardType1Number {
        font-size:19px;
    }
}