.logipeCardBox{
    position: relative;
width: 360px;
height: 222px;


background: #4C4059;
border-radius: 16px;
background-image: linear-gradient(145deg, #4C4059 0% 72%,#52465e 72% 79%,#584e65 79% 100%);
}
.CardBalanceAndName{
    display:flex;

}
.cardBalance{
    position: absolute;
left: 4.44%;
right: 68.89%;
top: 8.11%;
bottom: 81.98%;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;

color: rgba(255, 255, 255, 0.6);
}
.cardName{
    position: relative;
width: 115px;
height: 22px;
left: 220px;
top: 18px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.1em;

color: rgba(255, 255, 255, 0.6);
}


.Balance{
    position: relative;
left: 4.44%;
right: 71.11%;
top: 20px;
/* bottom: 68.02%; */

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23.5px;
display: flex;
align-items: center;

color: #FFFFFF;
}
.cardText{
    position: absolute;
width: 267px;
height: 19px;
left: 16px;
top: 133px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.04em;

color: #09BD7B;
}
.bottomText{
    position: absolute;
left: 4.44%;
right: 65.83%;
top: 81.08%;
bottom: 9.01%;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
text-align: right;
text-decoration-line: underline;

color: #FFFFFF;
}
.sideRectangle{
    position: absolute;
width: 4px;
height: 31px;
left: 0px;
top: 127px;

background: #09BD7B;
}
.arrow{
    box-sizing: border-box;

position: absolute;
width: 12px;
height: 0px;
left: 105px;
top: 182px;


}