.Login-Screen_box {
  height: 100%;
  width: 100%;
  background: #fff;
}
.upiAppBorder {
  border: 1px solid #e2e2e2;
  border-radius: 12px;
}
.greenBg {
  background-color: #2e7c7b;
  border-radius: 12px;
  text-align-last: center;
  padding: 32px;
}
.greenBg2 {
  background-color: rgba(9, 189, 123, 0.8);
  border-radius: 100px;
  text-align-last: center;
  padding: 6px 16px;
}
#loginMobile {
  display: none;
  position: relative;
  height: 100vh;
}
.textClassRecharge {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.textLast {
  text-align-last: end;
}
.vrnHeading {
  color: #25252d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
}
.text2Class {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* #loginDesktop{
    display: block;
} */
.SuccessText {
  color: #09bd7b;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
@media (max-width: 768px) {
  #loginMobile {
    display: block;
  }

  #loginDesktop {
    display: none;
  }
}
