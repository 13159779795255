.manage-logipe-card-bottomSection{
    
}

.manageLogipeCardMain{
 
}
.manageLogipeCardRightSection{
    margin-left: 5px;
}


  .mid-container-manage-logipecard{

      margin-top: 90px;
  }
  .bottomSectionContainer{
      padding-top: 30px;
  }

  .noKycText{
    color: #6C6C6C; 
  }

  .paddingForCenter{
    padding: 10.5rem 0;
    text-align: center;
    border-radius: 8px;
  }

  @media (max-width: 1400px) and (min-width: 1200px) {
    .paddingForCenter{
        padding: 8.3rem 0;
      }
  }