.showPW{
    cursor: pointer;
    padding: 0.2rem 0.5rem;
}

.showPW1{
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  text-align: right;
}

.showPW:hover{
    background-color: #D5E5E5;
    border-radius: 0.4rem;
    text-decoration: underline;
}

.Tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  }

.Tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem 0.5rem;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }


.Tooltip:hover .tooltiptext {
    visibility: visible;
  }