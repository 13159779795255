.butt-recharge{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    
    width: 400px;
    height: 52px;
    margin: 136px 0px 0 60px;
    top: calc(50% - 52px/2 + 426px);
    
    background: #485B7C;
    border-radius: 4px;
    color: #FFFFFF;
}

.butt-recharge1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    
    width: 400px;
    height: 52px;
    margin: 105px 0px 0 60px;
    top: calc(50% - 52px/2 + 426px);
    
    background: #485B7C;
    border-radius: 4px;
    color: #FFFFFF;
}

.rechargeScroll{
    /* overflow-y: hidden;
    overflow-x: hidden; */
}