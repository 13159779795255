.FullCustomerListContainer{
    margin-top:90px;
    margin-left:0px;
    margin-bottom: 48px;

}

.ManageCustomer-rightContainer{
       margin-top: 90px;
       margin-bottom: 48px;
     
}
.Manage-Customer-card-type-1-box{
    
    display:flex;
    flex-direction: row;
}
.manage-customer-dashboard-box{
 
    margin-top: 30px;
}
