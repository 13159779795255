.greenText{
    color:#2E7C7B;
}

.enteredNumber{
    display: flex;
    font-size: 16px;
    color: #25252D;
    padding-left: 1rem;
}

.editIcon{
    cursor: pointer;
}

.otpInput{
    margin-left: -48px;
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .otpInput{
        margin-left: -45px;
    }
}  


.phoneNumberBox{
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    width: 350px;
}


.phoneNumberBox1{
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    width: 100%;
}

.input-body-4 {
    margin-top: 0px;
    width: 210px;
    border: none;
    /* color: #c4c4c4; */
    text-align: left;
                     
}

.input-body-4:focus{
    outline: none;
}

.skipText{
    color: #2E7C7B;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .phoneNumberBox{
        width: 335px;
    }
    .phoneNumberBox1{
        width: 100%;
    }
}