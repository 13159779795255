.showroom-for-vehicle-text {

    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
 
    color: #25252D;
}
