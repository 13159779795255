.featureCard{
    border-radius: 8px;
    border: 0.5px solid rgba(108, 108, 108, 0.20);
    /* background: #96A9CA; */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 12px 20px;
    /* height: 120px; */
    width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cardRowUp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-edit-details1{
  font-family: 'Nunito-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  width: 90px;
  height: 30px;
  border: none;
  color : white;
  background: #485B7C;
  border-radius: 8px;
}
.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}
.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  cursor: pointer;
}
