
.qtyBox{
    font-size: 0.8rem;
    line-height: 15px;
    font-weight: 500;
    color:#6C6C6C;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    width: 4rem;
    padding: 0.5rem;
    align-items: center;
    text-align: center;
  }

.borderCls{
    border: 0.2rem solid #485B7C;
    border-radius: 0.5rem;
  }

.addressHeader{
    padding: 1rem;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
  }