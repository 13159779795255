.showroomVehilceText{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 43.65px;
    color: #485B7C;
    text-align: center;
}

.showroomText{
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 43.65px;
    color: #ED752E;
    text-align: center;
}

.modal-enter {
    opacity: 0;
    transform: rotate(-30deg);
  }
  
  .modal-enter-active {
    opacity: 1;
    transform: rotate(0deg);
    transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .modal-exit {
    opacity: 1;
    transform: rotate(0deg);
  }
  
  .modal-exit-active {
    opacity: 0;
    transform: rotate(30deg);
    transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
