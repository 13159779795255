.butt{
/* Rectangle 828238 */


    box-sizing: border-box;

    position: absolute;
    width: 113px;
    height: 32px;
    left: 282px;
    top: 25px;
    
    background: #2E7C7B;
    border: 1px solid #2E7C7B;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-family: 'Nunito-Regular';
    font-style: normal;
}


.head-part{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 33px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
