.PGCollectionBarDetails{
    position: relative;
    width: 811px;
    display: flex;
    flex-direction: row;
height: 51px;   
 background: rgba(46, 124, 123, 0.1);
}
.PGCollectionDetails{
    position: relative;
    width: 105px;
    height: 19px;
    left:30px;
    top:15px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.PGCollectionCurBalance{
    position: relative;
    width: 119px;
    height: 19px;
    left: 80px;
    top:15px;

    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.PGCollectionReqDate{
    position: relative;
    width: 93px;
    height: 19px;
    top:15px;

    left: 120px;
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.PGColletionMode{
    position: relative;
    width: 98px;
    height: 19px;
    left: -40px;
    top:15px;

    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #25252D;
}
.PGCollectionBarAction{
    position: relative;
width: 43px;
height: 19px;
left: 730px;

top:-28px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */

text-transform: capitalize;

color: #25252D;
}
.PGCollectionBarDetailsImage{
    left: 660px;
    top:-10px;
    position: relative;
}