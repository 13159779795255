.ManageCustomerDashboard{

background: #FFFFFF;
border-radius: 4px 4px 0px 0px;
}
.ManageCustomerDashboardContactCard{
    position: relative;
    padding:  24px 0px;
}
.ManagerCustomerDashboardCardType2{
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 8px;
   
}
.ManagerCustomerDashboardCardType2-Inner{
    position: relative;
    width: 33.33%;
    padding: 8px;
}
.manage-customer-dashboard-desc{
    position: relative;
    width: 399px;
    height: 44px;
   
}

.ManageCustomerMidHeader{
    position: relative;
   
padding-bottom: 16px;
color: #25252D;
}


.ManageCustomerMidBar{

position: relative;
border: 1px solid #E2E2E2;
}
.ManageCustomerDashboardMidTextHandler{
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top:25px;
}
.ManageCustomerDetailsCardBarPositions{
    margin-top:40px;
    margin-left: 12px;
}