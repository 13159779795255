.ManageExpenseManageCardBox{

    /* margin-left:25px; */
display: flex;
}
.ManageExpenseManageCardBoxClicked{
  
    position: relative;
    background: #F0F2FD;
}
.ManageExpenseBox-1{
    position: relative;
    width: 210px;
    height: 51px;
 /* left:-25px; */
 padding-top:11px;
 font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 27px;
cursor: pointer;
border: none;
color: black;
outline: none;
background: #fff;
}
.ManageLogepeCardBox-1{
  left:-25px;
  width: 210px;
    height: 51px;
  margin-left: 5px;
    padding-top:11px;
    position: relative;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 27px;
cursor: pointer;
border: none;
color: black;

outline: none;
}
.ManageExpenseBox-1:Hover{
    background:#fff;
    color:black;
}
.ManageLogepeCardBox-1:hover{
    background: #fff;
    color:black;
}
.ManageExpenseBox-1Clicked{
    position: relative;
    width: 210px;
    height: 51px;
 left: -25px;
 padding-top:11px;
 font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 27px;
cursor: pointer;
border: none;
color: #6378E3;
background-color: #F0F2FD;
outline: none;

}

.ManageExpenseBox-1Clicked:focus{
    position: relative;
    width: 210px;
    height: 51px;
 
 padding-top:11px;
 font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 27px;
color: #6378E3;
background-color: #F0F2FD;
cursor: pointer;
border:none;
outline: none;

}
.ManageLogepeCardBox-1Clicked{
    height: 51px;
    left:-25px;
    padding-top:11px;
    width: 210px;
    position: relative;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 27px;
color: #6378E3;

cursor: pointer;
border:none;
outline: none;

}
.ManageLogepeCardBox-1Clicked:focus{
    height: 51px;
    
    padding-top:11px;
    width: 210px;
    position: relative;
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 27px;
color: #6378E3;

cursor: pointer;
border:none;
outline: none;

}
/* .ExpenseEndBar{
    position: relative;
width:808px;
height: .1px;
top: 0px;
left:0px;
background: #d2d2d7;
} */
.ExpenseEndBarClicked{
    position: absolute;
    width: 210px;
    height: 4px;
    top: 0px;

    
background: #6378E3;

}
.DriverDashboardManageExpenseDetails{
    position: relative;
    /* margin-left:-25px; */
    /* margin-top:270px; */
}

    .DriverDashboardManageCardDetails{
        /* margin-top:110px;
        margin-left:-37px; */
        padding-top: 24px;
    }
    .DriverDashboardManageCardItems{
        margin-top:30px;
    }    
    
    .DriverDashboardLogipeCard{
        position: relative;
        margin-top:30px;
        /* margin-left:-12px; */
    }
    .DriverDashboardManageCardTextBox{
        position: relative;
   
    }


.mgDriverButSel{
    background-color: rgb(240, 242, 253) !important;
    color: rgb(99, 120, 227) !important;
    border-bottom: 5px solid rgb(99, 120, 227) !important;
    border-radius: 0px;
}

.ManageExpenseBox-1:hover{
    background-color: rgb(240, 242, 253) !important;
    color: rgb(99, 120, 227) !important;
    border-bottom: 5px solid rgb(99, 120, 227) !important;
    border-radius: 0px;
}

button:focus {
    box-shadow: none !important;

  }


  .buttonContainer{
    border-bottom: solid 1px #e2e2e2;
  }