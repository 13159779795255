.main-card-fastag {
  height: 361px;
}

.main-card-vehicles {
  height: 230px;
}


.Low-balance-fastag {
  /* margin: 27px 0 0 0px; */
  position: relative;
  /* left: 29px; */
  border: none;
  width: 100px;
  height: 23px;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  cursor: auto !important;
  color: #ffffff;

  background: #fe2950;
  border-radius: 2px;
}



.Low-balance-fastag-1 {
  /* margin: 27px 0 0 0px; */
  position: relative;
  /* left: 29px; */
  border: none;
  width: 87px;
  height: 23px;
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  cursor: auto !important;
  color: #ffffff;

  background: #fe2950;
  border-radius: 2px;
}


.current-fastag-balance {
  /* width: 376px; */
  height: 74px;
  /* margin: 30.33px 0px 0 12px; */
  background: #ffeaee;
}

.current-fastag-balance-amount {
  /* width: 352px; */
  height: 33px;
  position: relative;
  /* top: 8px; */
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.current-fastag-balance-amount-title {
  width: 148.56px;
  height: 19px;
  position: relative;
  top: -8px;

  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #25252d;
}

.fastag-logo {
  margin: 26.33px 0 0 14.33px;
}

.title-fastag {
  margin: 25px 0 0 10.33px;
}

.recent-trans-box {
  margin: 28px 0 0 0;
}


.divPadding{
    padding-left: calc(.5 * var(--bs-gutter-x));
    padding-right: calc(.5 * var(--bs-gutter-x));
    padding-top: 1rem;
}

.hotlist{
  background-color: #EA9571;
  color: #ffffff;
  width: 7rem;
  border-radius: 10px;
  position: relative;
  /* left:2rem; */
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .leftPadding{
    padding-left: 0.5rem;
  }

  .hotlist{
    width:5.8rem;
  }

  .Low-balance-fastag{
    width:93px;
  }

  .Low-balance-fastag-1{
    width:78px;
    font-size: 10px;
    line-height: 14px;
  }
}