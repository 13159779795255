.ManageDriverDetailsCardType3Box{
    position: relative;
width: 784px;
height: 78px;


background: #FFFFFF;
}
.ManageDriverDetailsCardType3ExpenseType{
    position: relative;
width: 31px;
height: 22px;
left:24px;
top:17px;
font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.ManageDriverDetailsCardType3Amount{
    position: relative;
width: 60px;
height: 22px;
left:120px;
top:17px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #25252D;
}
.ManageDriverDetailsCardType3By{
    position: relative;
width: 44px;
height: 22px;
top:17px;
left:110px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.ManageDriverDetailsCardType3Mode{
    position: relative;
width: 87px;
height: 22px;
top:17px;
left:70px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.ManageDriverDetailsCardType3Date{
    position: relative;
width: 91px;
height: 22px;
top:17px;
left:70px;
font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #25252D;
}
.ManageDriverDetailsCardType3Dots{
    position: relative;
    width: 91px;
    height: 22px;
    left: 90px;
    top: 17px;
    
    font-family:'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    
    color: #25252D;

}
.ManageDriverDetailsCardType3Remarks{
position:relative;
left:25px;
top:-40px;

font-family:'Nunito-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */


color: #898989;
}
.ManageDriverDetailsCardType3EndBar{
    position: relative;
    width: 811px;
    height: 0px;
        left: -15px;
    
   
    
    border: .25px solid #E2E2E2;
}
.downloadText{
    /* align-items: flex-end; */
    display: flex;
    padding: 16px;
    
}
.downloadImg{
    margin-right: 7px;
}

