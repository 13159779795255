.closeBtnWithBackgroundForTrip {
  position: absolute;
  right: 36px;
  top: 0px;
  border: 1px solid;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.trigger {
  border-bottom: 2px solid #d7f2d7;
  /* background: rgb(159, 175, 170); */
  background: linear-gradient(
    180deg,
    rgb(238, 242, 241) 0%,
    rgb(225, 228, 227) 100%
  );
}
.endTripCard {
  border: 1px solid;
  /* height: 250px; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  /* background: rgb(238, 242, 241); */
}
