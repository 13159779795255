.toastSVGImageV2{
    position: relative;
    top: 2.5rem;
    left: 11rem;
}

.model-width-toast{
    max-width: 420px;
    max-height: 305px;
}

.successToastHeader{
    text-align: center;
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32.74px;
    /* identical to box height */
    text-transform: capitalize;
    padding: 25px 0 0 0;
    color: #09BD7B;
}

.successToastMsg{
    text-align: center;
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.82px;
    /* identical to box height */
    padding: 10px 0 0 0;
}