.AddNewCustomerBox{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 14px 12px 2px;
gap: 4px;
position: relative;
width: 180px;
height: 42px;
cursor: pointer;
background: #F2F2F2;
border-radius: 40px;
}
.AddNewCustomerTextP{
    position: relative;
width: 144px;
height: 22px;
left: 36px;
top: 10px;

font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

color: #2E7C7B;
}


@media (max-width: 1400px) and (min-width:1200px){
    .AddNewCustomerBox {
        padding: 10px 10px 10px 6px;
        gap: 4px;
        position: relative;
        width: 178px;
    }}
