.manage-logipe-card-bottom-box{
    width: 808px;
height: 500px;
background: #FFFFFF;
border-radius: 4px;
}
.manage-logipe-card-details-header{
    font-family: 'Nunito-Regular';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;
margin-top:30px;
/* identical to box height */
position: relative;
margin-left: 10px;
color: #25252D;
}
.manage-logipe-card-header{
    display: flex;
    flex-direction: row;
    
}.manage-logipe-card-details-header-icon{
  margin-left: 15px;
  margin-top:30px
}
.ManageLogipeDetailsCardMain{
    margin-top:21px;
    margin-left: 10px;
}
.ManageLogipeCardDetailsPos{
    margin-top: 0px;
}