.outside-Truck-logo-for-total-vehicle {
    width: 52px;
    height: 48px;
    background: #EBF9F2;
    border-radius: 4px;
}

.Truck-logo-for-total-vehicle {
    margin: 8px 0 0 8px;
}

.outside-toll-logo-for-not-linked {
    width: 52px;
    height: 48px;
    background: rgba(226, 66, 162, 0.1);
    border-radius: 4px;
}

.toll-logo-for-not-linked {
    margin: 8px 0 0 8px;
}

.outside-vehicle-logo-for-unassigned {
    width: 52px;
    height: 48px;
    background: rgba(147, 106, 224, 0.1);
    border-radius: 4px;

}

.vehicle-logo-for-unassigned {
    margin: 8px 0 0 8px;
}

.Manage-numbers-for-vehicle {
    height: 33px;
    margin-left: 12px;
    /* margin-top: 15px; */

}

.Manage-numbers-for-vehicle-no-text {
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
   width: 100%;
    color: #25252D;
}

.Manage-numbers-for-vehicle-text {
    position: relative;

    margin-left: 24px;

    font-family: 'Nunito-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    color: #25252D;
}

.Manage-numbers-for-vehicle-no-sub-text {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    color: #6C6C6C;
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .Manage-numbers-for-vehicle-no-text {
    font-size: 20px;}

}